import React from 'react'
import { Button, TextField, Typography, Heading, Frame } from 'components'
import { signIn } from 'utils'

const LoginView = () => {
  return (
    <Frame maxWidth="xs">
      <Heading>Log in.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { email, password } = event.target.elements
          await signIn(email.value, password.value)
        }}
      >
        <TextField name="email" label="Email Address" />
        <TextField name="password" type="password" label="Password" />

        <Button my={3} type="submit">
          Submit
        </Button>
      </form>

      <Typography style={{ marginBottom: 16 }}>
        Don't have an account?{' '}
        <Typography component="a" href="/freelancer-signup">
          Sign up
        </Typography>
      </Typography>

      <Typography>
        Forgot your password?{' '}
        <Typography component="a" href="/forgot-password">
          Reset your password
        </Typography>
      </Typography>
    </Frame>
  )
}

export default LoginView
