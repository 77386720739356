import React, { useEffect, useState } from 'react'
import { Box, Button, Frame, Heading, Typography } from 'components'
import { getUserById, toggleHidden } from 'utils'

const SubscriberHiddenFreelancers = ({ user }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    Promise.all(user.hiddenUsers.map((id) => getUserById(id))).then(setUsers)
  }, [user])

  return (
    <Frame maxWidth="sm" user={user}>
      <Heading>Manage Hidden Freelancers</Heading>

      {user.hiddenUsers.length === 0 ? (
        <Typography align="center">You have no hidden freelancers</Typography>
      ) : null}

      {user.hiddenUsers.map((id) => {
        const profileUser = users.find((u) => u.id === id)

        return (
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography>
              {profileUser?.firstName} {profileUser?.lastName} (
              {profileUser?.email})
            </Typography>

            <Button
              variant="contained"
              onClick={() => toggleHidden(user, { id })}
            >
              Unhide
            </Button>
          </Box>
        )
      })}
    </Frame>
  )
}

export default SubscriberHiddenFreelancers
