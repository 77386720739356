import React from 'react'
import { Button, TextField, Frame, Heading } from 'components'
import { useHistory, getFormValues, updateUser } from 'utils'

const SubscriberName = ({ user, setSnackMessage }) => {
  const history = useHistory()
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Change your name.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { firstName, lastName } = getFormValues(event)
          await updateUser(user, { firstName, lastName }).then(() => {
            history.push('/settings')
            setSnackMessage('Your name has been successfully updated')
          })
        }}
      >
        <TextField required name="firstName" label="First name" />
        <TextField required name="lastName" label="Last name" />

        <Button type="submit">Submit</Button>
      </form>
    </Frame>
  )
}

export default SubscriberName
