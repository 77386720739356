import React, { useState, useEffect } from 'react'
import {
  Box,
  Frame,
  Typography,
  Loader,
  FlagDialog,
  ImproveDialog,
  FreelancerProfileRecommendations,
  FreelancerProfileCalendarSection,
  FreelancerProfileContactSection,
  FreelancerExpertiseSection,
  FreelancerEquipmentSection,
  FreelancerCreditsSection,
  FreelancerCommitmentSection,
  Button,
  FreelancerWorkStatusSection,
  FreelancerProfileWLCalendarSection,
  FreelancerDiversitySection
} from 'components'
import {
  useParams,
  getUserByEmail,
  getUserRecommendations,
  useHasActiveSubscription,
  useContent,
  sendImproveProfileEmail,
} from 'utils'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import FreelancerProfileNotesSection from 'components/freelancer/FreelancerProfileNotesSection'
import { CUSTOM_STATIC_LANDER, CREDITS_ENABLED, DIVERSITY_TAGS_ENABLED, AVAILABLE_FROM_CALENDAR } from 'constants'

const FreelancerShowView = ({ setSnackMessage, user }) => {
  const { userEmail } = useParams()
  const [foundUser, setFoundUser] = useState()
  const [flagDialogOpen, setFlagDialogOpen] = useState(false)
  const [improveDialogOpen, setImproveDialogOpen] = useState(false)
  const { title } = useContent()

  useEffect(() => {
    if (userEmail) {
      getUserByEmail(userEmail).then((user) => {
        getUserRecommendations(user).then((recommendations) => {
          setFoundUser({ ...user, recommendations })
        })
      })
    }
  }, [userEmail])

  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    user?.id,
  )

  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  if (!foundUser) {
    return (
      <>
        <Helmet>
          <title>{title} - {userEmail}</title>
        </Helmet>
        <Loader />
      </>
    )
  }

  const onFlag = () => setFlagDialogOpen(true)
  const onImprove = () => setImproveDialogOpen(true)

  return (
    <Frame py={2.5} maxWidth="lg" user={user}>
      <Helmet>
        <title>
          {title} - {foundUser.firstName} {foundUser.lastName}
        </title>
      </Helmet>

      <FlagDialog
        user={user}
        flaggedUser={foundUser}
        onSubmit={() => {
          setSnackMessage('Thank you, we have flagged the user.')
          setFlagDialogOpen(false)
        }}
        onClose={() => setFlagDialogOpen(false)}
        open={flagDialogOpen}
      />

      <ImproveDialog
        user={user}
        flaggedUser={foundUser}
        onSubmit={!CUSTOM_STATIC_LANDER ? () => {
          setSnackMessage('Thank you, we have notified the user.')
          setFlagDialogOpen(false)
        } :
          (data) => sendImproveProfileEmail({
            to: foundUser.email,
            firstName: foundUser.firstName, 
            missingDetails: data,
          }).then(res => {
            setSnackMessage('Thank you, we have notified the user.')
            setImproveDialogOpen(false)
          }).catch(err => {
            console.log(data)
            setSnackMessage('Something went wrong. Please try again later.')
          })}
        onClose={() => setImproveDialogOpen(false)}
        open={improveDialogOpen}
      />

      {user ? (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box flex={1} mr={2.5}>
            <FreelancerProfileContactSection freelancer={foundUser} />
            {DIVERSITY_TAGS_ENABLED && (
              <FreelancerDiversitySection freelancer={foundUser} />
            )}
            <FreelancerExpertiseSection freelancer={foundUser} />
            {CREDITS_ENABLED && (
              <FreelancerCreditsSection freelancer={foundUser} />
            )}
            <FreelancerEquipmentSection freelancer={foundUser} />
            <FreelancerProfileRecommendations freelancer={foundUser} />
          </Box>

          <Box>
            <FreelancerProfileNotesSection freelancer={foundUser} />

            <Box display="flex" flexDirection="row" justifyContent="flex-start" style={{ gap: 20 }} flex={1} mb={3}>
              {onImprove && (
                <Button variant="outlined" onClick={onImprove} >
                  Improve this Profile
                </Button>
              )}

              {onFlag && (
                <Button variant="outlined" onClick={onFlag} >
                  Flag this Profile
                </Button>
              )}
            </Box>
            {!AVAILABLE_FROM_CALENDAR ? (
              <FreelancerProfileCalendarSection freelancer={foundUser} />
            ) :
              <>
                <FreelancerCommitmentSection freelancer={foundUser} />
                <FreelancerWorkStatusSection freelancer={foundUser} />
                <FreelancerProfileWLCalendarSection freelancer={foundUser} />
              </>
            }
          </Box>
        </Box>
      ) : (
        <Typography variant="h5">
          <Typography variant="h5" component="a" href="/login">
            Sign in{' '}
          </Typography>
          to reveal {foundUser.firstName}'s availability and contact info
        </Typography>
      )}
    </Frame>
  )
}

export default FreelancerShowView
