import { useState, useEffect } from 'react'
import { Box, Typography, Pill, Frame, Button } from 'components'
import { Chip } from '@material-ui/core'
import { useDiversityTags, updateUser } from "utils";
import { ProgressBarFooter } from '../FreelancerRoles/FreelancerAddRole';
import { NUM_ONBOARDING_STEPS } from 'constants';

export default function FreelancerDiversityTags({ user, onboarding, history, onNext }) {
    const [state, setState] = useState({
        diversityTags: user.diversityTags ? user.diversityTags : [],
    })
    const diversityTags = useDiversityTags()

    // useEffect(() => {
    //     updateUser(user, { diversityTags: state.diversityTags })
    // }, [state.diversityTags])

    if (!diversityTags)
        return null

    const userTags = user.diversityTags ? user.diversityTags : []

    const handleTagClick = (e) => {
        const newTags = state.diversityTags
        if (newTags.includes(e.target.textContent)) {
            newTags.splice(newTags.indexOf(e.target.textContent), 1)
            setState({ ...state, diversityTags: newTags })
        } else {
            newTags.push(e.target.textContent)
            setState({ ...state, diversityTags: newTags })
        }
    }
    const handleSubmit = (e) => {
        // e.preventDefault()
        updateUser(user, { diversityTags: state.diversityTags })
        if (onboarding) {
            onNext()
        } else {
            history.push("/profile")
        }
    }
    return (
        <Frame maxWidth="sm" user={user}>
            <Box mx="auto" maxWidth={530}>
                <Box flex={1} flexVariant="column center" mb={2.5}>
                    <Typography variant="h4" style={{ fontWeight: '600' }}>
                        Diversity Tags
                    </Typography>
                    <Box my={2} />
                    <Typography variant="body1">
                        Diversity in the workplace is important to both
                        CRRAL and Employers. Many Employers have
                        Diversity and Inclusion programs. Also sometimes a project calls for a certain point of view.
                        This step is entirely optional. If you don't want to
                        identify or no tags apply, tap the skip button.
                    </Typography>
                    <Box my={2} />
                </Box>
                <Box my={2} />
                <Box flexVariant="row align-center" flexWrap="wrap">
                    {diversityTags.map((diversityTag, i) => (
                        <Chip variant={
                            state.diversityTags.includes(diversityTag.name) ? 'default' : 'outlined'
                        }
                            color="primary"
                            style={{ marginRight: 8, marginBottom: 5 }}
                            key={i}
                            label={diversityTag.name}
                            name={diversityTag.name}
                            onClick={handleTagClick}
                        />
                    ))}
                </Box>
                {onboarding && (
                    <Box my={2} flexVariant="row justify-space-evenly">
                        <Button variant="outlined" style={{ width: '40%' }} onClick={() => onNext()}>Skip</Button>
                        <Button variant="contained" style={{ width: '40%' }} onClick={() => handleSubmit()}>Save</Button>
                    </Box>
                )}
                {!onboarding && (
                    <Box my={2} flexVariant="row justify-space-evenly">
                        <Button variant="contained" style={{ width: '40%' }} onClick={() => handleSubmit()}>Save</Button>
                        <Button variant="outlined" style={{ width: '40%' }} onClick={() => history.push('/profile')}>Cancel</Button>
                    </Box>
                )}
            </Box>
            {onboarding && (
                <ProgressBarFooter
                    valid={true}
                    stepIndex={5}
                    firstStep={5}
                    numSteps={NUM_ONBOARDING_STEPS}
                    onNext={() => onNext()}
                    onPrev={() => { }}
                />
            )}
        </Frame>
    )
}