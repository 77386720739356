import React from 'react'
import { Box, Typography } from 'components'
import { ButtonBase, Checkbox } from '@material-ui/core'

export function ListFilter({
  items,
  stateKey,
  filterState: state,
  setFilterState: setState,
  maxHeight = '40vh',
}) {
  return (
    <Box
      flex={1}
      flexVariant="row"
      flexWrap="wrap"
      width={500}
      maxHeight={maxHeight}
      overflow="hidden auto"
    >
      {items.map(({ id: itemId, label: itemLabel }) => (
        <ButtonBase
          key={itemId}
          style={{ width: '50%', justifyContent: 'flex-start' }}
          onClick={() =>
            setState((s) => ({
              ...s,
              [stateKey]: s[stateKey].includes(itemId)
                ? s[stateKey].filter((c) => c !== itemId)
                : s[stateKey].concat([itemId]),
            }))
          }
        >
          <Checkbox
            color="primary"
            checked={state[stateKey].includes(itemId)}
            style={{ marginRight: 0, padding: 5 }}
          />
          <Typography
            style={{ color: '#1d1d1d', fontSize: 12, whiteSpace: 'nowrap' }}
          >
            {itemLabel}
          </Typography>
        </ButtonBase>
      ))}
    </Box>
  )
}
