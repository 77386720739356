import React, { useState } from 'react'
import { Button } from 'components'
import { TextField, Box } from 'components'
import { formatPhoneNumber, useTheme } from 'utils'
import { updateUser } from 'utils'
import { CUSTOM_STATIC_LANDER, PRONOUNS_ENABLED } from 'constants'

export const VALID_URI_REGEX =
  /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/

const FreelancerEditProfile = ({ setSnackMessage, user, onSubmit }) => {
  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName, setLastName] = useState(user.lastName || '')
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '')
  const [website, setWebsite] = useState(user.website || '')
  const [websitePassword, setWebsitePassword] = useState(user.websitePassword || '')
  const [websites, setWebsites] = useState(user.websites || [])
  const [websitePasswords, setWebsitePasswords] = useState(user.websitePasswords || [])
  const theme = useTheme()
  const [about, setAbout] = useState(user.about || '')
  const [pronouns, setPronouns] = useState(user.pronouns || '')
  const lastLink = websites[websites.length - 1]
  const canAddLink =
    websites.length < 4 &&
    (typeof lastLink === 'undefined' ||
      (lastLink && !!VALID_URI_REGEX.test(lastLink)))

  return (
    <Box maxWidth={750} mx="auto">
      <Box display="flex">
        <Box flex={1} mr={2}>
          <TextField
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Box>
        <Box flex={1} mr={PRONOUNS_ENABLED && 2}>
          <TextField
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>

        {PRONOUNS_ENABLED && (
        <Box flex={1}>
          <TextField
            name="pronouns"
            label="Pronouns"
            placeholder="They/Them"
            value={pronouns}
            onChange={(e) => setPronouns(e.target.value)}
          />
        </Box>
        )}
      </Box>

      <TextField
        name="phoneNumber"
        label="Phone Number"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={(e) => {
          const phoneNumber = formatPhoneNumber(e.target.value)
          if (phoneNumber || phoneNumber.length === 0) {
            setPhoneNumber(phoneNumber)
          }
        }}
      />
      <Box display="flex" flexDirection="column">
        <Box
          className="website-item"
          display="flex"
          alignItems="center"
          flex={1}
          style={{ gap: 20 }}
        >
          <TextField
            name="website"
            label="LinkedIn Profile"
            placeholder="https://www.linkedin.com/in/yourname"
            value={website}
            onChange={(e) => {
              let website = e.target.value
              setWebsite(website.toLowerCase())
            }}
          />
          {!CUSTOM_STATIC_LANDER ? (
            <TextField
              name={`website-password`}
              label={`Website Password`}
              placeholder="Enter if required"
              value={websitePassword}
              onChange={(e) => {
                let val = e.target.value.toLowerCase()
                setWebsitePassword((w) => val)
              }}
            />
          ) : null}

        </Box>

        {websites.map((website, i) => (
          <Box
            className="website-item"
            display="flex"
            alignItems="center"
            flex={1}
            style={{ gap: 20 }}
          >
            <TextField
              name={`website-${i + 1}`}
              label={i === 0 ? 'Primary Website' : `Website ${i + 1}`}
              placeholder="Portfolio, Blog .etc"
              value={website}
              onChange={(e) => {
                let val = e.target.value.toLowerCase()
                setWebsites((w) => w.map((s, _i) => (i === _i ? val : s)))
              }}
            />
            <TextField
              name={`website-${i + 1}-password`}
              label={i === 0 ? 'Primary Website Password' : `Website ${i + 1} Password`}
              placeholder="Enter if required"
              value={websitePasswords[i]}
              onChange={(e) => {
                let val = e.target.value.toLowerCase()
                setWebsitePasswords((w) => websites.map((s, _i) => (i === _i ? val : w[_i])))
              }}
            />
            <Button
              variant="contained"
              style={{
                marginLeft: 16,
                marginTop: 26,
                background: theme.palette.red.main,
              }}
              onClick={() => {
                setWebsites((w) =>
                  w.filter((s, _i) => (i === _i ? false : true)),
                )
              }}
            >
              Delete
            </Button>
          </Box>
        ))}
        <Box flex={1} mt={2}>
          <Button
            disabled={!canAddLink}
            color="primary"
            variant="contained"
            onClick={() => setWebsites((w) => [...w, ''])}
          >
            Add Another Website
          </Button>
        </Box>
      </Box>

      <TextField
        multiline
        showCharacterCount
        name="about"
        rows={6}
        margin="normal"
        maxLength={400}
        placeholder=""
        label="About Me (400 characters)"
        value={about}
        onChange={(e) => {
          let about = e.target.value
          about.length <= 400 && setAbout(about)
        }}
      />

      <Button
        color="primary"
        id="profile-edit-submit"
        variant="contained"
        onClick={() => {
          if (!firstName || !lastName) {
            alert('Please enter your name')
          } else if (
            phoneNumber &&
            phoneNumber.length > 0 &&
            phoneNumber.length < 12
          ) {
            alert('Please enter a complete phone number')
          } else {
            const _websites = (websites || []).filter(
              (s) => !!VALID_URI_REGEX.test(s),
            )
            updateUser(user, {
              firstName: firstName || '',
              lastName: lastName || '',
              phoneNumber: phoneNumber || '',
              website: website || '',
              websites: _websites,
              websitePassword: websitePassword,
              websitePasswords: websitePasswords,
              about: about || '',
              pronouns: pronouns || '',
            })
            onSubmit()
          }
        }}
      >
        Save
      </Button>
    </Box>
  )
}

export default FreelancerEditProfile
