import React from 'react'
import { Frame, Typography } from 'components'
import ReactMarkdown from 'react-markdown'

const TermsView = ({ user }) => {
  return (
    <Frame user={user}>
      <Typography>
        <ReactMarkdown>{terms}</ReactMarkdown>
      </Typography>
    </Frame>
  )
}

export default TermsView


const casoTerms = `Last revision: Nov 27, 2023

** PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS
WEBSITE.**

The following Terms and Conditions govern and apply to your use of or
reliance upon this website maintained by CRRAL.com (the "Website").

Your access or use of the Website indicates that you have read,
understand and agree to be bound by these Terms and Conditions and any
other applicable laws, statutes and/or regulations. We may change these
Terms and Conditions at any time without notice, effective upon its
posting to the Website. Your continued use of the Website will be
considered your acceptance to the revised Terms and Conditions.

** 1. AGE RESTRICTION**

You must be at least eighteen (18) years of age to use this Website or
any services contained herein. Your access or use of this Website
indicates your representation that you are at least eighteen (18) years
of age. We assume no responsibility or liability for any
misrepresentation of your age.

** 2. INTELLECTUAL PROPERTY**

All intellectual property on the Website (except for User Generated
Content, as defined below) is owned by us or our licensors, which
includes materials protected by copyright, trademark, or patent laws.
All trademarks, service marks and trade names are owned, registered
and/or licensed by us. All content on the Website (except for User
Generated Content, as defined below), including but not limited to text,
software, code, designs, graphics, photos, sounds, music, videos,
applications, interactive features and all other content is a collective
work under Canadian and other copyright laws and is the proprietary
property of the Company; All rights reserved.

** 3. USE OF COMPANY MATERIALS**

We may provide you with certain information as a result of your use of
the Website including, but not limited to, documentation, data, or
information developed by us, and other materials which may assist in the
use of the Website or Services ("Company Materials"). The Company
Materials may not be used for any other purpose than the use of this
Website and the services offered on the Website. Nothing in these Terms
of Use may be interpreted as granting any license of intellectual
property rights to you.

** 4. USER GENERATED CONTENT**

"User Generated Content" is communications, materials, information,
data, opinions, photos, profiles, messages, notes, website links, text
information, music, videos, designs, graphics, sounds, and any other
content that you and/or other Website users post or otherwise make
available on or through the Website, except to the extent the content is
owned by us.

** 5. ACCOUNT AND ACCOUNT USE**

If your use of the Website requires an account identifying you as a user
of the Website (an "Account"):

a) you are solely responsible for your Account and the maintenance,
confidentiality and security of your Account and all passwords related
to your Account, and any and all activities that occur under your
Account, including all activities of any persons who gain access to your
Account with or without your permission;

b) you agree to immediately notify us of any unauthorized use of your
Account, any service provided through your Account or any password
related to your Account, or any other breach of security with respect to
your Account or any service provided through it, and you agree to
provide assistance to us, as requested, to stop or remedy any breach of
security related to your Account, and

c) you agree to provide true, current, accurate and complete customer
information as requested by us from time to time and you agree to
promptly notify us of any changes to this information as required to
keep such information held by us current, complete and accurate.

** 6. PAYMENT**

When you make a purchase on the Website, you agree to provide a valid
instrument to make a payment. Pay attention to the details of the
transaction, as your total price may include taxes, fees, and shipping
costs, all of which you are responsible for.

When you provide a payment instrument to us, you confirm that you are
permitted to use that payment instrument. When you make a payment, you
authorize us (and our designated payment processor) to charge the full
amount to the payment instrument you designate for the transaction. You
also authorize us to collect and store that funding instrument, along
with other related transaction information.

If you pay by credit or debit card, we may obtain a pre-approval from
the issuer of the card for an amount as high as the full price. If you
cancel a transaction before completion, this pre-approval may result in
those funds not otherwise being immediately available to you.

We may cancel any transaction if we believe the transaction violates
these Terms, or if we believe doing so may prevent financial loss.

In order to prevent financial loss to you or to us, we may contact your
funding instrument issuer, law enforcement, or affected third parties
(including other users) and share details of any payments you are
associated with, if we believe doing so may prevent financial loss or a
violation of law.

Payment for any on-going services is billed automatically until
notification that you would like to terminate your access to the
services.

** 7. SALE OF SERVICES**

We undertake to be as accurate as possible with all information
regarding our services. However, we do not guarantee the accuracy or
reliability of any information and you acknowledge and agree that you
use this information at your own risk.

** 8. ACCEPTABLE USE**

You agree not to use the Website for any unlawful purpose or any purpose
prohibited under this clause. You agree not to use the Website in any
way that could damage the Website, the services or the general business
of CRRAL.com.

You further agree not to use and/or access the Website:

a) To harass, abuse, or threaten others or otherwise violate any
person's legal rights;

b) To violate any intellectual property rights of us or any third
party;

c) To upload or otherwise disseminate any computer viruses or other
software that may damage the property of another;

d) To perpetrate any fraud;

e) To engage in or create any unlawful gambling, sweepstakes, or
pyramid scheme;

f) To publish or distribute any obscene or defamatory material;

g) To publish or distribute any material that incites violence, hate or
discrimination towards any group;

h) To unlawfully gather information about others.

** 9. PROTECTION OF PRIVACY**

The Website takes your privacy very seriously. The Website will never
give or sell your information to a third party.

Through your use of the Website, you may provide us with certain
information. By using the Website, you authorize us to use your
information in Canada and any other country where We may operate.

When you register for an account, you provide us with a valid email
address and may provide us with additional information, such as your
name, online portfolio link and/or billing information. Depending on how
you use our Website, we may also receive information from external
applications you use to access our Website, or we may receive
information through various web technologies, such as cookies, log
files, clear gifs, web beacons or others.

We use the information gathered from you to ensure your continued good
experience on our website, including through email communication.

If you would like to disable our access to any passive information we
receive from the use of various technologies, you may choose to disable
cookies in your web browser. Please be aware that we will still receive
information about you that you have provided, such as your email
address.

If you choose to terminate your account, we will store information about
you for the following period of time: 1 month. After that time, all
information about you will be deleted.

The Website uses Stripe as its credit card processor. Stripe's privacy
statement is
here: [https://stripe.com/us/privacy#1](https://stripe.com/us/privacy%231)

** 10. REVERSE ENGINEERING & SECURITY**

You may not undertake any of the following actions:

a) Reverse engineer, or attempt to reverse engineer or disassemble any
code or software from or on the Website;

b) Violate the security of the Website through any unauthorized access,
circumvention of encryption or other security tools, data mining or
interference to any host, user or network.

** 11. DATA LOSS**

We are not responsible for the security of your Account or Content. Your
use of the Website is at your own risk.

** 12. INDEMNIFICATION**

You defend and indemnify CRRAL.com and any of its affiliates and hold us
harmless against any and all legal claims and demands, including
reasonable attorney's fees, which may arise from or relate to your use
or misuse of the Website, your breach of these Terms and Conditions, or
your conduct or actions. We will select our own legal counsel and may
participate in our own defence, if we wish to so.

** 13. SPAM POLICY**

You are strictly prohibited from using the Website or any of our
services for illegal spam activities, including gathering email
addresses and personal information from others or sending any mass
commercial emails.

** 14A. THIRD-PARTY LINKS**

The website posts links to third party websites and online portfolios.
We are not responsible or liable for any loss or damage caused as a
result of your use of any third party services linked to from our
Website.

**14B. THIRD-PARTY COMMERCIAL INTERACTION** The Website exists to
facilitate connections between Associations (and other professional or
charitable organizations) and their members. Association members
maintain a profile on The Website. The Website does not guarantee the
personal or professional conduct, nor the quality of work provided by
any member appearing on the Website, nor does the Website guarantee
payment between the Association or affiliated employer. Any hiring,
contracts, negotiations and payments are solely between the hirer and
the member.

** 15. VARIATION**

To the extent any part or sub-part of these Terms and Conditions is held
ineffective or invalid by any court of law, the prior, effective version
of these Terms and Conditions be considered enforceable and valid to the
fullest extent.

** 16. SERVICE INTERRUPTIONS**

We may need to interrupt your access to the Website to perform
maintenance or emergency services on a scheduled or unscheduled basis.
You agree that your access to the Website may be affected by
unanticipated or unscheduled downtime, for any reason, but that the we
will have no liability for any damage or loss caused as a result of such
downtime.

** 17. TERMINATION OF ACCOUNT**

We may, in our sole discretion, suspend, restrict or terminate your
Account and your use of the Website, effective at any time, without
notice to you, for any reason, including because the operation or
efficiency of the Website or our or any third party's equipment or
network is impaired by your use of the Website, any amount is past due
from you to us, we have received a third party complaint which relates
to your use or misuse of the Website, or you have been or are in breach
of any term or condition of these Terms and Conditions. We will have no
responsibility to notify any third party, including any third party
providers of services, merchandise or information, of any suspension,
restriction or termination of your access to the Website.

** 18. NO WARRANTIES**

Your use of the Website is at your sole and exclusive risk and any
services provided by us are on an "as is" basis. We disclaim any and
all express or implied warranties of any kind, including, but not
limited to the implied warranty of fitness for a particular purpose and
the implied warranty of merchantability. We make no warranties that the
Website will meet your needs or that the Website will be uninterrupted,
error-free, or secure. We also make no warranties as to the reliability
or accuracy of any information on the Website or obtained through the
Services. Any damage that may occur to you, through your computer
system, or as a result of loss of your data from your use of the Website
is your sole responsibility and we are not liable for any such damage or
loss.

**19. LIMITATION OF LIABILITY** The Website, its employees, agents,
officers and directors will not be liable for any direct, indirect,
incidental, punitive, consequential, special, exemplary, or other
damages, including, without limitation, loss of revenue or income, pain
and suffering, emotional distress, or similar damages, or damages
resulting from any (i) errors or omissions in content, (ii) any
unauthorized access to or use of our servers and/or any and all personal
information and/or financial information stored therein, (iii) any
interruption or cessation of transmission to or from our website, (iv)
any bugs, viruses, trojan horses, or the like, which may be transmitted
to or through our website by any third party, or (v) for any loss or
damage of any kind incurred as a result of your use of any content
posted, emailed, transmitted, or otherwise made available via the
website. This limitation of liability applies regardless of the legal
theory giving rise to the damages, and even if The Website has been
advised of the possibility of such damages. The foregoing limitation of
liability shall apply to the fullest extent permitted by law in the
applicable jurisdiction.

You specifically acknowledge that The Website, its employees, agents,
officers and directors shall not be liable for user content or the
defamatory, offensive, or illegal conduct of any third party and that
the risk of harm or damage from the foregoing rests entirely with you.

**20. INDEMNITY** You agree to defend, indemnify and hold harmless The
Website, its employees, agents, officers and directors from and against
any and all claims, damages, obligations, judgments, losses,
liabilities, costs or debt, attorney's fees and other expenses arising
from your use of and access to the website. This defense and
indemnification obligation will survive these terms and your use of the
website. In such a case, The Website will provide you with written
notice of such claim, suit or action.

** 21. PRIVACY**

Internet communications are subject to interception, loss or alteration
and, as consequence, you acknowledge that information or data you
provide by electronic means by accessing or using this Website are not
confidential or exclusive, except to the extent required by the
applicable laws, and that communications by email may be intercepted,
altered or lost.

** 22. LIMITATION ON LIABILITY**

We are not liable for any damages that may occur to you as a result of
your use of the Website, to the fullest extent permitted by law.

**23. CHANGES TO THESE TERMS AND CONDITIONS** We reserve the right to
change, modify or amend these Terms and Conditions at any time to
reflect changes in our practices and service offerings. If we modify our
Terms and Conditions we will update the "Effective Date" and such
changes will be effective upon posting. It is your obligation to check
our current Terms and Conditions for any changes. Any revised Terms and
Conditions will only apply prospectively to Information collected or
modified after the Effective Date of the revisions.

**24. QUESTIONS** If you have any further questions about The Terms and
Conditions please send us an email at support@CRRAL.com`

const terms = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'crral-white-label' ? casoTerms : `**TERMS AND CONDITIONS OF CRRAL.COM**

Last revision: Dec 8, 2019

** PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.**

The following Terms and Conditions govern and apply to your use of or reliance upon this website maintained by CRRAL.com (the "Website").

Your access or use of the Website indicates that you have read, understand and agree to be bound by these Terms and Conditions and any other applicable laws, statutes and/or regulations. We may change these Terms and Conditions at any time without notice, effective upon its posting to the Website. Your continued use of the Website will be considered your acceptance to the revised Terms and Conditions. 

** 1. AGE RESTRICTION**

You must be at least eighteen (18) years of age to use this Website or any services contained herein. Your access or use of this Website indicates your representation that you are at least eighteen (18) years of age. We assume no responsibility or liability for any misrepresentation of your age.

** 2. INTELLECTUAL PROPERTY**

All intellectual property on the Website (except for User Generated Content, as defined below) is owned by us or our licensors, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by us. All content on the Website (except for User Generated Content, as defined below), including but not limited to text, software, code, designs, graphics, photos, sounds, music, videos, applications, interactive features and all other content is a collective work under Canadian and other copyright laws and is the proprietary property of the Company; All rights reserved.

** 3. USE OF COMPANY MATERIALS**

We may provide you with certain information as a result of your use of the Website including, but not limited to, documentation, data, or information developed by us, and other materials which may assist in the use of the Website or Services ("Company Materials"). The Company Materials may not be used for any other purpose than the use of this Website and the services offered on the Website. Nothing in these Terms of Use may be interpreted as granting any license of intellectual property rights to you. 

** 4. USER GENERATED CONTENT**

"User Generated Content" is communications, materials, information, data, opinions, photos, profiles, messages, notes, website links, text information, music, videos, designs, graphics, sounds, and any other content that you and/or other Website users post or otherwise make available on or through the Website, except to the extent the content is owned by us.

** 5. ACCOUNT AND ACCOUNT USE**

If your use of the Website requires an account identifying you as a user of the Website (an "Account"):

a) you are solely responsible for your Account and the maintenance, confidentiality and security of your Account and all passwords related to your Account, and any and all activities that occur under your Account, including all activities of any persons who gain access to your Account with or without your permission;

b) you agree to immediately notify us of any unauthorized use of your Account, any service provided through your Account or any password related to your Account, or any other breach of security with respect to your Account or any service provided through it, and you agree to provide assistance to us, as requested, to stop or remedy any breach of security related to your Account, and

c) you agree to provide true, current, accurate and complete customer information as requested by us from time to time and you agree to promptly notify us of any changes to this information as required to keep such information held by us current, complete and accurate. 

** 6. PAYMENT**

When you make a purchase on the Website, you agree to provide a valid instrument to make a payment. Pay attention to the details of the transaction, as your total price may include taxes, fees, and shipping costs, all of which you are responsible for.

When you provide a payment instrument to us, you confirm that you are permitted to use that payment instrument. When you make a payment, you authorize us (and our designated payment processor) to charge the full amount to the payment instrument you designate for the transaction. You also authorize us to collect and store that funding instrument, along with other related transaction information.

If you pay by credit or debit card, we may obtain a pre-approval from the issuer of the card for an amount as high as the full price. If you cancel a transaction before completion, this pre-approval may result in those funds not otherwise being immediately available to you.

We may cancel any transaction if we believe the transaction violates these Terms, or if we believe doing so may prevent financial loss.

In order to prevent financial loss to you or to us, we may contact your funding instrument issuer, law enforcement, or affected third parties (including other users) and share details of any payments you are associated with, if we believe doing so may prevent financial loss or a violation of law.

Payment for any on-going services is billed automatically until notification that you would like to terminate your access to the services.

** 7. SALE OF SERVICES**

We undertake to be as accurate as possible with all information regarding our services. However, we do not guarantee the accuracy or reliability of any information and you acknowledge and agree that you use this information at your own risk. 

** 8. ACCEPTABLE USE**

You agree not to use the Website for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website in any way that could damage the Website, the services or the general business of CRRAL.com.

You further agree not to use and/or access the Website:

a) To harass, abuse, or threaten others or otherwise violate any person's legal rights;

b) To violate any intellectual property rights of us or any third party;

c) To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;

d) To perpetrate any fraud;

e) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;

f) To publish or distribute any obscene or defamatory material;

g) To publish or distribute any material that incites violence, hate or discrimination towards any group;

h) To unlawfully gather information about others.

** 9. PROTECTION OF PRIVACY**

The Website takes your privacy very seriously. The Website will never give or sell your information to a third party.

Through your use of the Website, you may provide us with certain information. By using the Website, you authorize us to use your information in Canada and any other country where We may operate.

When you register for an account, you provide us with a valid email address and may provide us with additional information, such as your name, online portfolio link and/or billing information. Depending on how you use our Website, we may also receive information from external applications you use to access our Website, or we may receive information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.

We use the information gathered from you to ensure your continued good experience on our website, including through email communication.

If you would like to disable our access to any passive information we receive from the use of various technologies, you may choose to disable cookies in your web browser. Please be aware that we will still receive information about you that you have provided, such as your email address. 

If you choose to terminate your account, we will store information about you for the following period of time: 1 month. After that time, all information about you will be deleted.

The Website uses Stripe as its credit card processor. Stripe’s privacy statement is here: [https://stripe.com/us/privacy#1](https://stripe.com/us/privacy%231) 

** 10. REVERSE ENGINEERING & SECURITY**

You may not undertake any of the following actions:

a) Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website;

b) Violate the security of the Website through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.

** 11. DATA LOSS**

We are not responsible for the security of your Account or Content. Your use of the Website is at your own risk.

** 12. INDEMNIFICATION**

You defend and indemnify CRRAL.com and any of its affiliates and hold us harmless against any and all legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of the Website, your breach of these Terms and Conditions, or your conduct or actions. We will select our own legal counsel and may participate in our own defence, if we wish to so.

** 13. SPAM POLICY**

You are strictly prohibited from using the Website or any of our services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.

** 14A. THIRD-PARTY LINKS**

We post links to third party websites and online portfolios. We are not responsible or liable for any loss or damage caused as a result of your use of any third party services linked to from our Website. 

**14B. THIRD-PARTY COMMERCIAL INTERACTION**
The Website exists solely to facilitate connections between subscribers and the contract workers "Freelancers" who maintain a profile on The Website. The Website does not guarantee the personal or professional conduct, nor the quality of work provided by any Freelancer appearing on the Website, nor does the Website guarantee payment between subscriber and the Freelancer. Any hiring, contracts, negotiations and payments are solely between subscribers and the Freelancer.

** 15. VARIATION**

To the extent any part or sub-part of these Terms and Conditions is held ineffective or invalid by any court of law, the prior, effective version of these Terms and Conditions be considered enforceable and valid to the fullest extent.

** 16. SERVICE INTERRUPTIONS**

We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that the we will have no liability for any damage or loss caused as a result of such downtime.

** 17. TERMINATION OF ACCOUNT**

We may, in our sole discretion, suspend, restrict or terminate your Account and your use of the Website, effective at any time, without notice to you, for any reason, including because the operation or efficiency of the Website or our or any third party's equipment or network is impaired by your use of the Website, any amount is past due from you to us, we have received a third party complaint which relates to your use or misuse of the Website, or you have been or are in breach of any term or condition of these Terms and Conditions. We will have no responsibility to notify any third party, including any third party providers of services, merchandise or information, of any suspension, restriction or termination of your access to the Website.

** 18. NO WARRANTIES**

Your use of the Website is at your sole and exclusive risk and any services provided by us are on an "as is" basis. We disclaim any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website will meet your needs or that the Website will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. Any damage that may occur to you, through your computer system, or as a result of loss of your data from your use of the Website is your sole responsibility and we are not liable for any such damage or loss.

**19. LIMITATION OF LIABILITY**
The Website, its employees, agents, officers and directors will not be liable for any direct, indirect, incidental, punitive, consequential, special, exemplary, or other damages, including, without limitation, loss of revenue or income, pain and suffering, emotional distress, or similar damages, or damages resulting from any (i) errors or omissions in content, (ii) any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored therein, (iii) any interruption or cessation of transmission to or from our website, (iv) any bugs, viruses, trojan horses, or the like, which may be transmitted to or through our website by any third party, or (v) for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the website. This limitation of liability applies regardless of the legal theory giving rise to the damages, and even if The Website has been advised of the possibility of such damages. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction.

You specifically acknowledge that The Website, its employees, agents, officers and directors shall not be liable for user content or the defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with you.

**20. INDEMNITY**
You agree to defend, indemnify and hold harmless The Website, its employees, agents, officers and directors from and against any and all claims, damages, obligations, judgments, losses, liabilities, costs or debt, attorney's fees and other expenses arising from your use of and access to the website. This defense and indemnification obligation will survive these terms and your use of the website. In such a case, The Website will provide you with written notice of such claim, suit or action.

** 21. PRIVACY**

Internet communications are subject to interception, loss or alteration and, as consequence, you acknowledge that information or data you provide by electronic means by accessing or using this Website are not confidential or exclusive, except to the extent required by the applicable laws, and that communications by email may be intercepted, altered or lost. 

** 22. LIMITATION ON LIABILITY**

We are not liable for any damages that may occur to you as a result of your use of the Website, to the fullest extent permitted by law. 

**23. CHANGES TO THESE TERMS AND CONDITIONS**
We reserve the right to change, modify or amend these Terms and Conditions at any time to reflect changes in our practices and service offerings. If we modify our Terms and Conditions we will update the "Effective Date" and such changes will be effective upon posting. It is your obligation to check our current Terms and Conditions for any changes. Any revised Terms and Conditions will only apply prospectively to Information collected or modified after the Effective Date of the revisions.

**24. QUESTIONS**
If you have any further questions about The Terms and Conditions please send us an email at support@CRRAL.com
`
