import React, { useEffect, useState, useMemo } from 'react'
import { Frame, Loader, FreelancerList } from 'components'
import { searchUsers, useParams, filterHiddenUsers } from 'utils'

const SearchView = ({ freelancers, user, history, setSnackMessage }) => {
  const [results, setResults] = useState([])
  let { query, type } = useParams()
  query = query.replace(/-/g, ' ')

  const users = useMemo(
    () =>
      Object.values(freelancers)
        .map((d) => Object.values(d))
        .flat()
        .filter(filterHiddenUsers(user ? user.hiddenUsers : [])),
    [user, freelancers],
  )

  useEffect(() => {
    if (!type) {
      setResults(searchUsers(query, users))
    } else if (type === 'titles') {
      setResults(searchUsers(query, users, ['titles']))
    } else if (type === 'skills') {
      setResults(searchUsers(query, users, ['skills']))
    }
  }, [query, users, type])

  if (users.length === 0) {
    return <Loader />
  }

  return (
    <Frame maxWidth="lg" user={user}>
      <FreelancerList
        history={history}
        user={user}
        showAd={!user}
        numRows={999}
        label={`Search: ${query}`}
        users={results}
      />
    </Frame>
  )
}

export default SearchView
