import React, { useState } from 'react'
import { Heading, Checklist, TextField, Box, Frame, Select, MenuItem, Button, Typography, Switch } from 'components'
import { updateUser } from 'utils'
import { ProgressBarFooter } from '../FreelancerRoles/FreelancerAddRole'
import { NUM_ONBOARDING_STEPS } from 'constants'

const FreelancerSelectEmploymentStatus = ({
    onboarding,
    user,
    history,
    onSubmit = () => { },
    onNext = () => { },
}) => {
    const [state, setState] = useState({ employment: { status: 'Full Time', scheduledDays: [], vagueDays: 3, remoteStatus: 'In when asked' } })
    const [formIndex, setFormIndex] = useState(0)

    const handleNext = () => {
        if (formIndex < 1) {
            setFormIndex(formIndex + 1)
            return
        }
        updateUser(user, state).then(() => {
            if (onboarding) {
                onNext()
                return
            }
            history.push('/profile')
        })
        
    }
    return (
        <Frame maxWidth="xs" user={user}>
            <EmploymentForm formIndex={formIndex} state={state} setState={setState} onNext={handleNext} />

            {!onboarding && formIndex < 2 && (
                <Button type="submit" onClick={() => {
                    handleNext()
                }}>Next</Button>
            )}
            {onboarding && (
                <ProgressBarFooter
                    valid={true}
                    stepIndex={5}
                    firstStep={5}
                    numSteps={NUM_ONBOARDING_STEPS}
                    onNext={() => {
                        handleNext()
                    }}
                    onPrev={() => { }}
                />
            )}
        </Frame>
    )
}

const EmploymentForm = ({ formIndex, state, setState, onNext }) => {
    if (formIndex === 0) {
        return (
            <Box mx="auto" maxWidth={430}>
                <Heading variant='h5'>What's your employment status?</Heading>
                <Box display="flex" justifyContent="center">
                    <Select value={state.employment?.status} onChange={e => setState({ ...state, employment: { ...state.employment, status: e.target.value } })}>
                        <MenuItem value="Full Time">Full Time</MenuItem>
                        <MenuItem value="Contract">Contract</MenuItem>
                        <MenuItem value="Freelance">Freelance</MenuItem>
                    </Select>
                </Box>
            </Box>
        )
    }
    if (formIndex === 1) {
        return (
            <Box mx="auto" maxWidth={430}>
                <Typography variant='h5' align='center'>If you have a regular schedule, click all days you’re in.</Typography>
                <Checklist
                    buttonLabel="Next"
                    multicheck
                    items={[
                        { id: 1, label: 'Monday', value:1 },
                        { id: 2, label: 'Tuesday', value:2 },
                        { id: 3, label: 'Wednesday', value:3 },
                        { id: 4, label: 'Thursday', value:4 },
                        { id: 5, label: 'Friday', value:5 },
                        { id: 6, label: 'Saturday', value:6 },
                        { id: 0, label: 'Sunday', value:0 },
                    ]}
                    onChange={(items) => setState({ ...state, employment: { ...state.employment, scheduledDays: items.map((i) => i.id) } })}
                />
                <Typography variant='h5' align='center'>If your days vary week-to-week, indicate how many days you typically come in.</Typography>
                <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                    <TextField
                        type='number'
                        style={{ width: 'fit-content' }}
                        inputProps={{ min: 0, max: 7, shrink: true }}
                        value={state.employment.vagueDays}
                        onChange={e => setState({ ...state, employment: { ...state.employment, vagueDays: e.target.value } })}
                    />
                    <Typography style={{ marginLeft: 10 }}>days per week.</Typography>
                </Box>
                <Typography variant='h5' align='center'>If you don’t regularly come in, click the one that best applies.</Typography>
                <Box display='flex' flexDirection='row' justifyContent='space-evenly'>
                    <Switch
                        label='In when asked'
                        checked={state.employment.remoteStatus === 'In when asked'}
                        onChange={() => setState({ ...state, employment: { ...state.employment, remoteStatus: 'In when asked' } })}
                        color='primary'
                    />
                    <Switch
                        label='Important meetings'
                        checked={state.employment.remoteStatus === 'Important meetings'}
                        onChange={() => setState({ ...state, employment: { ...state.employment, remoteStatus: 'Important meetings' } })}
                        color='primary'
                    />
                    <Switch
                        label='Remote only'
                        checked={state.employment.remoteStatus === 'Remote only'}
                        onChange={() => setState({ ...state, employment: { ...state.employment, remoteStatus: 'Remote only' } })}
                        color='primary'
                    />
                </Box>
            </Box>
        )
    }
}

export default FreelancerSelectEmploymentStatus
