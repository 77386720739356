import React, { useState } from 'react'
import {
  Box,
  ColorGuide,
  Typography,
  ArrowBackwardIcon,
  Divider,
  ArrowForwardIcon,
  Pill,
  Switch,
} from 'components'
import { getAvailabilityForDate, useParams } from 'utils'
import firebase from 'utils/firebase'
import ReactCalendar from 'react-calendar'
import { format, parse } from 'date-fns'
import './calendar.css'
import { AVAILABLE_FROM_CALENDAR } from 'constants.js'

export default function FreelancerProfileCalendarSection({
  freelancer,
  onEdit,
}) {
  const message = getAvailabilityForDate(new Date(), freelancer)?.message || ''
  const lastUpdatedDate = freelancer.lastUpdated
    ? format(freelancer.lastUpdated.toDate(), 'MMMM do, yyyy')
    : null

  const setUser = (data) =>
    firebase
      .firestore()
      .collection('users')
      .doc(freelancer.id)
      .set(data, { merge: true })

  return (
    <Box
      maxWidth={420}
      boxSizing="border-box"
      border="1px solid #DDD"
      borderRadius={10}
      py={3}
      px={3.5}
    >
      {onEdit && !AVAILABLE_FROM_CALENDAR && (
        <Box
          display="flex"
          alignItems="center"
          padding={1}
          mb={2.5}
          flexVariant="row space-between"
          style={freelancer.hidden ? { backgroundColor: 'red' } : { backgroundColor: 'green' }}
        >
          <Typography color='secondary'>Profile is {!freelancer.hidden ? 'Visible' : 'Hidden'}</Typography>
          <Switch variant="small" checked={!freelancer.hidden} onChange={e => setUser({ hidden: !e.target.checked })} />
        </Box>
      )}
      <Box flexVariant="space-between align-center">
        <Typography variant="h5" style={{ fontWeight: '600' }}>
          Availability
        </Typography>

        {onEdit && (
          <Pill size="small" onClick={onEdit}>
            Edit
          </Pill>
        )}
      </Box>

      {lastUpdatedDate && (
        <Typography variant="body2" style={{ marginTop: 4 }}>
          Last updated on {lastUpdatedDate}
        </Typography>
      )}

      {message && (
        <>
          <Typography variant="h5" style={{ marginTop: 16, fontWeight: '600' }}>
            Calendar Note
          </Typography>

          <Typography variant="body2" style={{ marginTop: 4 }}>
            {message}
          </Typography>
        </>
      )}

      <Divider />

      <SmallCalendar user={freelancer} />

      <Box maxWidth={400} mx="auto" my={3}>
        <ColorGuide />
      </Box>

    </Box>
  )
}

const SmallCalendar = ({ user, hideWeekends }) => {
  const { date: dateParam } = useParams()
  const startDate = parse(
    dateParam || format(new Date(), 'yyyy-MM-dd'),
    'yyyy-MM-dd',
    +new Date(),
  )
  const [activeDate] = useState(startDate)

  const getTileClassName = ({ date, view }) => {
    const today = getAvailabilityForDate(date, user)
    return availabilityClasses[today.status]
  }

  const getTileContent = ({ date, view }) => {
    const today = getAvailabilityForDate(date, user)
    if (today.message) {
      return (
        <AvailabilityMessageIndicator
          color={['gray', '#52B97F', '#F24741', '#ECB230'][today.status]}
          hideWeekends={hideWeekends}
        />
      )
    }
  }

  return (
    <ReactCalendar
      maxDetail="month"
      minDetail="month"
      className="mini-calendar"
      formatShortWeekday={(_, date) => days[date.getDay()]}
      nextLabel={<ArrowForwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />}
      prevLabel={
        <ArrowBackwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />
      }
      tileClassName={getTileClassName}
      tileContent={getTileContent}
      defaultActiveStartDate={startDate}
      activeDate={activeDate}
    />
  )
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const availabilityClasses = [
  'react-calendar__tile--unset',
  'react-calendar__tile--available',
  'react-calendar__tile--unavailable',
  'react-calendar__tile--maybe-available',
]

function AvailabilityMessageIndicator({ color }) {
  return (
    <Box
      bgcolor={color}
      style={{
        width: 4,
        height: 4,
        borderRadius: 20,
        position: 'absolute',
        bottom: 2,
      }}
    />
  )
}
