import React, { useEffect, useState } from 'react'
import { Frame, Typography, TextField, Button, Heading } from 'components'
import {
  getFormValues,
  useParams,
  updateRecommendations,
  getUserByEmail,
} from 'utils'

const FreelancerRecommendationCreate = ({ user, loading }) => {
  const { userEmail, name } = useParams()
  const [foundUser, setFoundUser] = useState()
  const [submitted, setSubmitted] = useState()

  useEffect(() => {
    if (userEmail) {
      getUserByEmail(userEmail).then(setFoundUser)
    }
  }, [userEmail])

  if (!foundUser && !loading) {
    return (
      <Frame maxWidth="sm" user={user}>
        <Typography align="center" variant="h6">
          Sorry, we could not find a user with that email
        </Typography>
      </Frame>
    )
  }

  if (submitted) {
    return (
      <Frame maxWidth="sm" user={user}>
        <Typography align="center" variant="h6">
          Thanks for your recommendation!
        </Typography>
      </Frame>
    )
  }

  return (
    <Frame maxWidth="sm" user={user}>
      <Heading>Complete your recommendation for {foundUser.firstName}</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          setSubmitted(true)
          return updateRecommendations(foundUser, getFormValues(event))
        }}
      >
        <TextField required name="name" label="Your Name" defaultValue={name} />
        <TextField required name="company" label="Your company" />
        <TextField required name="role" label="Your role" />
        <TextField
          required
          multiline
          rows={10}
          name="note"
          label="Your recommendation"
        />
        <Button type="submit">Send</Button>
      </form>
    </Frame>
  )
}

export default FreelancerRecommendationCreate
