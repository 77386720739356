import React from 'react'
import { Box, Divider, Typography, Frame, Button, Heading } from 'components'
import { updateUser } from 'utils'

const FreelancerVisibility = ({ history, user, setSnackMessage }) => {
  const makeHiddenHandler = (type) => () => {
    updateUser(user, { hidden: type })
    setSnackMessage(
      type
        ? 'Your profile is now hidden from subscribers. You can make it visible again at any time.'
        : 'Your profile is visible again.',
    )
    history.push('/')
  }

  const hidden = user.hidden

  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>{hidden ? 'Unhide' : 'Hide'} My Profile</Heading>

      <Box flexVariant="column center">
        {hidden ? (
          <Button
            disabled={!hidden}
            variant={hidden ? 'contained' : 'outlined'}
            color="primary"
            onClick={makeHiddenHandler(false)}
          >
            Show my profile and availability
          </Button>
        ) : (
          <Button
            disabled={hidden}
            variant={hidden ? 'outlined' : 'contained'}
            color="primary"
            onClick={makeHiddenHandler(true)}
          >
            Hide my profile and availability
          </Button>
        )}
      </Box>

      <Divider variant="middle" />

      <Typography align="center" variant="body1">
        When you hide your profile, you become invisible to potential employers. But keep your account. When you want to be seen again, just come here and unhide.
      </Typography>
    </Frame>
  )
}

export default FreelancerVisibility
