import React from 'react'
import { Frame, Typography, TextField, Divider, Button } from 'components'
import { requestRecommendation, getFormValues, useHistory } from 'utils'

const FreelancerRecommendationRequest = ({ user, setSnackMessage }) => {
  const history = useHistory()

  return (
    <Frame maxWidth="xs" user={user}>
      <Typography align="center" variant="h6">
        Ask for a recommendation from a client, employer or colleague. <br />
        Enter their name and email below.
      </Typography>

      <Divider variant="middle" />

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { firstName, lastName, email } = getFormValues(event)

          requestRecommendation({
            to: email,
            recipientFirstName: firstName,
            recipientLastName: lastName,
            userName: `${user.firstName} ${user.lastName}`,
            userRoles: Object.values(user.roles)
              .map((c) => c.label)
              .join(','),
            userEmail: user.email,
          }).then(function (result) {
            console.log(result)
            setSnackMessage('Your request has been sent!')

            history.push('/recommendations')
          }).catch(e => console.log(e))
        }}
      >
        <TextField required name="firstName" label="First Name" />
        <TextField required name="lastName" label="Last Name" />
        <TextField required name="email" label="Email Address" />
        <Button type="submit">Send request</Button>
      </form>
    </Frame>
  )
}

export default FreelancerRecommendationRequest
