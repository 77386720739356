import React from 'react'
import { Button, MUIDialog, Box, Typography } from 'components'

const SetCalendarNagDialog = ({ open, onClose, onCalendar }) => {
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={3} display="flex" flexDirection="column" alignItems="center">
        <Typography align="center" variant="h4">
          To see the Job Board, please confirm your calendar is up-to-date.
        </Typography>
        <Box pt={3} display="flex" alignItems="center">
          <Button
            variant="contained"
            onClick={onCalendar}
            color="primary"
            autoFocus
            style={{ marginRight: 12 }}
          >
            Update my calendar
          </Button>
          <Button
            variant="contained"
            onClick={onClose}
            color="primary"
            autoFocus
          >
            My calendar is good
          </Button>
        </Box>
      </Box>
    </MUIDialog>
  )
}

export default SetCalendarNagDialog
