import { isAfter, isBefore, isSameDay, isWeekend } from 'date-fns'
import { getAvailabilityForDate } from 'utils'

export const getColorFromStatus = (s) =>
  ['gray', '#32b87f', '#ff2c41', '#ecb22e'][s]
const availabilityClasses = [
  'react-calendar__tile--unset',
  'react-calendar__tile--available',
  'react-calendar__tile--unavailable',
  'react-calendar__tile--maybe-available',
]
export const getTileClassName =
  ({ user, date, availability }) =>
  ({ date: tileDate }) => {
    const existingAvail = getAvailabilityForDate(tileDate, user)
    if (isWeekend(tileDate) && !user.unsafeWeekends) return ''

    const avails = Object.entries(user.availability).sort(
      (a, b) => +new Date(b[0]) - +new Date(a[0]),
    )
    const nextAvailIndex = avails.findIndex((a) =>
      isBefore(new Date(a[0]), date),
    )
    const nextAvail = avails[nextAvailIndex - 1]
    const nextAvailDate =
      nextAvail && nextAvail[0] ? new Date(nextAvail[0]) : null

    let status = existingAvail.status
    // show preview of indefinite
    if (
      isSameDay(date, tileDate) ||
      (availability.indefinite &&
        isAfter(tileDate, date) &&
        isBefore(tileDate, nextAvailDate))
    ) {
      status = availability.status
    }

    let classes = availabilityClasses[status]
    const base = 'react-calendar__tile--availability'
    if (+tileDate === +date) classes += ' react-calendar__tile--active'
    if (existingAvail.isStartRange) classes += ` ${base}-rangeStart`
    if (existingAvail.isMiddleRange) classes += ` ${base}-range`
    if (existingAvail.isEndRange) classes += ` ${base}-rangeEnd`
    return classes
  }
