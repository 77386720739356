import * as React from 'react'
import {
  Datagrid,
  List,
  SimpleForm,
  Create,
  Edit,
  TextInput,
  TextField,
  ShowButton,
  Pagination,
} from 'react-admin'

export const RoleList = props => (
  <List
    {...props}
    title="Roles List"
    perPage={100}
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const RoleEdit = props => (
  <Edit {...props} title="Roles Edit" undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)

export const RoleCreate = props => (
  <Create {...props} title="Roles Create">
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)
