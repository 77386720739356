import React from 'react'
import FreelancerSelectCity from '../FreelancerCreate/FreelancerSelectCity'
import { useHistory } from 'utils'

const FreelancerCity = ({ user, markets, setSnackMessage }) => {
  const history = useHistory()
  return (
    <FreelancerSelectCity
      user={user}
      markets={markets}
      onSubmit={() => {
        setSnackMessage('Your city has been successfully updated')
        history.push('/settings')
      }}
    />
  )
}

export default FreelancerCity
