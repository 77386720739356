import React from 'react'
import { Box } from 'components'
import { FilterCheckbox } from './FilterCheckbox'

export function WorkingStyleFilter({
  filterState: state,
  setFilterState: setState,
}) {
  const { working_style } = state

  return (
    <Box display="flex" flex={1} flexDirection="column" minWidth={160}>
      <FilterCheckbox
        label="Remote only"
        checked={working_style === 'remote'}
        onChange={() =>
          setState({
            ...state,
            working_style: working_style === 'remote' ? undefined : 'remote',
          })
        }
      />

      <FilterCheckbox
        label="In-Office or Hybrid only"
        checked={working_style === 'in-office'}
        onChange={() =>
          setState({
            ...state,
            working_style:
              working_style === 'in-office' ? undefined : 'in-office',
          })
        }
      />
    </Box>
  )
}
