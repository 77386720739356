import React from 'react'
import { Button, Typography } from 'components'
import { Box, Dialog } from '@material-ui/core'
import { useContent } from 'utils'

function MissionDialog({ open, onClose }) {
  const { title } = useContent()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{ maxWidth: 800, margin: '0 auto' }}
    >
      <Box p={3} textAlign="center">
        <Typography color="primary" variant="h3">
          About {title}
        </Typography>

        <Box mt={1} mb={4}>
          {title === 'CASO' ? (
            <>
              <Typography>
                We're a not-for-profit association committed to the growth of Ontario’s animation and VFX industry.
                We support studios and creative careers through advocacy, professional development, and this Job Portal.
              </Typography>
            </>
          ) : (
            <Typography>
              {title} is not like other Freelancer sites. We don’t take money from
              Freelancers. We openly share contact information. We don’t meddle
              with the working relationship. By treating people with respect we’ve
              grown the best Freelancer community in the country.`
            </Typography>
          )}
        </Box>

        <Button variant="contained" color="primary" onClick={onClose}>
          Okay
        </Button>
      </Box>
    </Dialog>
  )
}

export default MissionDialog
