import React, { useState } from 'react'
import {
  List as MUIList,
  ListItem as MUIListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box } from 'components'
import { useTheme, slugify } from 'utils'

const NestedList = ({
  border,
  items,
  initialState = {},
  ItemComponent = ListItem,
}) => {
  const theme = useTheme()
  const green = theme.palette.primary.main
  const [open, setOpen] = useState(initialState)

  return (
    <Box
      border={border ? '1px solid #999' : ''}
      py={border ? 2 : 0}
      my={border ? 4 : 0}
      className={border ? 'hard-shadow' : ''}
    >
      <MUIList>
        {items.map((item) => {
          const { label: heading, children, id } = item

          if (!children) {
            return <ItemComponent key={`item-${id || heading}`} {...item} />
          }

          return (
            <div key={`item-${id || heading}`}>
              <MUIListItem
                button
                id={`list-heading-${id || slugify(heading)}`}
                onClick={() =>
                  setOpen((s) => ({ ...s, [heading]: !open[heading] }))
                }
              >
                <ListItemText
                  primary={heading}
                  primaryTypographyProps={{
                    style: { color: green, fontSize: 24 },
                  }}
                />
                {open[heading] ? (
                  <ExpandLessIcon color="primary" fontSize="large" />
                ) : (
                  <ExpandMoreIcon color="primary" fontSize="large" />
                )}
              </MUIListItem>

              <Collapse in={open[heading]} timeout="auto" unmountOnExit>
                <MUIList component="div" disablePadding>
                  {children.map((item) => (
                    <ItemComponent
                      key={`item-${item.id || item.label}`}
                      {...item}
                      nested
                    />
                  ))}
                </MUIList>
              </Collapse>
            </div>
          )
        })}
      </MUIList>
    </Box>
  )
}

export default NestedList

const ListItem = ({ onClick, nested, label, ...props }) => {
  return (
    <MUIListItem button onClick={onClick} {...props}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          style: { marginLeft: nested ? 16 : 0, fontSize: 24 },
        }}
      />
    </MUIListItem>
  )
}
