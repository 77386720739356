import { useState, useEffect } from 'react'
import { Box, Typography, SelectField, Button } from 'components'
import { Popover } from '@material-ui/core'
import { getUserGroups, addFreelancerToGroup } from 'utils'

export const GroupWidget = ({ freelancer, open = false, setOpen, targetEl }) => {
    const [_targetEl, _setTargetEl] = useState(targetEl)
    const [userGroups, setUserGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState()
    const [addDisabled, setAddDisabled] = useState(false)
    const [addButtonText, setAddButtonText] = useState('Add to Group')

    useEffect(() => {
        _setTargetEl(targetEl)
    }, [targetEl])
    useEffect(() => {
        getUserGroups().then((groups) => {
            const groupChoices = groups.map((group) => ({ id: group.id, label: group.name, value: group.id, freelancers: group.freelancers }))
            setSelectedGroup(groupChoices[0].value)
            setUserGroups(groupChoices)
            if (groups.find(g => g.freelancers.includes(freelancer.id))) {
                setAddButtonText('Already in Group')
                setAddDisabled(true)
                return
            }
        })
    }, [])

    const handleChange = e => {
        setSelectedGroup(e.target.value)
        if (userGroups.find(g => g.value === e.target.value).freelancers.includes(freelancer.id)) {
            setAddButtonText('Already in Group')
            setAddDisabled(true)
            return
        }
        setAddDisabled(false)
        setAddButtonText('Add to Group')
    }

    const handleAddButton = () => {
        addFreelancerToGroup({ id: selectedGroup }, freelancer.id)
            .then(() => {
                setUserGroups(userGroups => userGroups.map(g => g.id === selectedGroup ? { ...g, freelancers: [...g.freelancers, freelancer.id] } : g))
                setAddButtonText('Already in Group')
                setAddDisabled(true)
                setOpen(false)
            })
            .catch(err => console.log(err))
    }

    if (!freelancer) return null
    return (
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={_targetEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <Box padding={3}>
                <Typography>{`${freelancer.firstName} ${freelancer.lastName}`}</Typography>
                {userGroups.length &&
                    <SelectField label="Group*" choices={userGroups} value={selectedGroup} defaultValue={userGroups[0].value} onChange={handleChange} />
                }
                <Button onClick={handleAddButton} disabled={addDisabled} variant='contained' color='primary'>{addButtonText}</Button>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </Box>
        </Popover>
    )
}