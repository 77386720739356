import React from 'react'
import { Pill, Box } from 'components'

export function TagFilter({ items, onChange }) {
  return (
    <Box flex={1} flexVariant="row" flexWrap="wrap" width={350} mt={2}>
      {items.map((item) => (
        <Pill
          key={item}
          onClick={() => onChange(item)}
          style={{ marginRight: 8, marginBottom: 5 }}
        >
          {item}
        </Pill>
      ))}
    </Box>
  )
}
