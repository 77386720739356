import React, { useState } from 'react'
import {
  TextField,
  Button,
  MUIDialog,
  DialogContent,
  Typography,
} from 'components'
import { firebase } from 'utils'
import { CUSTOM_STATIC_LANDER } from 'constants'
const inviteEmail = firebase.functions().httpsCallable('inviteEmail')

const InviteDialog = ({ fromName, open, onClose, setSnackMessage }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [showSuccess, _setShowSuccess] = useState(false)
  const setShowMessage = () => {
    _setShowSuccess(true)
    setTimeout(() => _setShowSuccess(false), 4000)
  }

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography
          variant="h4"
          id="alert-dialog-title"
          style={{ marginBottom: 16 }}
        >
          {!CUSTOM_STATIC_LANDER ? 'Invite a freelancer' : 'Invite an artist'}
          {/* Invite a freelancer */}
        </Typography>
        <TextField
          label={!CUSTOM_STATIC_LANDER? "Freelancer Name" : "Artist Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label={!CUSTOM_STATIC_LANDER ? "Freelancer Email" : "Artist Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Typography
          style={{
            opacity: showSuccess ? 1 : 0,
            marginTop: 12,
            transition: 'opacity 400ms',
          }}
        >
          Your invitation has been sent, hopefully they’ll join soon!
        </Typography>
        <Button
          variant="contained"
          disabled={!name || !email || !email.match(/^.+@.+\..+$/)}
          onClick={() => {
            setName('')
            setEmail('')
            setShowMessage(true)
            inviteEmail({ email, name, fromName })
          }}
          color="primary"
          style={{ marginTop: 24, marginBottom: 12 }}
          autoFocus
        >
          Invite
        </Button>
        <Button
          onClick={() => {
            setName('')
            setEmail('')
            onClose()
          }}
          style={{ marginTop: 24, marginBottom: 8 }}
          autoFocus
        >
          Close
        </Button>
      </DialogContent>
    </MUIDialog>
  )
}

export default InviteDialog
