import React from 'react'
import { ArrowBackwardIcon, ArrowForwardIcon } from 'components'

export const NavArrow = ({ direction = 'right' }) =>
  direction === 'right' ? (
    <ArrowForwardIcon
      style={{ marginTop: 20, fontSize: 24, fill: '#1d1d1d' }}
    />
  ) : (
    <ArrowBackwardIcon
      style={{ marginTop: 20, fontSize: 24, fill: '#1d1d1d' }}
    />
  )
