import React from 'react'
import { Typography, Box } from 'components'
import { getAvailabilityForDate } from 'utils'
import { getColorFromStatus } from './utils'
import { isWeekend } from 'date-fns'

export const TileStatusMessage = ({ date, user }) => {
  const day = getAvailabilityForDate(date, user)
  if (
    (!isWeekend(date) || user.unsafeWeekends) &&
    day.message &&
    (day.isStartRange || !day.isRange)
  ) {
    return (
      <Box
        position="absolute"
        top="40%"
        left={10}
        alignItems="center"
        display="flex"
        width={day.isRange ? 100 : 'auto'}
        // width={day.isRange ? 250 : 'auto'}
        zIndex={9}
      >
        <Box
          width={6}
          height={6}
          borderRadius={3}
          bgcolor={getColorFromStatus(day.status)}
          mr={0.5}
        />
        <Box display={{ xs: 'none', sm: 'inline' }} clone>
          <Typography
            style={
              day.isRange
                ? { fontFamily: 'Mulish', fontSize: 12, color: '#1d1d1d' }
                : {
                    fontFamily: 'Mulish',
                    fontSize: 12,
                    color: '#1d1d1d',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    overflow: 'hidden',
                  }
            }
          >
            {day.message}
          </Typography>
        </Box>
      </Box>
    )
  }
  return null
}
