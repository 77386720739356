import React, { useState, useRef } from 'react'
import { toggleFavorite, onClickUserEmail, onClickUserWebsiteLink } from 'utils'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import {
  PhoneIcon,
  LinkIcon,
  Box,
  Typography,
  MailIcon,
  StarBorderIcon,
  PhoneDialog,
  Pill,
  Button,
  IconButton,
  Snackbar,
  FileCopyIcon,
  EditIcon,
} from 'components'
import { GroupWidget } from 'containers/Subscriber'
import { FreelancerFavoriteBadge } from './FreelancerFavoriteBadge'
import { PRONOUNS_ENABLED, PROFILE_PICTURES_ENABLED } from 'constants.js'
import { Avatar, makeStyles, Badge, IconButton as MUIIconButton } from '@material-ui/core'
import { useContent } from '../../utils'


const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: '0 1rem 0 0',
  }
}))

export default function FreelancerProfileContactSection({
  user,
  freelancer,
  onEdit,
  history,
}) {
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false)
  const [groupWidgetOpen, setGroupWidgetOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  
  const handleWidgetOpen = e => {
    setAnchorEl(e.currentTarget)
    setGroupWidgetOpen(true)
  }
  const classes = useStyles()
  return (
    <>
      <PhoneDialog
        open={phoneDialogOpen}
        user={freelancer}
        onClose={() => setPhoneDialogOpen(false)}
      />

      <Box
        bgcolor="white"
        border="1px solid #DDD"
        borderRadius={10}
        py={2}
        px={3.5}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            {PROFILE_PICTURES_ENABLED && (
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                badgeContent={onEdit ? <EditProfilePicture onEdit={onEdit} history={history} /> : null}
              >
                <Avatar className={classes.avatar} variant='square' src={freelancer.avatarUrl || ''} />
              </Badge>
            )}
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h3"
                style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              >
                {freelancer.firstName} {freelancer.lastName}
              </Typography>
              {PRONOUNS_ENABLED && freelancer.pronouns && (
                <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                  <Typography variant="body2">
                    {freelancer.pronouns}
                  </Typography>
                </Box>
              )}

              <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                <LocationOnOutlinedIcon style={{ fill: '#696969', fontSize: 20 }} />
                <Typography variant="body2">{freelancer.city}</Typography>
              </Box>
            </Box>

          </Box>

          <Box display="flex">
            {!onEdit &&
              <>
                <Button onClick={(e) => handleWidgetOpen(e)} variant='contained' color='primary' style={{ margin: 10 }}>Add to Group</Button>
                <GroupWidget freelancer={freelancer} open={groupWidgetOpen} setOpen={setGroupWidgetOpen} targetEl={anchorEl} />
              </>
            }
            <FreelancerFavoriteBadge freelancer={freelancer} />
            {onEdit && (
              <Pill size="small" onClick={onEdit} style={{ marginLeft: 8 }}>
                Manage Profile
              </Pill>
            )}
          </Box>
        </Box>

        {/* {PRONOUNS_ENABLED && freelancer.pronouns && (
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Typography variant="body2">
              {freelancer.pronouns}
            </Typography>
          </Box>
        )}

        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          <LocationOnOutlinedIcon style={{ fill: '#696969', fontSize: 20 }} />
          <Typography variant="body2">{freelancer.city}</Typography>
        </Box> */}

        {freelancer.about ? (
          <Typography>{freelancer.about}</Typography>
        ) : onEdit ? (
          <Pill size="small" onClick={onEdit}>
            Add "About Me"
          </Pill>
        ) : null}

        <ProfileButtons
          user={user}
          freelancer={freelancer}
          onPhone={() => setPhoneDialogOpen(true)}
        />
      </Box>
    </>
  )
}

const ProfileButtons = ({ user, freelancer, onPhone }) => {
  const websites = [freelancer.website, ...(freelancer.websites || [])].filter(
    (s) => !!s,
  )
  const websitePasswords = [freelancer.websitePassword, ...(freelancer.websitePasswords || [])]

  const [snackbarOpen, setSnackbarOpen] = useState(null)
  const [snackbarText, setSnackbarText] = useState('')

  const onCopyEmailClick = () => {
    navigator.clipboard.writeText(freelancer.email).then(() => {
      setSnackbarOpen(true)
      setSnackbarText('Email copied to clipboard')
    }).catch(err => {
      setSnackbarOpen(true)
      setSnackbarText('Failed to copy email to clipboard')
    })
    setTimeout(() => {
      setSnackbarOpen(false)
    }, 1000);
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      mt={3}
      flexWrap="wrap"
    >
      {freelancer.phoneNumber && (
        <ProfileButton onClick={onPhone} Icon={PhoneIcon}>
          {freelancer.phoneNumber}
        </ProfileButton>
      )}

      {websites.map((site, i) => (
        <ProfileButton
          key={site}
          onClick={() => onClickUserWebsiteLink(site)}
          Icon={LinkIcon}
        >
          {site} {websitePasswords[i] ? `/ password: ${websitePasswords[i]}` : ''}
        </ProfileButton>
      ))}

      <ProfileButton
        onClick={() => onClickUserEmail(freelancer)}
        Icon={MailIcon}
      >
        {freelancer.email}
      </ProfileButton>
      <IconButton onClick={onCopyEmailClick}>
        <FileCopyIcon />
      </IconButton>
      <Snackbar open={snackbarOpen} message={snackbarText} autoHideDuration={2000} />

      {user && (
        <ProfileButton
          Icon={StarBorderIcon}
          onClick={() => toggleFavorite(user, freelancer)}
        >
          {user.favorites.includes(freelancer.id) ? 'Unfave' : 'Fave'}{' '}
          {freelancer.firstName}
        </ProfileButton>
      )}
    </Box>
  )
}

const ProfileButton = ({ Icon, onClick, children }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    style={{ paddingLeft: 4, marginRight: 5, marginTop: 6 }}
  >
    <Box
      flexVariant="center"
      style={{
        borderRadius: 15,
        backgroundColor: 'black',
        height: 30,
        width: 30,
        marginRight: 8,
      }}
    >
      <Icon style={{ fill: 'white', fontSize: 18 }} />
    </Box>

    <Typography style={{ color: 'black', fontSize: 14 }}>{children}</Typography>
  </Button>
)

const EditProfilePicture = ({ user, onEdit, history }) => {
  return (
    <MUIIconButton onClick={() => history.push('/profilepicture')}><EditIcon /></MUIIconButton>
  )
}