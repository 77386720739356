import { createMuiTheme } from '@material-ui/core/styles'

export const palette = {
  primary: {
    main: process.env.REACT_APP_PRIMARY_COLOR,
    light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT,
    dark: process.env.REACT_APP_PRIMARY_COLOR_DARK,
    contrastText: process.env.REACT_APP_PRIMARY_CONTRAST_TEXT || '#fff',
  },
  secondary: {
    main: process.env.REACT_APP_SECONDARY_COLOR || '#31B87E',
    light: process.env.REACT_APP_SECONDARY_COLOR_LIGHT ||'#31B87E',
    dark: process.env.REACT_APP_SECONDARY_COLOR_DARK || '#31B87E',
    contrastText: process.env.REACT_APP_SECONDARY_CONTRAST_TEXT ||'#fff',
  },
  default: {
    main: '#1D1D1D',
    dark: '#000',
    light: '#3E3E3E',
    contrastText: '#fff',
  },
  green: { main: '#49b592', light: "$49b592", dark: "$49b592", contrastText: '#fff' },
  pastel_green: { main: '#D4EFE4' },
  yellow: { main: '#e8d53b' },
  pastel_yellow: { main: '#FBF0D5' },
  grey: { main: '#c7c7c7' },
  pastel_grey: { main: '#c7c7c7' },
  darkGrey: { main: '#555' },
  red: { main: '#f06450' },
  pastel_red: { main: '#F9C8CE' },
  white: { main: '#FFFFFF' }
}

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Mulish, Arial',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    textAlign: 'left',
    letterSpacing: '0px',
    h1: {
      fontStyle: 'normal',
      color: '#1d1d1d',
      fontWeight: '700',
      fontSize: '38px',
      lineHeight: '55px',
      '@media (min-width:600px)': {
        fontSize: '50px',
        lineHeight: '62px',
      },
      '@media (min-width:1200px)': {
        fontSize: '68px',
        lineHeight: '88px',
      },
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#1d1d1d',
      fontSize: '24px',
      lineHeight: '35px',
      '@media (min-width:600px)': {
        fontSize: '36px',
        lineHeight: '50px',
      },
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#1d1d1d',
      fontSize: '20px',
      lineHeight: '24px',
      '@media (min-width:600px)': {
        fontSize: '28px',
        lineHeight: '38px',
      },
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '700',
      color: '#1d1d1d',
      fontSize: '16px',
      lineHeight: '18px',
      '@media (min-width:600px)': {
        fontSize: '22px',
        lineHeight: '24px',
      },
    },
    h6: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '18px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '26px',
      color: '#696969',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '22px',
      color: '#696969',
    },
    subtitle1: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '15px',
      textAlign: 'center',
    },
  },
  palette,
  overrides: {
    MuiContainer: {
      maxWidthXs: {
        maxWidth: '480px !important',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      nm: 800,
      md: 1000,
      lg: 1330,
      xl: 1920,
    },
  },
})
