import React, { useEffect, useState } from 'react'
import {
  Button,
  MUIDialog,
  DialogContent,
  Typography,
  SelectField,
  Box,
  MenuItem,
} from 'components'
import {
  addFreelancerToGroup,
  getUserGroups,
  removeFreelancerFromGroup,
} from 'utils'
import { ProfileIcons } from 'components/freelancer/ProfileCard'

const GroupDialog = ({ user, open, onClose }) => {
  const [groups, setGroups] = useState([])
  const [groupId, setGroupId] = useState('')
  const group = groups?.find((l) => l.id === groupId)

  useEffect(() => {
    getUserGroups().then((groups) => setGroups(groups))
  }, [open])

  useEffect(() => {
    if (groups[0] && !groupId) setGroupId(groups[0].id)
  }, [groups, groupId])

  const onToggle = async () => {
    if (group.freelancers?.includes(user?.id)) {
      await removeFreelancerFromGroup(group, user?.id)
    } else {
      await addFreelancerToGroup(group, user?.id)
    }
    onClose()
  }

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography
          variant="h4"
          id="alert-dialog-title"
          style={{ marginBottom: 16 }}
        >
          {user?.firstName} {user?.lastName}
        </Typography>

        <SelectField
          required
          name="group"
          label="Group"
          value={groupId}
          onChange={(e) => setGroupId(e.target.value)}
          choices={groups}
          renderChoice={(group) => (
            <MenuItem key={group.id} value={group.id}>
              <Box display="flex" alignItems="center">
                <ProfileIcons groups={[group]} />
                <Box mr={1} />
                {group.name}
              </Box>
            </MenuItem>
          )}
          style={{ minWidth: 300 }}
        />

        {user?.id && (
          <Button
            disabled={!groupId}
            variant="contained"
            onClick={onToggle}
            color="primary"
            style={{ marginTop: 24, marginBottom: 12 }}
            autoFocus
          >
            {group?.freelancers?.includes(user?.id)
              ? 'Remove from group'
              : 'Add to group'}
          </Button>
        )}

        <Button
          onClick={onClose}
          style={{ marginTop: 24, marginBottom: 8 }}
          autoFocus
        >
          Close
        </Button>
      </DialogContent>
    </MUIDialog>
  )
}

export default GroupDialog
