// import { withStyles, Switch } from '@material-ui/core';

// const StyledSwitch = withStyles((color) => {
//     console.log(color)
// return ({
//   switchBase: {
//     color: color,
//     '&$checked': {
//       color: color,
//     },
//     '&$checked + $track': {
//       backgroundColor: color,
//     },
//   },
//   checked: {},
//   track: {},
// })})(Switch);

// export default StyledSwitch

import { makeStyles, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  switchBase: (props) => ({
    color: props.color + ` !important`,
    '&$checked': {
      color: props.color + ` !important`,
    },
    '&$checked + $track': {
      backgroundColor: props.color + ` !important`,
    },
  }),
  checked: {},
  track: {
    backgroundColor: theme.palette.primary.main + ` !important`,
  },
}));

// switchBase: {
//   color: purple[300],
//   '&$checked': {
//     color: purple[500],
//   },
//   '&$checked + $track': {
//     backgroundColor: purple[500],
//   },

const StyledSwitch = ({ color, ...props }) => {
  const classes = useStyles({ color });

  return (
    <Switch
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
      {...props}
    />
  );
};

export default StyledSwitch;