export const firestoreProvider = (firebase) => ({
  getList: makeGetList(firebase),
  getOne: makeGetOne(firebase),
  getMany: makeGetMany(firebase),
  getManyReference: makeGetManyReference(firebase),
  create: makeCreate(firebase),
  update: makeUpdate(firebase),
  updateMany: makeUpdateMany(firebase),
  delete: makeDelete(firebase),
  deleteMany: makeDeleteMany(firebase),
})

const makeGetList = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  const { page, perPage } = params.pagination
  let { field, order } = params.sort
  const filter = Object.entries(params.filter)[0]
  let baseCollection = firebase.firestore().collection(resource)
  if (filter) {
    if (Array.isArray(filter[1])) {
      baseCollection = baseCollection.where(filter[0], 'array-contains-any', filter[1])
    } else {
      baseCollection = baseCollection.where(filter[0], '==', filter[1])
    }
  }

  const collection = baseCollection.limit(page * perPage)

  if (field === 'createdAt.seconds') field = 'createdAt'

  var query =
    field === 'id' ? collection : collection.orderBy(field, order.toLowerCase())

  return query.get().then((snap) => {
    return baseCollection.get().then((innerSnap) => {
      var total = innerSnap.size
      var firstDocToDisplayCount =
        page === 1 ? 1 : Math.min((page - 1) * perPage, snap.size)
      var firstDocToDisplay = snap.docs.slice(firstDocToDisplayCount - 1)
      return {
        data: firstDocToDisplay.map((doc) => getDataWithId(doc)),
        total,
      }
    })
  })
}

const makeGetOne = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return firebase
    .firestore()
    .collection(resource)
    .doc(params.id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { data: getDataWithId(doc) }
      } else {
        throw new Error({ message: 'No such doc', status: 404 })
      }
    })
    .catch((error) => {
      throw new Error({ message: error, status: 404 })
    })
}

const makeGetMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return Promise.all(
    params.ids.map((id) =>
      firebase.firestore().collection(resource).doc(id).get(),
    ),
  ).then((arrayOfResults) => {
    return {
      data: arrayOfResults.map((documentSnapshot) =>
        getDataWithId(documentSnapshot),
      ),
    }
  })
}

const makeGetManyReference = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  const { target, id } = params
  const { field, order } = params.sort
  return firebase
    .firestore()
    .collection(resource)
    .where(target, '==', id)
    .orderBy(field, order.toLowerCase())
    .get()
    .then((QuerySnapshot) => {
      return {
        data: QuerySnapshot.docs.map((DocumentSnapshot) =>
          getDataWithId(DocumentSnapshot),
        ),
        total: QuerySnapshot.docs.length,
      }
    })
}

const makeCreate = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')

  return handleFiles(firebase, resource, params.data).then((data) =>
    firebase
      .firestore()
      .collection(resource)
      .add(params.data)
      .then((DocumentReference) =>
        DocumentReference.get().then((DocumentSnapshot) => {
          return { data: getDataWithId(DocumentSnapshot) }
        }),
      )
  )
}

const makeUpdate = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')

  return handleFiles(firebase, resource, params.data).then((data) =>
    firebase
      .firestore()
      .collection(resource)
      .doc(params.id)
      .set(data)
      .then(() => ({ data: params.data }))
  )
}

const makeUpdateMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return params.ids.map((id) =>
    firebase
      .firestore()
      .collection(resource)
      .doc(id)
      .set(params.data)
      .then(() => id),
  )
}

const makeDelete = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return firebase
    .firestore()
    .collection(resource)
    .doc(params.id)
    .delete()
    .then(() => {
      return { data: params.previousData }
    })
}

const makeDeleteMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return {
    data: params.ids.map((id) =>
      firebase
        .firestore()
        .collection(resource)
        .doc(id)
        .delete()
        .then(() => id),
    ),
  }
}

function getDataWithId(DocumentSnapshot, { convertTime } = {}) {
  var dataWithId = {}
  const data = DocumentSnapshot.data()
  if (DocumentSnapshot) {
    dataWithId = {
      id: DocumentSnapshot.id,
      ...data,
    }
  }
  return dataWithId
}

async function uploadFileToBucket(rawFile, storageRef) {
  return storageRef
    .put(rawFile)
    .then((snapshot) => {
      return storageRef.getDownloadURL()
    })
    .catch((error) => {
      throw new Error({ message: error.message_, status: 401 })
    })
}

async function createOrUpdateFile(resource, rawFile, firebase) {
  var storageRef = firebase.storage().ref().child(resource + '/' + rawFile.name + Date.now())
  return storageRef
    .getMetadata()
    .then((metadata) => {
      if (metadata && metadata.size === rawFile.size) {
        return storageRef.getDownloadURL()
      } else {
        return uploadFileToBucket(rawFile, storageRef)
      }
    })
    .catch(() => {
      return uploadFileToBucket(rawFile, storageRef)
    })
}

async function handleFiles(firebase, resource, data) {
  const files = Object.keys(data).filter(
    (key) => data[key]?.rawFile || data[key]?.filter?.(i => i.rawFile).length > 0,
  ).map(key => ({
    files: Array.isArray(data[key])
      ? data[key].map((f, index) => ({ ...f, key, index })).filter(i => i.rawFile)
      : [{ ...data[key], index: 0, key }]
  }))

  const results = await Promise.all(
    files.flatMap(({ files }) =>
      files.map((file) => createOrUpdateFile(resource, file.rawFile, firebase).then((src) => ({ ...file, src })))
    ),
  )
  results.forEach(({ key, title, src, index }) => { data[key][index] = { title, src } })

  return data
}