import React from 'react'
import {
  Frame,
  List,
  ListItem,
  ListItemText,
  KeyboardArrowRightIcon,
  Heading,
} from 'components'

import { useHistory, makeStyles } from 'utils'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
    fontSize: 21,
  },
}))

const Item = ({ onClick, label, route, secondary, ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <ListItem button onClick={() => history.push(route)} {...props}>
      <ListItemText classes={classes} primary={label} secondary={secondary} />
      <KeyboardArrowRightIcon color="primary" />
    </ListItem>
  )
}

const FreelancerSettings = ({ user }) => {
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Settings & Account</Heading>

      <List>
        <Item
          label="Update My Name"
          secondary={user && `${user.firstName} ${user.lastName}`}
          route="/settings/name"
        />
        <Item
          label="My City"
          secondary={user && user.city}
          route="/settings/city"
        />
        <Item label="Email Preferences" route="/settings/email" />
        <Item label="Change My Password" route="/settings/password" />
        <Item
          label={`${user.hidden ? 'Unhide' : 'Hide'} My Profile`}
          route="/settings/visibility"
        />
        <Item label="Log out" route="/logout" />
        <Item label="Delete" route="/settings/delete" />
      </List>
    </Frame>
  )
}

export default FreelancerSettings
