import React, { useEffect, useState } from 'react'
import { Frame } from 'components'
import JobBoardForm from './JobBoardForm'
import JobBoardList from './JobBoardList'
import { getUserJobBoardPosts, useHasActiveSubscription } from 'utils'
import { Redirect } from 'react-router'

export const SubscriberJobBoardCreate = (props) => {
  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )

  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }
  return (
    <Frame maxWidth="xs" user={props.user}>
      <JobBoardForm {...props} />
    </Frame>
  )
}

export const SubscriberJobBoardEdit = (props) => {
  const [post, setPost] = useState()
  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )

  useEffect(() => {
    if (!props.match.params.id || post) return
    getUserJobBoardPosts().then((posts) => {
      const foundPost = posts.find((post) => post.id === props.match.params.id)
      if (foundPost) {
        setPost({
          id: foundPost.id,
          ...foundPost,
          startDate: foundPost.startDate?.toDate(),
          endDate: foundPost.endDate?.toDate(),
        })
      } else {
        props.history.push('/job_board')
      }
    })
  }, [post, props.history, props.match.params.id])

  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="xs" user={props.user}>
      {post && <JobBoardForm defaultValues={post} {...props} />}
    </Frame>
  )
}

export const SubscriberJobBoardList = (props) => {
  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )
  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="xs" user={props.user}>
      <JobBoardList {...props} />
    </Frame>
  )
}
