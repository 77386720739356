import React from 'react'
import { Box, Typography, Divider, StyledSwitch } from 'components'
import { slugify } from 'utils'
// import { Switch } from '@material-ui/core'
import { theme } from 'theme'

export const FilterCheckbox = ({ checked, onChange, name, ...props }) => (
  <Box flex={1}>
    <Box py={1.5} flex={1} flexVariant="space-between align-center">
      <Box flexVariant="row align-center">
        <Box
          mr={1}
          width={8}
          height={8}
          borderRadius={4}
          bgcolor={props.color}
        />
        <Typography
          style={{ fontSize: 16, color: checked ? '#1d1d1d' : '#696969' }}
        >
          {props.label}
        </Typography>
      </Box>
      <StyledSwitch
        value={slugify(props.label)}
        color={props.styledColor || 'primary'}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    </Box>
    {!props.isLast && <Divider my={0} />}
  </Box>
)
