import React, { useState } from 'react'
import {
  Frame,
  Button,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from 'components'
import {
  useTheme,
  usePackages,
  redirectToCheckout,
  useSubscription,
  useContent,
} from 'utils'
import { PACKAGE_POST_COUNTS, LICENSE_COUNTS } from 'constants.js'

const DETAILS = [
  'Unlimited search',
  'Instant access to Freelancer portfolios, links + contact info',
  'Search by city, role, skills and category experience',
  'Includes PACKAGE_POST_COUNT active job posts',
  'LICENSE_COUNT / user',
]
const SubscriberPayment = ({ user }) => {
  const theme = useTheme()
  const packages = usePackages()
  const [selectedPackage, setSelectedPackage] = useState()
  const { subscription } = useSubscription(user?.id)
  const { title } = useContent()

  const onClickPackage = (priceId) => () => {
    if (selectedPackage) return
    redirectToCheckout(user.id, priceId)
    setSelectedPackage(priceId)
  }

  const getMessage = (pack, detail) => {
    if (detail.match(/PACKAGE_POST_COUNT/) && PACKAGE_POST_COUNTS[pack.id]) {
      return detail.replace('PACKAGE_POST_COUNT', PACKAGE_POST_COUNTS[pack.id])
    }
    if (detail.match(/LICENSE_COUNT/) && LICENSE_COUNTS[pack.id]) {
      const count = LICENSE_COUNTS[pack.id]
      const extra = `${count > 1 ? `Up to ${count}` : 'Single'} ${count > 1 ? 'licenses' : 'license'
        }`
      return detail.replace('LICENSE_COUNT', extra)
    }
    return detail
  }

  return (
    <Frame maxWidth="lg" user={user}>
      <Box align="center" maxWidth={650} mx="auto">
        {subscription ? (
          <>
            <Typography variant="h3">Select a Package</Typography>
            <Typography style={{ margin: '20px 0' }}>
              Select a payment package to continue using {title}.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h3">Start your free trial!</Typography>
            <Typography style={{ margin: '20px 0 5px' }}>
              The {title} 30-day guarantee: You get full access to all our
              Freelancers and search tools. If it doesn't make your job way
              easier, just hit cancel and never pay a penny.
            </Typography>
            <Typography style={{ margin: '5px 0', fontWeight: 'bold' }}>
              Pricing is based on company size.
            </Typography>
          </>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: 'column', nm: 'row' }}
        maxWidth={900}
        mx="auto"
      >
        {packages.map((pack, i) => (
          <Box
            className="package"
            align="center"
            key={pack.name}
            border={`3px solid ${theme.palette.green.main}`}
            borderRadius={8}
            maxWidth={300}
            padding={3}
            mx={1}
            my={4}
          >
            <Typography variant="h4">{pack.name}</Typography>
            <Typography>{pack.description}</Typography>

            <Divider />

            <ul style={{ textAlign: 'left' }}>
              {DETAILS.map((detail, _i) => (
                <Typography
                  key={detail}
                  component="li"
                  style={{
                    fontSize: 12,
                    lineHeight: 1.3,
                    fontWeight: _i > 2 ? 'bold' : 'normal',
                  }}
                >
                  {getMessage(pack, detail)}
                </Typography>
              ))}
            </ul>

            <Divider />

            <Box my={4}>
              <Typography
                variant="h3"
                style={{ fontSize: 60, lineHeight: 1.2, fontWeight: 'bold' }}
              >
                ${pack.cost}
              </Typography>
              <Typography>per month, first month free</Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onClickPackage(pack.priceId)}
            >
              {selectedPackage === pack.priceId ? (
                <CircularProgress color="white" size={20} />
              ) : (
                'Get started'
              )}
            </Button>
          </Box>
        ))}
      </Box>
      <Box
        my={3}
        maxWidth={900}
        mx="auto"
        display="flex"
        flexDirection={{ xs: 'column', nm: 'row' }}
        alignItems="center"
      >
        <Typography>
          Is {title} an expense you need approved? Download this handy ‘Why
          Subscribe’ one-pager. It explains to the bean-counters how {title} helps
          your business run better, and save beans.
        </Typography>
        <Box my={{ xs: 2, nm: 0 }} />
        <Button
          onClick={() => {
            window.open(
              'https://firebasestorage.googleapis.com/v0/b/crral-4faae.appspot.com/o/Subscriber%20Sell%20Sheet%202022.v4.pdf?alt=media&token=13234951-6bf3-495c-991d-925b109c5327',
              '_blank',
            )
          }}
          style={{ width: 220, backgroundColor: '#ECB230' }}
          variant="contained"
        >
          <span style={{ fontSize: 11, lineHeight: 1.2, color: 'black' }}>
            Why Subscribe One-Pager
          </span>
        </Button>
      </Box>
    </Frame>
  )
}

export default SubscriberPayment
