import React from 'react'
import { Box, Typography, Slider } from 'components'

export function ExperienceFilter({ filterState, setFilterState }) {
  return (
    <Box mt={2} minWidth={180}>
      <Box my={2} flexVariant="row center">
        <TextButton value={filterState.experience[0]} />
        <Typography style={{ marginLeft: 16, marginRight: 16 }}>To</Typography>
        <TextButton value={filterState.experience[1]} />
      </Box>
      <Box mx={1}>
        <Slider
          value={filterState.experience}
          min={1}
          max={20}
          onChange={(e, value) => {
            setFilterState((f) => ({ ...f, experience: value }))
          }}
        />
      </Box>
    </Box>
  )
}

const TextButton = ({ value }) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 40,
        background: '#FFFFFF',
        border: '1px solid #DDDDDD',
        boxSizing: 'border-box',
        borderRadius: '4px',
      }}
    >
      <Typography
        style={{ color: '#111', fontWeight: 'bold', lineHeight: '0px' }}
      >
        {value}
      </Typography>
    </Box>
  )
}
