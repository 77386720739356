import React, { useEffect, useState } from 'react'
import { Box, CardList, Heading } from 'components'
import { getActiveJobBoardPosts, getDecoratedJobBoardPosts } from 'utils'
import JobCard from 'components/freelancer/JobCard'
import { Typography } from '@material-ui/core'
import {
  JobBoardFilterBar,
  filterInitialState,
} from 'components/FilterBar/JobBoardFilterBar'

import {
  useQueryParams,
  withDefault,
  DateParam,
  ArrayParam,
  StringParam,
} from 'use-query-params'
import { isAfter } from 'date-fns'

const JobBoardListings = ({ history, user, markets }) => {
  const [filterState, setFilterState] = useQueryParams({
    id: StringParam,
    industry: withDefault(StringParam, Object.values(user.roles)[0].industryId),
    date1: withDefault(DateParam, filterInitialState.date1),
    date2: withDefault(DateParam, filterInitialState.date2),
    cities: withDefault(ArrayParam, filterInitialState.cities),
    roles: withDefault(ArrayParam, filterInitialState.roles),
    working_style: withDefault(StringParam, filterInitialState.working_style),
  })

  const [posts, setPosts] = useState([])

  useEffect(() => {
    if (!markets) return
    getActiveJobBoardPosts().then((_posts) => {
      getDecoratedJobBoardPosts(markets, _posts).then(setPosts)
    })
  }, [markets])

  const { date1, industry, roles, cities, working_style } = filterState

  const renderedPosts = posts

    .filter((p) => {
      if (filterState.id) return p.id === filterState.id
      const dateState = date1
        ? isAfter(p.endDate?.toDate(), date1)
        : isAfter(p.endDate?.toDate(), new Date())
      const industryState = industry ? p.industryId === industry : true
      const roleState = roles.length > 0 ? roles.includes(p.roleId) : true
      const cityState = cities.length > 0 ? cities.includes(p.cityId) : true
      let workLocationState = true
      if (working_style === 'remote') {
        workLocationState = p.workingStyle === 'remote'
      } else if (working_style === 'in-office') {
        workLocationState = p.workingStyle !== 'remote'
      }
      return (
        dateState &&
        industryState &&
        roleState &&
        workLocationState &&
        cityState
      )
    })
    .sort((a, b) => a.role?.localeCompare(b.role))

  return (
    <Box>
      <Heading>New Job Posts</Heading>
      <Box mb={2}>
        <JobBoardFilterBar
          markets={markets}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      </Box>
      {renderedPosts.length === 0 && (
        <Box>
          <Typography align="center">
            Oops, no matches. Try adjusting/removing your active filters.
          </Typography>
        </Box>
      )}
      <CardList
        cards={renderedPosts.map((post) => (
          <JobCard jobPost={post} />
        ))}
      />
    </Box>
  )
}

export default JobBoardListings
