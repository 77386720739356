import React from 'react'
import { Box, Typography } from 'components'
import { useTheme } from 'utils'
import { COMMITMENT_STATUS } from 'constants'

export default function ColorGuide() {
  const theme = useTheme()

  return (
    <Box mt={2} flexVariant="space-between">
      <Box flexVariant="center">
        <Box
          style={{
            width: 6,
            height: 6,
            borderRadius: '50%',
            marginRight: 8,
            background: theme.palette.green.main,
          }}
        />
        <Typography style={{ color: '#a4a4a4', fontSize: 12 }}>
          Available
        </Typography>
      </Box>

      {!COMMITMENT_STATUS &&

        (
          <Box flexVariant="center">
            <Box
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                marginRight: 8,
                background: theme.palette.yellow.main,
              }}
            />
            <Typography style={{ color: '#a4a4a4', fontSize: 12 }}>
              It depends
            </Typography>
          </Box>
        )
      }

      <Box flexVariant="center">
        <Box
          style={{
            width: 6,
            height: 6,
            borderRadius: '50%',
            marginRight: 8,
            background: theme.palette.red.main,
          }}
        />
        <Typography style={{ color: '#a4a4a4', fontSize: 12 }}>
          Unavailable
        </Typography>
      </Box>
    </Box>
  )
}
