import React, { useEffect, useState } from 'react'
import { Button, TextField, Box, Checklist } from 'components'
import { firebase } from 'utils'
import { getFreelancerGroupsWithUsers } from './Form'

const Message = ({ user, group }) => {
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [users, setUsers] = useState([])
  const [message, setMessage] = useState('')
  const [freelancersToMessage, setFreelancersToMessage] = useState(
    group?.freelancers,
  )
  const onSendMessage = () => {
    const confirmed = window.confirm(
      `Are you sure you want to send this message to ${freelancersToMessage.length} people?`,
    )
    if (!confirmed) return
    Promise.all(
      freelancersToMessage.map((freelancer) => {
        const user = users.find((u) => u.id === freelancer)
        return sendCustomEmail({
          email,
          name: user.firstName,
          message,
          subject,
          fromName: firebase.auth().currentUser.email,
        })
      }),
    )
  }

  useEffect(() => {
    if (!group.id) return
    getFreelancerGroupsWithUsers().then((groups) => {
      setUsers(groups.find((l) => l.id === group.id)?.freelancers || [])
    })
  }, [group.id])

  return (
    <Box mt={4} width="100%">
      <Checklist
        multicheck
        state={freelancersToMessage.map((s) => ({ id: s }))}
        buttonLabel="Next"
        items={users.map((u) => ({
          label: `${u.firstName} ${u.lastName}`,
          id: u.id,
        }))}
        onChange={(freelancers) => {
          setFreelancersToMessage(freelancers.map((f) => f.id))
        }}
      />
      <TextField
        label="To"
        placeholder="freelancer@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Subject"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        label="Message"
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        disabled={!email || subject.length < 1 || message.length < 1}
        variant="contained"
        color="primary"
        onClick={onSendMessage}
      >
        Send Message
      </Button>
    </Box>
  )
}

export default Message

// firebase.functions().useEmulator('localhost', 5001)
const sendCustomEmail = firebase.functions().httpsCallable('sendCustomEmail')
