import React from 'react'
import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core'

const Switch = ({ label, ...props }) => {
  if (label) {
    return <FormControlLabel label={label} control={<MUISwitch {...props} />} />
  }
  return <MUISwitch {...props} />
}

export default Switch
