import React, { useState } from 'react'
import { Dialog, TextField, Button, Frame, Heading } from 'components'
import { useHistory, deleteUser } from 'utils'

const FreelancerDelete = ({ user, setUserNull, setSnackMessage }) => {
  const history = useHistory()
  const [alertOpen, setAlertOpen] = useState()
  const [password, setPassword] = useState('')

  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Delete your account?</Heading>

      <Dialog
        open={!!alertOpen}
        onClose={() => setAlertOpen(null)}
        title="No wait!"
        body="You can hide your account so nobody will see it. You never know when you might want to re-activate it."
        secondaryText="Hide My Account"
        onSecondaryClick={() => {
          history.push('/settings/visibility')
        }}
        confirmText="Delete Account"
        onClick={() => {
          setAlertOpen(null)
          deleteUser(password).then(() => {
            history.push('/')
            setUserNull()
            setSnackMessage('Your account has been successfully deleted')
          })
        }}
      />

      <TextField
        required
        name="password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label="Current Password"
      />

      <Button
        disabled={password.length === 0}
        type="submit"
        onClick={() => {
          if (password.length > 0) {
            setAlertOpen(true)
          }
        }}
      >
        Delete
      </Button>
    </Frame>
  )
}

export default FreelancerDelete
