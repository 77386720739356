import React from 'react'
import { TextField, Box, Slider } from 'components'
import { Typography } from '@material-ui/core'

const ExperienceSlider = ({ value, setValue }) => (
  <Box display="flex" alignItems="center">
    <Typography>Years:</Typography>
    <TextField
      value={value}
      style={{
        width: 80,
        marginLeft: 15,
        marginRight: 15,
        textAlign: 'center',
      }}
    />

    <Box flexVariant="flex column center">
      <Slider
        id="experience-slider"
        value={value}
        onChange={(_, value) => setValue(value)}
        step={1}
        min={0}
        max={20}
      />
    </Box>
  </Box>
)

export default ExperienceSlider
