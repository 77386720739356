import React from 'react'
import { Box } from 'components'
import { FilterCheckbox } from './FilterCheckbox'
import { ONTARIO_WORKING_STATUS } from 'constants'

export function ExtrasFilter({ filterState: state, setFilterState: setState }) {
  const { favorites, has_equipment, ontario_resident, relocate } = state

  return (
    <Box display="flex" flex={1} flexDirection="column" minWidth={160}>
      <FilterCheckbox
        label="Favorites only"
        checked={!!favorites}
        onChange={() => setState({ ...state, favorites: !favorites })}
      />
      <FilterCheckbox
        label="Has own equipment"
        checked={!!has_equipment}
        onChange={() => setState({ ...state, has_equipment: !has_equipment })}
      />
      {/* {ONTARIO_WORKING_STATUS && <>
        <FilterCheckbox
          label="Ontario Resident"
          checked={!!ontario_resident}
          onChange={() => setState({ ...state, ontario_resident: !ontario_resident })}
        />
        <FilterCheckbox
          label="May relocate"
          checked={!!relocate}
          onChange={() => setState({ ...state, relocate: !relocate })}
        />
      </>} */}
    </Box>
  )
}
