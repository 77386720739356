import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  SimpleFormIterator,
  ArrayInput,
  TextInput,
  ShowButton,
  Pagination,
} from 'react-admin'

export const TagList = (props) => (
  <List
    {...props}
    title="Tags List"
    perPage={100}
    sort={{ field: 'category', order: 'ASC' }}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid>
      <TextField source="category" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const TagEdit = (props) => (
  <Edit {...props} title="Tags Edit" undoable={false}>
    <SimpleForm>
      <TextInput source="category" />
      <ArrayInput source="tags">
        <SimpleFormIterator>
          <TextInput source="tag" label="tag" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)

export const TagCreate = (props) => (
  <Create {...props} title="Tags Create">
    <SimpleForm>
      <TextInput source="category" />
      <ArrayInput source="tags">
        <SimpleFormIterator>
          <TextInput source="tag" label="tag" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
