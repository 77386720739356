import { getConstants } from './utils/firebase'
export const COLORS = ['green', 'green', 'red', 'yellow']
export const LABELS = ['available', 'available', 'unavailable', 'it depends']
export const PACKAGE_POST_COUNTS = {
  prod_JqW1i8Qn8aclm8: 1,
  prod_JqW9QrTHa0KHMP: 3,
  prod_JqW918e8hTn0Ui: 10,
}
export const LICENSE_COUNTS = {
  prod_JqW1i8Qn8aclm8: 1,
  prod_JqW9QrTHa0KHMP: 3,
  prod_JqW918e8hTn0Ui: 7,
}

export const BIG_SWITCH_HEIGHT = 70
export const BIG_SWITCH_WIDTH = 190
export const TRIAL_DURATION = 30
export const CUSTOM_STATIC_LANDER = process.env.REACT_APP_CUSTOM_STATIC_LANDER === 'true'
export const ONTARIO_WORKING_STATUS = process.env.REACT_APP_ONTARIO_WORKING_STATUS === 'true'
export const DISABLE_INDUSTRY_STEP = process.env.REACT_APP_ONTARIO_WORKING_STATUS === 'true'
export const SUBSCRIPTIONS_ENABLED = process.env.REACT_APP_SUBSCRIPTION_ENABLED === 'true'
export const ONTARIO_RESIDENCE_STATUS = process.env.REACT_APP_ONTARIO_RESIDENCE_STATUS === 'true'
export const COMMITMENT_STATUS = process.env.REACT_APP_COMMITMENT_STATUS === 'true'
export const CREDITS_ENABLED = process.env.REACT_APP_CREDITS_ENABLED === 'true'
export const CV_ONBOARD_ENABLED = process.env.REACT_APP_CVONBOARD_ENABLED === 'true'
export const DISABLE_ONBOARD_MESSAGE = process.env.REACT_APP_DISABLE_ONBOARD_MESSAGE === 'true'
export const TUTORIAL_ENABLED = process.env.REACT_APP_ONBOARD_TUTORIAL_ENABLED === 'true'
export const PRONOUNS_ENABLED = process.env.REACT_APP_PRONOUNS_ENABLED === 'true'
export const DIVERSITY_TAGS_ENABLED = process.env.REACT_APP_DIVERSITY_TAGS_ENABLED === 'true'
export const GENDER_TAGS_ENABLED = process.env.REACT_APP_GENDER_TAGS_ENABLED === 'true'
export const AVAILABLE_FROM_CALENDAR = process.env.REACT_APP_AVAILABILE_FROM_CALENDAR_ENABLED === 'true'
export const PROFILE_PICTURES_ENABLED = process.env.REACT_APP_PROFILE_PICTURES_ENABLED === 'true'
export const USE_DEMO_LANDER = process.env.REACT_APP_USE_DEMO_LANDER === 'true'
export const DISABLE_CRRAL_CALENDAR = process.env.REACT_APP_DISABLE_CRRAL_CALENDAR === 'true'
export const DEMO_REEL_ENABLED = process.env.REACT_APP_DEMO_REEL_ENABLED === 'true'
export const NUM_PRE_ROLE_ONBOARDING_STEPS = (ONTARIO_WORKING_STATUS ? 4 : 3)
  + (ONTARIO_RESIDENCE_STATUS && 1)
  + (COMMITMENT_STATUS && 1)
  + (CREDITS_ENABLED && 1)
  + (CV_ONBOARD_ENABLED && 1)
  + (DIVERSITY_TAGS_ENABLED && 1)
  + (PROFILE_PICTURES_ENABLED && 1)
export const NUM_ROLE_STEPS = DISABLE_INDUSTRY_STEP ? 4 : 5
export const NUM_ONBOARDING_STEPS = NUM_PRE_ROLE_ONBOARDING_STEPS + NUM_ROLE_STEPS
export const SUBSCRIBER_ROLES =
  !CUSTOM_STATIC_LANDER ?
    [
      'Creative Director',
      'Headhunter',
      'HR Manager',
      'Owner/Operator',
      'President',
      'Producer',
      'Project Manager',
      'Resource Manager',
    ] :
    [
      'Creative Director',
      'Recruiter',
      'HR Manager',
      'Owner/Operator',
      'President',
      'Producer',
      'Project Manager',
      'Resource Manager',
    ]
export const WORK_STATUSES = [
  'No legal permit to work in Canada',
  'Canadian work permit tied to specific employer',
  'Canadian work permit open to work for any employer',
  'Permanent Resident',
  'Canadian Citizen',
]

export const RELOCATION_STATUSES = [
  'I am not willing to relocate',
  'I am willing to relocate within Ontario',

]

export const GROUP_COLORS = {
  yellow: '#ECB22E',
  red: '#f06450',
  blue: '#2B72E6',
  purple: '#BE79D6',
  cyan: '#79CDD6',
  green: '#49b592',
}

export const ONBOARD_WALKTHROUGH_URL = process.env.REACT_APP_TUTORIAL_URL
export let CLOUD_CONSTANTS = []
getConstants().then(value => {
  CLOUD_CONSTANTS = value
})