import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { Box, Frame } from 'components'

const Loader = () => {
  return (
    <Frame>
      <Box flexVariant="flex center" my={5}>
        <CircularProgress />
      </Box>
    </Frame>
  )
}

export default Loader
