import React, { forwardRef } from 'react'
import { Box as MUIBox } from '@material-ui/core'

const Box = forwardRef(({ flexVariant, children, ...props }, ref) => {
  let flexProps = {}
  if (flexVariant) {
    flexProps = {
      ...flexProps,
      display: 'flex',
      flex: 1,
    }
    if (flexVariant.match(/column/)) {
      flexProps = {
        ...flexProps,
        flexDirection: 'column',
      }
    }
    if (flexVariant.match(/space-between/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'space-between',
      }
    }
    if (flexVariant.match(/space-evenly/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'space-evenly',
      }
    }
    if (flexVariant.match(/space-around/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'space-around',
      }
    }
    if (flexVariant.match(/align-start/)) {
      flexProps = {
        ...flexProps,
        alignItems: 'flex-start',
      }
    }
    if (flexVariant.match(/align-end/)) {
      flexProps = {
        ...flexProps,
        alignItems: 'flex-end',
      }
    }

    if (flexVariant.match(/justify-end/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'flex-end',
      }
    }
    if (flexVariant.match(/justify-center/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'center',
      }
    } else if (flexVariant.match(/align-center/)) {
      flexProps = {
        ...flexProps,
        alignItems: 'center',
      }
    } else if (flexVariant.match(/center/)) {
      flexProps = {
        ...flexProps,
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
  }

  return (
    <MUIBox ref={ref} {...flexProps} {...props}>
      {children}
    </MUIBox>
  )
})

export default Box
