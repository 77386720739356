import React, { useEffect, useState } from 'react'
import { Heading, Box, Button, Typography } from 'components'
import { getUserGroups } from 'utils'
import { ProfileIcons } from 'components/freelancer/ProfileCard'

const Group = ({ history, markets, user }) => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    getUserGroups().then((groups) => setGroups(groups))
  }, [])

  const onEdit = (id) => () => history.push(`/groups/edit/${id}`)

  const onAdd = () => history.push('/groups/create')

  if (!groups || groups.length === 0) return null

  return (
    <>
      <Heading>Groups</Heading>
      <Box my={4}>
        {groups.map((group) => (
          <GroupItem key={group.id} group={group} onEdit={onEdit} />
        ))}
      </Box>

      <Button
        style={{ width: '100%', marginBottom: 12 }}
        variant="contained"
        color="primary"
        onClick={onAdd}
      >
        Add new group
      </Button>
    </>
  )
}

export default Group

const GroupItem = ({ group, onEdit }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" my={4}>
    <Box flex={1} display="flex" alignItems="center">
      <ProfileIcons groups={[group]} />
      <Typography
        variant="h5"
        style={{ marginLeft: 12, lineHeight: 1.2, fontWeight: 'normal' }}
      >
        {group.name}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center">
      <Typography
        style={{
          fontSize: 12,
          lineHeight: '12px',
          marginRight: 8,
          fontWeight: 'normal',
        }}
      >
        {group.freelancers ? `${group.freelancers.length} freelancers` : ''}
      </Typography>
      {onEdit && (
        <Button
          style={{ marginRight: 12 }}
          variant="outlined"
          onClick={onEdit(group.id)}
        >
          View
        </Button>
      )}
    </Box>
  </Box>
)
