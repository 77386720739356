import React, { useState } from 'react'
import { onClickUserEmail } from 'utils'
import { Typography, Box, ButtonBase } from 'components'
import { format } from 'date-fns'
import { MailIcon } from 'components/icons'
import { IconButton } from 'components/mui'
import { CUSTOM_STATIC_LANDER } from 'constants'

export default function JobCard({ jobPost }) {
  const [showLinks, setShowLinks] = useState(false)
  jobPost.workingStyle = jobPost.workingStyle || jobPost.remote
  const { startDate, endDate } = jobPost
  const dateRange =
    startDate && endDate
      ? `${format(
        startDate?.toDate ? startDate.toDate() : startDate,
        'MMM d',
      )} - ${format(
        endDate?.toDate ? endDate.toDate() : endDate,
        'MMM d yyyy',
      )}`
      : null
  return (
    <ButtonBase
      disableRipple
      key={jobPost.id}
      className="box"
      component="div"
      onMouseEnter={() => setShowLinks(true)}
      onMouseLeave={() => setShowLinks(false)}
    >
      <Box
        className="inner"
        position="relative"
        textAlign="left"
        flexVariant="column space-between"
        style={{
          background: '#D4D4E7',
          color: '#1d1d1d',
          padding: '8px 12px',
          boxSizing: 'border-box',
          borderRadius: 4,
        }}
      >
        <ContactLinks active={showLinks} jobPost={jobPost} color={'#D4D4E7'} />
        <Box>
          <TopSection {...jobPost} />

          <MiddleSection
            role={jobPost.role}
            title={jobPost.title}
            company={jobPost.company}
            description={jobPost.description}
            commitment={jobPost.commitment}
            payAmount={jobPost.payAmount}
            payRate={jobPost.payRate}
            footer={dateRange}
            startDate={jobPost.startDate}
            endDate={jobPost.endDate}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box>
            {jobPost.skills.slice(0, 3).map((title) => (
              <Tag
                key={title}
                display="inline-flex"
                border="1px solid #1d1d1d"
                my={0.25}
              >
                {title}
              </Tag>
            ))}
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  )
}

const ContactLinks = ({ active, color, jobPost }) => {
  return (
    <Box
      position="absolute"
      flex={1}
      flexVariant="column"
      justifyContent="space-between"
      bgcolor={color}
      style={{
        inset: 0,
        opacity: active ? 1 : 0,
        padding: '8px 12px',
        pointerEvents: active ? 'auto' : 'none',
        transition: 'opacity 200ms',
      }}
    >
      <Box>
        <TopSection {...jobPost} />
        <MiddleSectionContact {...jobPost} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation()
          onClickUserEmail(jobPost)
        }}
      >
        <IconButton style={{ backgroundColor: 'black', padding: 6 }}>
          <MailIcon style={{ fontSize: 18, fill: 'white' }} />
        </IconButton>
        <Typography align="left" variant="subtitle1" style={{ fontSize: 12 }}>
          Contact <span style={{ fontWeight: 'bold' }}>{jobPost.company}</span>
        </Typography>
      </Box>
    </Box>
  )
}

const TopSection = ({ workingStyle, experience, city }) => (
  <Box display="flex" justifyContent="space-between" height={20} mb={1}>
    {workingStyle === 'remote' ? (
      <Tag display="inline-flex" bgcolor="black" textColor="#ECB230" my={0.25}>
        {WORKING_STYLE_LABEL[workingStyle]}
      </Tag>
    ) : (
      <Tag display="inline-flex" bgcolor="black" textColor="#ECB230" my={0.25}>
        {WORKING_STYLE_LABEL[workingStyle]} - {city}
      </Tag>
    )}
    <Typography style={{ marginTop: 6 }} align="left" variant="subtitle1">
      {experience[0]}
      {' - '}
      {experience[1]}
    </Typography>
  </Box>
)

const MiddleSection = ({
  role,
  title,
  company,
  footer,
  commitment,
  payAmount,
  payRate,
}) => (
  <Box mb={0.5}>
    <Typography
      style={{ fontSize: 12, color: 'black', fontWeight: 'bold', }}
    >
      <span style={{
        textTransform: 'capitalize'
      }}>
        {company}{' '}
      </span>
      <Typography
        style={{ display: 'inline', fontSize: 12, color: 'black' }}
      >
        is looking for a
      </Typography>
    </Typography>

    <Typography
      variant="h5"
      style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
    >
      {role}
    </Typography>

    <Typography
      variant="h5"
      style={{
        fontSize: 18,
        lineHeight: 1.1,
        maxHeight: 40,
        marginTop: 2,
        overflow: 'hidden',
        fontWeight: 'normal',
        textTransform: 'capitalize',
      }}
    >
      {title}
    </Typography>

    <Typography
      style={{ marginTop: 4, marginBottom: 4 }}
      align="left"
      variant="subtitle1"
    >
      {footer}
    </Typography>

    <Typography align="left" variant="subtitle1">
      {commitment ? COMMITMENT_LABELS[commitment] : ''}
    </Typography>
    <Typography align="left" variant="subtitle1">
      {payAmount ? `$${payAmount} ` : ''}
      {payRate ? PAY_RATE_LABELS[payRate] : ''}
    </Typography>
  </Box>
)

const MiddleSectionContact = ({
  role,
  title,
  description,
  company,
}) => (
  <MiddleSection
    role={role}
    title={title}
    company={company}
    footer={
      <div className="no-margin">
        <p style={{ marginBottom: 8 }}>{description}</p>
      </div>
    }
  />
)

const Tag = ({ children, textColor = '#1d1d1d', ...props }) => (
  <Box
    height={22}
    flex={0}
    borderRadius={4}
    maxWidth="100%"
    flexVariant="justify-start align-center"
    mr={1}
    {...props}
    px={1}
  >
    <Typography
      style={{
        fontSize: 12,
        lineHeight: '15px',
        color: textColor,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {children}
    </Typography>
  </Box>
)

const WORKING_STYLE_LABEL = {
  remote: 'Remote',
  'in-office': 'Office',
  'in-office-remote': 'Hybrid',
}

const PAY_RATE_LABELS = {
  'per-hour': 'Per Hour',
  'per-day': 'Per Day',
  'per-week': 'Per Week',
  'flat-rate': 'Flat Rate',
}

const COMMITMENT_LABELS = !CUSTOM_STATIC_LANDER ?{
  'small-project': 'Small project',
  'large-project': 'Large project',
  'some-of-your-time': 'Some of your time',
  'all-of-your-time': 'All of your time',
  'full-time-job': 'Full time job',
} :
{
  'contract-full-time': 'Contract: Full Time',
  'contract-part-time': 'Contract: Part Time',
  'freelance': 'Freelance',
}
