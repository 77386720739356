import { useEffect, useState } from 'react'
import defaultLogo from 'assets/logo.png'
import pluralize from 'pluralize'
import debounce from 'lodash/debounce'
import clamp from 'lodash/clamp'
import firebase, { getContent } from 'utils/firebase'
import { uniqBy } from 'lodash'
import { SUBSCRIPTIONS_ENABLED } from '../constants'

export { useTheme } from '@material-ui/core'
export { useParams, useHistory } from 'react-router-dom'
export { makeStyles } from '@material-ui/styles'
export { default as firebase } from 'utils/firebase'
export * from 'utils/firebase'
export * from 'utils/user'

export const getFormValues = (event) => {
  const result = {}

  for (let i in event.target.elements) {
    const thing = event.target.elements[i]
    if (thing.name && typeof thing.value === 'string') {
      result[thing.name] = thing.value
    }
  }

  return result
}

export const slugify = (s = '') =>
  s
    .toLowerCase()
    .replace(/!|:|/g, '')
    .replace('&', 'and')
    .replace(/\//g, '-')
    .replace(/ +/g, '-')

export const getCitiesFromMarkets = (markets) =>
  uniqBy(
    Object.values(markets)
      .sort((a, b) => a.cityName.localeCompare(b.cityName))
      .sort((a, b) => (a.disabled ? 1 : b.disabled ? -1 : a.index - b.index))
      .map((m) => ({ label: m.cityName, id: m.cityId, disabled: m.disabled }))
      .filter((m) => !m.disabled),
    (i) => i.id,
  )

export const getRolesFromMarkets = (markets) =>
  uniqBy(
    Object.entries(markets).map(([k, v]) => ({
      industryId: k.split('-')[0],
      roles: v.roles.map((r) => ({ ...r, industryId: k.split('-')[0] })),
    })),
    (m) => m.industryId,
  )
    .map((m) => m.roles)
    .flat()
    .map((r) => ({
      id: r.id,
      roleId: r.id,
      label: r.name.trim(),
      industryId: r.industryId,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

export const getIndustriesFromMarkets = (markets) =>
  uniqBy(
    Object.values(markets)
      .map((m) => ({ label: m.industry, id: m.industryId, roles: m.roles }))
      .flat(),
    (m) => m.id,
  ).sort((a, b) => a.label.localeCompare(b.label))

export const useTags = () => {
  const [tags, setTags] = useState()

  useEffect(() => {
    return firebase
      .firestore()
      .collection('tags')
      .get()
      .then((update) => {
        let collection = []
        if (update.size) {
          update.forEach((doc) =>
            collection.push({ ...doc.data(), id: doc.id }),
          )
        }
        setTags(collection)
      })
  }, [])
  return tags
}

export const useDiversityTags = () => {
  const [diversityTags, setDiversityTags] = useState()

  useEffect(() => {
    return firebase
      .firestore()
      .collection('diversitytags')
      .get()
      .then((update) => {
        let collection = []
        if (update.size) {
          update.forEach((doc) =>
            collection.push({ ...doc.data(), id: doc.id }),
          )
        }
        setDiversityTags(collection)
      })
  }, [])
  return diversityTags
}

export const useGenderTags = () => {
  const [genderTags, setGenderTags] = useState()

  useEffect(() => {
    return firebase
      .firestore()
      .collection('gendertags')
      .get()
      .then((update) => {
        let collection = []
        if (update.size) {
          update.forEach((doc) =>
            collection.push({ ...doc.data(), id: doc.id }),
          )
        }
        setGenderTags(collection)
      })
  }, [])
  return genderTags
}

export const useOnResize = (callback) => {
  useEffect(() => {
    const handleResize = debounce(() => callback(window.innerWidth), 50)
    callback(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [callback])
}

export const useIsMobile = (width = 1000) => {
  const [mobile, setMobile] = useState(window.innerWidth < width)

  useOnResize(() => {
    setMobile(window.innerWidth < width)
  })

  return mobile
}

export const getUserExperience = (user, role) => {
  if (role && !['Hidden', 'Recently Added'].includes(role)) {
    const roleObj = Object.values(user.roles || {}).find(
      (r) => pluralize(r.label) === role,
    )
    return roleObj ? roleObj.experience : 0
  }
  return Object.values(user.roles || {}).reduce(
    (prevValue, currRole) => prevValue + currRole.experience,
    0,
  )
}

export const useOnScroll = (callback) => {
  useEffect(() => {
    callback()
    window.addEventListener('scroll', callback)
    return () => {
      window.removeEventListener('scroll', callback)
    }
  }, [callback])
}

export const useOnClickOutside = (ref, onClick) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })
}

export const formatPrice = (n) => `$${n.toFixed(2)}`

export const filterHiddenUsers =
  (hiddenUsers = []) =>
    (user) => {
      return !hiddenUsers.includes(user.id)
    }

export const transformMarketFreelancers = (marketFreelancers = {}) => {
  marketFreelancers = Object.entries(marketFreelancers).reduce(
    (obj, [marketId, freelancers]) => {
      obj[marketId] = Object.values(freelancers).reduce((obj, freelancer) => {
        const availability = (freelancer.availability || '')
          .split(',')
          .reduce((obj, avail) => {
            const [date, status, indefinite] = avail.split(':')
            return {
              ...obj,
              [date]: {
                status: Number(status),
                indefinite: indefinite === '1',
              },
            }
          }, {})
        obj[freelancer.id] = {
          ...freelancer,
          availability,
          experienceByRole: JSON.parse(freelancer.experienceByRole || '{}'),
          skills: (freelancer.skills || '').split(','),
          titles: (freelancer.titles || '').split(','),
        }
        return obj
      }, {})
      return obj
    },
    {},
  )

  return marketFreelancers
}

export const getRoleCounts = (users = []) => {
  const userRoles = users
    .map((user) => (user.titles || []).map((title) => pluralize(title)))
    .flat()

  const titleCounts = userRoles.reduce((obj, title) => {
    obj[title] = ++obj[title] || 1
    return obj
  }, {})

  return Object.entries(titleCounts).sort((a, b) => a[0].localeCompare(b[0]))
}

export const getSkillCounts = (users) => {
  const userSkills = users
    .map((user) => (user.skills || []).map((skill) => skill))
    .flat()

  const skillCounts = userSkills.reduce((obj, skill) => {
    obj[skill] = ++obj[skill] || 1
    return obj
  }, {})

  return Object.entries(skillCounts).sort((a, b) => a[0].localeCompare(b[0]))
}

export const filterUserWithStatus = (filterState) => (user) => {
  const avail = user.latestAvail
  return (
    (filterState[0] && avail.status === 1) ||
    (filterState[1] && avail.status === 3) ||
    (filterState[2] && avail.status === 2) ||
    avail.status === 0
  )
}

export const filterUserWithExperience =
  ([min, max]) =>
    (user) => {
      const experience = clamp(user.totalExperience, 1, 15)
      return experience >= min && experience <= max
    }

export const filterUserWithRole = (role) => (user) => {
  return !role || user.titles.includes(role)
}
export const filterUserWithSkills =
  (skills = []) =>
    (user) => {
      return (
        skills.length === 0 ||
        skills.every((skill) => user.skills.includes(skill))
      )
    }
export const filterUserWithCity = (cities) => (user) => {
  return cities.includes(user.market.cityId)
}

export const filterUsersByAvailability = (u, filterState) => {
  if (!u.desiredCommitments) return true;
  for (let key in filterState) {
    if (!!filterState[key] === true && u.desiredCommitments[key] === true) {
      return true;
    }
  }
  return false;
};

export const filterUserWithGenderTag =
  (genderTags = []) =>
    (user) => {
      const userTags = user.genderTags || []
      return (
        genderTags.length === 0 ||
        genderTags.every((genderTag) => userTags.includes(genderTag))
      )
    }

export const filterUserWithDiversityTag =
  (diversityTags = []) =>
    (user) => {
      const userTags = user.diversityTags || []
      return (
        diversityTags.length === 0 ||
        diversityTags.every((diversityTag) =>
          userTags.includes(diversityTag),
        )
      )
    }

export const usePackages = () => {
  const [packages, setPackages] = useState([])

  useEffect(() => {
    getPackages().then(setPackages)
  }, [])

  return packages
}

export const useContent = (uid) => {
  const [content, setContent] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const logo = isLoading ? '' : content?.logo?.imageValue[0]?.src ?? defaultLogo
  const tagline = isLoading ? '' : content?.tagline?.textValue ?? 'Where agencies go for top Creative Freelance talent'
  const domain = isLoading ? '' : content?.domain?.textValue ?? 'crral.com'
  const contactEmail = isLoading ? '' : content?.contactEmail?.textValue ?? `hello@${domain}`
  const facebookURL = isLoading ? '' : content?.facebookURL?.textValue ?? 'https://www.facebook.com/The.Freelancer.finder'
  const linkedInURL = isLoading ? '' : content?.linkedInURL?.textValue ?? 'https://ca.linkedin.com/company/crral-com'
  const title = isLoading ? '' : content?.title?.textValue ?? 'CRRAL'

  useEffect(() => {
    getContent().then((c) => {
      setIsLoading(false)
      setContent(c)
    })
  }, [uid])

  let landing = null
  if (!isLoading && content) {
    landing = {
      'heading': content['landing-page-1-heading']?.textValue,
      'tagline': content['landing-page-2-tagline']?.textValue,
      'taglineShort': content['landing-page-2-tagline-short']?.textValue ?? 'Computer Animation Studios of Ontario',
      'sponsor': content['landing-page-3-sponsor'],
      'use-cases': content['landing-page-4-use-cases']?.textValues,
      'freelancer-signup-label': content['landing-page-5.1-freelancer-signup-label']?.textValue,
      'subscriber-signup-label': content['landing-page-5.2-subscriber-signup-label']?.textValue,
      'member-logos': content['landing-page-6-member-logos'],
      'footer-cta': content['landing-page-8-footer-cta']?.textValue,
      'freelancer-signup-color': content['landing-page-5.1-freelancer-signup-color']?.textValue,
      'subscriber-signup-color': content['landing-page-5.2-subscriber-signup-color']?.textValue,
    }
  }
  let onboarding = null
  if (!isLoading && content) {
    onboarding = {
      'contact-heading': content['onboard-contact-heading']?.textValue,
      'contact-subheading': content['onboard-contact-subheading']?.textValue,
      'website-heading': content['onboard-website-heading']?.textValue,
      'website-subheading': content['onboard-website-subheading']?.textValue,
    }
  }

  let search = null
  if (!isLoading && content) {
    search = {
      'default-industry': content['search-default-industry']?.textValue,
    }
  }

  return {
    content,
    landing,
    domain,
    contactEmail,
    facebookURL,
    title,
    linkedInURL,
    logo,
    tagline,
    isLoading,
    onboarding,
    search,
  }
}

export const useSubscription = (uid) => {
  const [subscription, setSubscription] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!uid) {
      setIsLoading(false)
      return
    }
    getUserSubscription(uid).then((sub) => {
      if (!sub) setIsLoading(false)
      setSubscription(sub)
    })
  }, [uid])

  useEffect(() => {
    if (subscription) setIsLoading(false)
  }, [subscription])
  return {
    subscription,
    isLoading,
  }
}

export const useHasActiveSubscription = (uid) => {
  const { subscription, isLoading } = useSubscription(uid)

  let hasActiveSubscription = false

  if (
    !SUBSCRIPTIONS_ENABLED ||
    (subscription && ['active', 'trialing'].includes(subscription.status))
  ) {
    hasActiveSubscription = true
  }

  return { hasActiveSubscription, isLoading }
}

export const redirectToCheckout = async (uid, priceId) => {
  const docRef = await firebase
    .firestore()
    .collection('stripe_customers')
    .doc(uid)
    .collection('checkout_sessions')
    .add({
      price: priceId,
      allow_promotion_codes: true,
      tax_rates: ['txr_1KOlZ5FK4VH2tYhvD3BAxbgW'],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    })
  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data()
    if (error) {
      alert(`An error occured: ${error.message}`)
    }

    if (url) {
      window.location.assign(url)
    }
  })
}
export const getUserPortalLink = async () => {
  const functionRef = firebase
    .app()
    .functions('us-east1')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  const { data } = await functionRef({ returnUrl: window.location.origin })
  return data.url
}

export const getUserSubscription = async (uid) => {
  const query = await firebase
    .firestore()
    .collection('stripe_customers')
    .doc(uid)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get()

  const doc = query.docs[0]
  if (!doc) return null
  return {
    id: doc.id,
    ...doc.data(),
  }
}

const getPackages = async () => {
  const query = await firebase
    .firestore()
    .collection('stripe_products')
    .where('active', '==', true)
    .get()

  const basePackages = await Promise.all(
    query.docs.map(async (doc) => {
      const priceSnap = await doc.ref.collection('prices').get()
      return {
        id: doc.id,
        ...doc.data(),
        prices: priceSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      }
    }),
  )

  const packages = basePackages.filter(p => p.name === 'Big' || p.name === 'Medium' || p.name === 'Small')
    .filter((p) => p.prices.find((p) => p.active))
    .map((p) => {
      const price = p.prices.find((p) => p.active)
      return {
        ...p,
        cost: price.unit_amount / 100,
        priceId: price.id,
      }
    })
    .sort((a, b) => a.cost - b.cost)

  return packages
}

export const getDecoratedJobBoardPosts = async (markets, posts) => {
  const cities = getCitiesFromMarkets(markets)
  const roles = getRolesFromMarkets(markets)
  const industries = getIndustriesFromMarkets(markets)
  return posts.map((post) => {
    return decorateJobBoardPost(post, cities, roles, industries)
  })
}

export const decorateJobBoardPost = (post, cities, roles, industries) => {
  return {
    ...post,
    city: cities.find((c) => c.id === post.cityId)?.label,
    role: roles.find((c) => c.id === post.roleId)?.label,
    industry: industries.find((c) => c.id === post.industryId)?.label,
  }
}

export const getIsOnboarding = () => {
  const params = new URLSearchParams(window.location.search.split('?')[1])
  return params.get('source') === 'onboarding'
}