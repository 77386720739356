import React from 'react'
import {
  Box,
  Frame,
  Typography,
  Divider,
  IconButton,
  Heading,
  ButtonBase,
} from 'components'
import { useState } from 'react'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const SubscriberFaqView = ({ user }) => {
  const [activeQ, setActiveQ] = useState(null)
  return (
    <Frame maxWidth={null} user={user}>
      <Box maxWidth={400} mx="auto">
        <Heading>
          Frequently
          <br />
          Asked Questions
        </Heading>

        {QUESTIONS.map((group, index) => (
          <Box key={`group-${index}`}>
            <ButtonBase
              onClick={() => setActiveQ(activeQ === index ? null : index)}
              style={{ textAlign: 'left', width: '100%' }}
              disableRipple
            >
              <Box
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography style={{ color: '#1d1d1d' }}>
                  {group.question}
                </Typography>
                <Box>
                  <IconButton
                    style={{
                      padding: 5,
                      color: '#1d1d1d',
                      opacity: activeQ === index ? 1 : 0.5,
                    }}
                    onClick={() => setActiveQ(activeQ === index ? null : index)}
                  >
                    {activeQ === index ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </IconButton>
                </Box>
              </Box>
            </ButtonBase>
            {activeQ === index && (
              <>
                <Divider />
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: group.answer.replace(/\n/g, '<br /><br />'),
                  }}
                  style={{ color: '#696969' }}
                ></Typography>
              </>
            )}
            <Divider />
          </Box>
        ))}
      </Box>
    </Frame>
  )
}

export default SubscriberFaqView

const QUESTIONS = [
  {
    question: 'Why CRRAL? Our double mission.',
    answer: `Help companies bring order to the chaos of managing a freelance workforce.
Help freelancers grow their network of quality employers.`,
  },
  {
    question: 'Who made CRRAL?',
    answer: `Chris Harrison and Rich Cooper are Toronto advertising freelancers.
CRRAL is our response to the persistent confusion that has bedeviled the Freelance world for over a decade.`,
  },
  {
    question: 'How does CRRAL help Freelancers?',
    answer: `• CRRAL is a free way to increase your exposure and grow your network of employers
• Because agencies & employers (will eventually) pay to see the list, the employers are good
• CRRAL skill tags help you show off your abilities
• CRRAL never takes money from your projects or meddles with the work`,
  },
  {
    question: 'How does CRRAL help people who hire Freelancers?',
    answer: `• Instant, direct access to Freelancer’s portfolios, Linked In, e-mail and phone; no gatekeeping
• CRRAL search tools help you identify the job titles, skills, experience you need
• Colour coded availability, so you can see who’s available now or at a future date
• Use the Favourite button to mark your best people, so they’re at the top of your searches
• Do you have an idea for a feature you’d like? Let us know at HiRich@CRRAL.com`,
  },
  {
    question: 'How do I work the CRRAL Search Tools?',
    answer: `1: Type whatever you’re looking for into the search bar. It can be a job title, a skill, even the name of a specific person.
2:  CRRAL has hundreds of tags, and you’ll find them below the search bar.
3: CRRAL also has filters that help you narrow your search. By industry, city, years of experience, and their availability status.
4: Want a personal tutorial on how to work CRRAL? HiRich@CRRAL.com`,
  },
  {
    question: 'I want someone local, how does CRRAL help me?',
    answer: `CRRAL was built to protect careers and grow local economies. Many jobs found through CRRAL will involve face-to-face meetings and at least some work in the employer’s office. Freelancers are tagged with their city, so it's easy to find who's local and who's not.`,
  },
  {
    question: 'My favourite Freelancer(s) aren’t on CRRAL',
    answer: `If you’re a subscriber, we make it easy to invite freelancers. If you’re signed in you can simply use the 'invite' button in the top corner. It's always free for freelancers, and they're almost always happy to sign up when a potential employer invites them. `,
  },
  {
    question: 'My city isn’t on CRRAL',
    answer: `We started in our hometown of Toronto, and quickly added all major North American cities after the overwhelming positive response. We're happy to add your city if we've missed it though. Let us know at HiRich@CRRAL.com and we’ll notify you when it’s ready.`,
  },
  {
    question: 'The industry I need isn’t on CRRAL',
    answer: `CRRAL started with business the founders know, Advertising/Marketing. With the enthusiasm that received from the industry, we've expanded to include Web Development and Film Production roles. Future industries may be added later. If you're interested in CRRAL expanding to support your industry we'd love to hear from you: HiRich@CRRAL.com`,
  },
  {
    question: "What's next for CRRAL?",
    answer: `We're always improving CRRAL so it serves Freelancers and Employers better, and our new features often start with ideas from our users. Soon we'll be launching Works Well With, where Freelancers indicated their preferred partners; Build Teams, to organize & visualize Freelancer teams; a Job Board, so Freelancers can be more active in their search; and Equipment Tags, so people in the film industry can search for specific gear when it's necessary.`,
  },
  {
    question: 'CRRAL is “buggy” or something makes no sense',
    answer: `We’re not perfect! If you find a bug some weird issue, we’d LOVE you to report problems at HiRich@CRRAL.com`,
  },
]
