import React from 'react'
import { Button, Typography } from 'components'
import { Box, Dialog } from '@material-ui/core'
import { useHistory } from 'react-router'
import { CUSTOM_STATIC_LANDER } from 'constants'

function IntroDialog({ open, onClose }) {
  const history = useHistory()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{ maxWidth: 500, margin: '0 auto' }}
    >
      <Box p={5} textAlign="center">
        <Typography variant="h3">Congratulations!<br />You did it.</Typography>

        <Box mt={2} mb={4}>
          <Typography>
            Your profile is a thing of beauty. <br />Remember, you can edit or add to it any time.
          </Typography>
          {!CUSTOM_STATIC_LANDER && (
            <>
              <Box my={2} />
              <Typography>
                We’ve set your Avails to ‘Might Be Available’, this can be changed on your profile page.
              </Typography>
            </>
          )}

          <Box my={2} />
          <Typography>
            One last thing! It’s best to add a quick bio for some personality.
          </Typography>
          <Box my={2} />
          <Typography>
            Don’t overthink it. Just write some friendly words. Talk about the work you love to do and/or what employers love about you. Or talk about your hobbies or pets.
          </Typography>

        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/profile')}
        >
          Take me to my profile
        </Button>
      </Box>
    </Dialog>
  )
}

export default IntroDialog
