import React, { useEffect, useState } from 'react'
import chunk from 'lodash/chunk'
import { Box, ButtonBase, Typography } from 'components'
import { useOnResize } from 'utils'

const CardList = ({ cards }) => {
  const [numPerRow, setNumPerRow] = useState(2)
  useOnResize(() => {
    let num = 1
    if (window.innerWidth >= 450) num = 2
    if (window.innerWidth >= 750) num = 3
    if (window.innerWidth >= 1000) num = 4
    if (window.innerWidth >= 1250) num = 5

    setNumPerRow(num)
  })

  const [renderAmount, setRenderAmount] = useState(10)

  useEffect(() => {
    setRenderAmount(10)
  }, [cards.length])

  let numItemsToRender = renderAmount
  let numProfilesToRender = numItemsToRender
  if (cards.length > numItemsToRender) numProfilesToRender--
  const finalCards = [
    ...cards.slice(0, numProfilesToRender),
    cards.length > numItemsToRender && (
      <MoreBox
        key="more-box"
        amount={Math.min(cards.length - numProfilesToRender, 20)}
        onClick={() => setRenderAmount((r) => r + 20)}
      />
    ),
  ].filter((r) => !!r)
  const remainder = finalCards.length % numPerRow
  const numEmptyElements = remainder === 0 ? 0 : numPerRow - remainder
  const renderArray = chunk(
    [
      ...finalCards,
      ...new Array(numEmptyElements)
        .fill('')
        .map((_, i) => <Box key={`box-${i}`} className="box" />),
    ],
    numPerRow,
  )

  return (
    <Box flex={1} mb={0.5}>
      {finalCards.length > 0 &&
        renderArray.map((group, rowIndex) => (
          <Box className="box-wrap" key={`row-${rowIndex}`} display="flex">
            {group}
          </Box>
        ))}
    </Box>
  )
}

const MoreBox = ({ amount, onClick }) => (
  <ButtonBase
    disableRipple
    className="box"
    component="div"
    onClick={onClick}
    style={{ marginRight: 0 }}
  >
    <Box
      className="inner"
      position="relative"
      textAlign="left"
      flexVariant="center"
      style={{
        borderRadius: 4,
        border: '1px solid #ddd',
        marginRight: 0,
      }}
    >
      <Typography style={{ color: '#1d1d1d' }}>
        {amount ? `Show ${amount} More` : 'Show Less'}
      </Typography>
    </Box>
  </ButtonBase>
)

export default CardList
