import React from 'react'
import { Box } from 'components'
import { useTheme } from 'utils'
import { FilterCheckbox } from './FilterCheckbox'
import { green, yellow, red } from '@material-ui/core/colors'

export function AvailabilityFilter({
  filterState: state,
  setFilterState: setState,
}) {
  const theme = useTheme()
  const [available, itDepends, unavailable] = state.status || []

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <FilterCheckbox
        label="Available"
        color={theme.palette.green.main}
        styledColor={'#388e3c'}
        checked={!!available}
        onChange={() =>
          setState({
            ...state,
            status: state.status.map((v, i) => (i === 0 ? (v ? 0 : 1) : v)),
          })
        }
      />
      <FilterCheckbox
        label="It depends"
        color={theme.palette.yellow.main}
        styledColor={yellow[700]}
        checked={!!itDepends}
        onChange={() =>
          setState({
            ...state,
            status: state.status.map((v, i) => (i === 1 ? (v ? 0 : 1) : v)),
          })
        }
      />
      <FilterCheckbox
        isLast
        label="Unavailable"
        color={theme.palette.red.main}
        styledColor={red[700]}
        checked={!!unavailable}
        onChange={() =>
          setState({
            ...state,
            status: state.status.map((v, i) => (i === 2 ? (v ? 0 : 1) : v)),
          })
        }
      />
    </Box>
  )
}
