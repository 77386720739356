import { useEffect } from 'react'
import { signOut } from 'utils'

const LogoutView = ({ history, user, setUserNull }) => {
  if (!user) history.push('')
  useEffect(() => {
    signOut().then(setUserNull)
  }, [setUserNull])

  return null
}

export default LogoutView
