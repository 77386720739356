import React from 'react'
import { Frame, Button, TextField, Heading, SelectField } from 'components'
import { getFormValues, updateUser } from 'utils'
import firebase from 'utils/firebase'
import { SUBSCRIBER_ROLES } from '../../../constants'

const SubscriberCompanyForm = ({ user }) => {
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>A bit about you</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          firebase
            .analytics()
            .logEvent('user_subscriber_company_details_submit_click')
          const { company, role, city } = getFormValues(event)
          await updateUser(user, { company, occupation: role, city }).then(
            () => {
              window.location = '/'
            },
          )
        }}
      >
        <TextField required name="company" label="Company" />
        <TextField required name="city" label="City" />

        <SelectField
          required
          name="role"
          label="Role"
          choices={SUBSCRIBER_ROLES.map((label) => ({ label, value: label }))}
        />

        <Button type="submit">Start finding Freelancers</Button>
      </form>
    </Frame>
  )
}

export default SubscriberCompanyForm
