import { Box } from 'components'
import { FilterCheckbox } from './FilterCheckbox'

export const EmploymentStatusFilter = ({ filterState: state, setFilterState: setState, resetState }) => {
    const { employment_status } = state

    return (
        <Box display="flex" flex={1} flexDirection="column" minWidth={160}>
            <FilterCheckbox
                label="Full Time"
                checked={employment_status.includes('Full Time')}
                onChange={() => setState(employment_status.includes('Full Time')? { ...state, employment_status: employment_status.filter(v => v !== 'Full Time') } : { ...state, employment_status: [...employment_status, 'Full Time'] })}
                onReset={() => resetState('employment_status')}
                color="primary"
            />
            <FilterCheckbox
                label="Contract"
                checked={employment_status.includes('Contract')}
                onChange={() => setState(employment_status.includes('Contract')? { ...state, employment_status: employment_status.filter(v => v !== 'Contract') } : { ...state, employment_status: [...employment_status, 'Contract'] })}
                onReset={() => resetState('employment_status')}
                color="primary"
            />
            <FilterCheckbox
                label="Freelance"
                checked={employment_status.includes('Freelance')}
                onChange={() => setState(employment_status.includes('Freelance')? { ...state, employment_status: employment_status.filter(v => v !== 'Freelance') } : { ...state, employment_status: [...employment_status, 'Freelance'] })}
                onReset={() => resetState('employment_status')}
                color="primary"
            />
        </Box>
    )
}