import React, { useEffect, useState } from 'react'
import { Divider, Frame, Typography, Button, Box } from 'components'
import { useHistory } from 'utils'
import homeImg from '../assets/home.png'
import upsell1 from '../assets/upsell1.png'
import upsell2 from '../assets/upsell2.png'
import upsell3 from '../assets/upsell3.png'
import firebase from 'utils/firebase'
import { theme } from 'theme'

export const StaticLander = (props) => {
  const history = useHistory()
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    firebase.analytics().logEvent('user_landing_page_visit')
  }, [])
  return (
    <Frame py={0} maxWidth={null} containerStyle={{ padding: 0 }}>
      <Box mt={6} />

      <Typography align="center" variant="h3">
        Where agencies go for top creative Freelance talent.
      </Typography>

      <LanderVideo />

      <SignupLinks history={history} />

      <WhyCRRAL />

      <Divider />

      <AboutCRRAL onClick={() => setShowVideo(true)} />

      <QuotesCRRAL />

      <SignupLinks history={history} />
      <Box mb={15} />

      {showVideo && <AboutVideoModal onClose={() => setShowVideo(false)} />}
    </Frame>
  )
}

const LanderVideo = () => (
  <Box mt={8} mb={5} flexVariant="center">
    <video
      autoPlay="autoplay"
      muted
      loop
      controls
      src="https://firebasestorage.googleapis.com/v0/b/crral-4faae.appspot.com/o/CRRAL%20LandingLoop%20Feb%202022%20final.mp4?alt=media&token=d22642d2-06bd-482d-8403-75929c710f9d"
      style={{ width: '100%', maxWidth: 1000, margin: '0 auto' }}
    />
  </Box>
)

const SignupLinks = ({ history }) => (
  <Box display="flex">
    <Box align="center" pt={0} pb={0} flex={1}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        boxSizing="border-box"
        px={{ xs: 4, md: 0 }}
        width={{ xs: '100%', sm: 800 }}
      >
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              firebase.analytics().logEvent('user_subscriber_signup_click')
              history.push('/signup')
            }}
            style={{ width: '100%' }}
          >
            I hire Freelancers
          </Button>
          <Typography style={{ color: '#1d1d1d', fontSize: 13, marginTop: 2 }}>
            Start your free month now.
          </Typography>
        </Box>

        <Box m={3} />

        <Box flex={1}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              firebase.analytics().logEvent('user_freelancer_signup_click')
              history.push('/freelancer-signup')
            }}
            style={{ width: '100%' }}
          >
            I am a Freelancer
          </Button>
          <Typography style={{ color: '#1d1d1d', fontSize: 13, marginTop: 2 }}>
            Join us! It’s always free for Freelancers.
          </Typography>
        </Box>

        <Box m={3} />

        <Box flex={1}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              firebase.analytics().logEvent('user_login_click')
              history.push('/login')
            }}
            style={{ width: '100%' }}
          >
            I already have an account
          </Button>
          <Typography style={{ color: '#1d1d1d', fontSize: 13, marginTop: 2 }}>
            Login now.
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
)

const WhyCRRAL = () => (
  <Box mt={14} maxWidth={{ xs: '100%', md: 1300 }} mx="auto">
    <Typography align="center" variant="h3">
      Why CRRAL
    </Typography>

    <Box
      mt={3}
      mb={12}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Box my={3} align="center" flex={1}>
        <img
          alt="crral-app"
          style={{ height: 100, marginBottom: -8 }}
          src={upsell1}
        />
        <Typography variant="h5">The Best Talent</Typography>
        <Box my={0.5} />
        <Typography>People you won’t find anywhere else</Typography>
      </Box>
      <Box my={3} align="center" flex={1}>
        <img
          alt="crral-app"
          style={{ height: 100, marginBottom: -8 }}
          src={upsell3}
        />

        <Typography variant="h5">Unlimited, Easy Access</Typography>
        <Box my={0.5} />
        <Typography>One-click contact. We don’t gatekeep.</Typography>
      </Box>
      <Box my={3} align="center" flex={1}>
        <img
          alt="crral-app"
          style={{ height: 100, marginBottom: -8 }}
          src={upsell2}
        />

        <Typography variant="h5">Saves Hours of Stress</Typography>
        <Box my={0.5} />
        <Typography>Make faster hiring decisions, with certainty.</Typography>
      </Box>
    </Box>
  </Box>
)

const AboutCRRAL = ({ onClick }) => (
  <Box
    mt={5}
    maxWidth={{ xs: '100%', md: 1400 }}
    mx="auto"
    display="flex"
    flexDirection={{ xs: 'column', md: 'row' }}
  >
    <Box
      flexVariant="column justify-center align-start"
      flex={1}
      pl={{ xs: 0, sm: 4, lg: 6 }}
      pb={5}
      boxSizing="border-box"
      px={{ xs: 4, md: 4 }}
    >
      <Typography variant="h3">More about CRRAL</Typography>

      <Box my={1} />

      <Typography>
        CRRAL was created by Freelancers to solve the big recurring problem.
        Stressed-out Resource Managers and Creative Directors wondering “Who’s
        out there that’s good, and can help me right now?” Because we treat
        Freelancers nice and never take their money, we built the best community
        of talent in the country. And it’s still growing. Now Freelancers have a
        fair place to call home and Freelance-Hirers have a simple, customizable
        system that saves them hours of stress and uncertainty. Join us.
      </Typography>

      <Box my={3} />

      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        size="large"
      >
        Watch the Video
      </Button>
    </Box>

    <Box flex={1} mr={-3}>
      <img alt="crral-app" style={{ width: '100%' }} src={homeImg} />
    </Box>
  </Box>
)

const QuotesCRRAL = () => (
  <Box
    mt={20}
    mb={20}
    px={3}
    maxWidth={{ xs: '100%', md: 1400 }}
    mx="auto"
    display="flex"
    flexDirection={{ xs: 'column', md: 'row' }}
  >
    {quotes.map(({ quote, name, role }) => (
      <Box key={quote} flex={1} my={2} mx={2}>
        <Typography
          variant="h4"
          style={{ color: theme.palette.green.main, fontWeight: 'bold' }}
        >
          "{quote}"
        </Typography>
        <Box alignItems="center" display="flex">
          <Typography style={{ fontSize: 28, marginBottom: 32 }}>~</Typography>
          <Box ml={3} mt={2} minHeight={80}>
            <Typography style={{ marginBottom: 8, fontSize: 23 }}>
              {name}
            </Typography>
            <Typography>{role}</Typography>
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
)

const AboutVideoModal = ({ onClose }) => (
  <Box
    bgcolor="rgba(255,255,255,0.5)"
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    padding="0 8px"
    onClick={onClose}
    display="flex"
    justifyContent="center"
    alignItems="center"
    zIndex={9999}
  >
    <video
      onClick={(e) => e.stopPropagation()}
      autoPlay
      loop
      controls
      src="https://firebasestorage.googleapis.com/v0/b/crral-4faae.appspot.com/o/CRRAL_Intro_Video_May2021_V7.mp4?alt=media&token=165b92e7-a0fa-4a41-b75f-e701069bf93f"
      style={{ width: '100%', maxWidth: 800 }}
    />
  </Box>
)

const quotes = [
  {
    quote: 'The first place I turn when I need freelancers.',
    name: 'Ron Tite',
    role: 'Founder and CCO, Church+State',
  },
  {
    quote: 'It’s effen brilliant!',
    name: 'Natalie McClare',
    role: 'Director of Creative Operations, Grey Canada',
  },
  {
    quote: 'The tool the industry has been waiting for.',
    name: 'Hayes Steinberg',
    role: 'Partner, The Mark',
  },
]
