import React, { useEffect, useState } from 'react'
import {
  Heading,
  Box,
  Button,
  Typography,
  CloseIcon,
  ButtonBase,
} from 'components'
import {
  deleteJobBoardPost,
  getUserJobBoardPosts,
  getDecoratedJobBoardPosts,
  useSubscription,
} from 'utils'
import isAfter from 'date-fns/isAfter'
import { PACKAGE_POST_COUNTS, SUBSCRIPTIONS_ENABLED } from '../../../constants'

const JobBoardList = ({ history, markets, user }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    if (!markets) return
    getUserJobBoardPosts().then((_posts) =>
      getDecoratedJobBoardPosts(markets, _posts).then(setPosts),
    )
  }, [markets])

  const onEdit = (id) => () => history.push(`/job_board/edit/${id}`)

  const onAdd = () => history.push('/job_board/create')

  const onDelete = (id) => () => {
    const confirm = window.confirm('Are you sure?')
    if (confirm) {
      deleteJobBoardPost(id).then(() =>
        setPosts((p) => p.filter((_p) => _p.id !== id)),
      )
    }
  }
  const { subscription } = useSubscription(user?.id)

  const maxPosts =
    PACKAGE_POST_COUNTS[subscription?.product?.id || 'prod_JqW9QrTHa0KHMP']

  const activePosts = posts.filter(
    (p) => p.endDate && isAfter(p.endDate?.toDate(), new Date()),
  )

  const allowNewPost = activePosts.length < maxPosts

  return (
    <>
      <Heading>Active Job Posts</Heading>
      <Typography align="center">
        You can post up to {maxPosts} active job posts. You have{' '}
        {maxPosts - activePosts.length} remaining.
      </Typography>
      <Box my={4}>
        {activePosts.map((post) => (
          <JobPostItem
            key={post.id}
            post={post}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </Box>

      <Button
        disabled={!allowNewPost}
        style={{ width: '100%', marginBottom: 12 }}
        variant="contained"
        color="primary"
        onClick={onAdd}
      >
        Add new post
      </Button>

      {SUBSCRIPTIONS_ENABLED && (
        <Button
          disabled={!allowNewPost}
          style={{ width: '100%' }}
          component="a"
          href="/packages"
          variant="contained"
          color="primary"
        >
          Upgrade plan
        </Button>
      )}

      {/* <Box my={8}>
        <Heading>Expired Job Posts</Heading>
        {expiredPosts.map((post) => (
          <JobPostItem key={post.id} post={post} onDelete={onDelete} />
        ))}
      </Box> */}
    </>
  )
}

export default JobBoardList

const JobPostItem = ({ post, onEdit, onDelete }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" my={4}>
    <Box flex={1}>
      <Typography variant="h5" style={{ fontWeight: 'normal' }}>
        {post.role}, {post.title}
      </Typography>
    </Box>
    <Box>
      {onEdit && (
        <Button
          style={{ marginRight: 12 }}
          variant="outlined"
          onClick={onEdit(post.id)}
        >
          Edit
        </Button>
      )}
      <ButtonBase onClick={onDelete(post.id)}>
        <CloseIcon style={{ fill: 'red' }} />
      </ButtonBase>
    </Box>
  </Box>
)
