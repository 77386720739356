import React, { useEffect, useState } from 'react'
import { Container } from '@material-ui/core'
import { Box } from 'components'
import Footer from 'components/mui/Frame/Footer'
import Header from 'components/mui/Frame/Header'
import { useHistory } from 'utils'
import MissionDialog from 'containers/Subscriber/MissionDialog'

const Frame = ({
  user,
  children,
  py = 4,
  maxWidth,
  setSnackMessage,
  footerContent,
  containerStyle,
  ...props
}) => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Box
      bgcolor="#FBFBFB"
      flexVariant="flex column"
      overflow="hidden"
      {...props}
    >
      <Header
        history={history}
        user={user}
        setSnackMessage={setSnackMessage}
        openMissionDialog={() => setDialogOpen(true)}
      />
      <Box
        flexVariant="flex column"
        flexGrow="1"
        minHeight="calc(100vh - 81px)"
      >
        <Box display={{ xs: 'none', sm: 'block' }} style={{ marginTop: 81 }} />
        <Box flexVariant="flex column" py={py}>
          <Container maxWidth={maxWidth} style={containerStyle}>
            {children}
          </Container>
          {footerContent}
        </Box>
      </Box>

      <MissionDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <Footer history={history} user={user} />
    </Box>
  )
}

export default Frame
