import React, { useState } from 'react'
import { Heading, Checklist, TextField, Box, Frame } from 'components'
import { updateUser } from 'utils'
import { ProgressBarFooter } from '../FreelancerRoles/FreelancerAddRole'
import firebase from 'utils/firebase'
import { NUM_ONBOARDING_STEPS } from 'constants'

const FreelancerSelectCity = ({
  onboarding,
  user,
  cities,
  onSubmit = () => { },
  onNext = () => {},
}) => {
  const [state, setState] = useState({ city: null, cityId: null })

  return (
    <Frame maxWidth="xs" user={user}>
      <Box mx="auto" maxWidth={430}>
        <Heading subheading="Choosing a city implies your working area">Choose your City</Heading>

        <Checklist
          buttonLabel="Next"
          items={[...(cities?.map(city => ({ id: city.id, label: city.name })) ?? []), { id: 'other', label: 'Other' }]}
          onChange={([{ label, id }]) => {
            setState({ city: label === 'Other' ? '' : label, cityId: id })
          }}
          onSubmit={
            !onboarding
              ? ({ label, id }) => {
                updateUser(user, { city: label, cityId: id }).then(onSubmit)
                firebase
                  .analytics()
                  .logEvent('user_freelancer_city_submit_click')
              }
              : null
          }
        />

        <Box px={2} mt={-2}>
          {state.cityId === 'other' && <TextField
            label="Other City"
            required
            placeholder="Enter your city"
            value={state.city}
            onChange={(e) => setState(s => ({ ...s, city: e.target.value }))}
          />}
        </Box>
      </Box>

      {onboarding && (
        <ProgressBarFooter
          valid={state.city && state.cityId}
          stepIndex={3}
          firstStep={3}
          numSteps={NUM_ONBOARDING_STEPS}
          onNext={() => {
            updateUser(user, state).then(onSubmit)
          }}
          onPrev={() => { }}
        />
      )}
    </Frame>
  )
}

export default FreelancerSelectCity
