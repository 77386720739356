import React, { useEffect, useState } from 'react'
import { Frame } from 'components'
import Form from './Form'
import List from './List'
import Message from './Message'
import { getUserGroups, useHasActiveSubscription } from 'utils'
import { Redirect } from 'react-router'

export const SubscriberGroupCreate = (props) => {
  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )
  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="md" user={props.user}>
      <Form {...props} />
    </Frame>
  )
}

export const SubscriberGroupEdit = (props) => {
  const [group, setGroup] = useState()

  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )

  useEffect(() => {
    if (!props.match.params.id || group) return
    getUserGroups().then((groups) => {
      const foundGroup = groups.find(
        (group) => group.id === props.match.params.id,
      )
      if (foundGroup) {
        setGroup({
          id: foundGroup.id,
          ...foundGroup,
        })
      } else {
        props.history.push('/groups')
      }
    })
  }, [group, props.history, props.match.params.id])
  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="lg" user={props.user}>
      {group && <>
        <Form defaultValues={group} {...props} />
      </>}
    </Frame>
  )
}

export const SubscriberGroups = (props) => {
  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )
  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="xs" user={props.user}>
      <List {...props} />
    </Frame>
  )
}

export const SubscriberGroupMessage = (props) => {
  const [group, setGroup] = useState()

  const { hasActiveSubscription, isLoading } = useHasActiveSubscription(
    props.user?.id,
  )

  useEffect(() => {
    if (!props.match.params.id || group) return
    getUserGroups().then((groups) => {
      const foundGroup = groups.find(
        (group) => group.id === props.match.params.id,
      )
      if (foundGroup) {
        setGroup({
          id: foundGroup.id,
          ...foundGroup,
        })
      } else {
        props.history.push('/groups')
      }
    })
  }, [group, props.history, props.match.params.id])
  if (!group) return <Frame maxWidth="lg" user={props.user}></Frame>
  if (!isLoading && !hasActiveSubscription) {
    return <Redirect to="/packages" />
  }

  return (
    <Frame maxWidth="lg" user={props.user}>
      <Message group={group} {...props} />
    </Frame>
  )
}
