import React, { useState } from 'react'
import { Chip, Box, Button, DoneIcon, AddIcon } from 'components'

const ChipList = ({
  items,
  value: defaultValue = [],
  onSubmit,
  onChange,
  label = 'Submit',
  max = 10,
}) => {
  const [active, _setActive] = useState(defaultValue)
  const setActive = (value) => {
    _setActive(value)
    onChange && onChange(value)
  }
  return (
    <>
      {items.map((item) => (
        <Chip
          clickable
          key={`item-${item}`}
          color="primary"
          label={item}
          variant={active.includes(item) ? undefined : 'outlined'}
          deleteIcon={active.includes(item) ? <DoneIcon /> : <AddIcon />}
          onDelete={() =>
            setActive(
              active.includes(item)
                ? active.filter((i) => i !== item)
                : active.length < max
                ? [...active, item]
                : active,
            )
          }
          onClick={() =>
            setActive(
              active.includes(item)
                ? active.filter((i) => i !== item)
                : active.length < max
                ? [...active, item]
                : active,
            )
          }
          style={{ margin: '8px' }}
        />
      ))}
      <Box my={3} />

      {onSubmit && (
        <Button
          type="submit"
          flex={1}
          disabled={!active}
          onClick={() => onSubmit(active)}
        >
          {label}
        </Button>
      )}
    </>
  )
}

export default ChipList
