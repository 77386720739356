import React from 'react'
import { Box, Divider as MUIDivider } from '@material-ui/core'

const Divider = ({ variant, my = 2, ...props }) =>
  variant === 'middle' ? (
    <Box my={my} mx="auto" width={200}>
      <MUIDivider />
    </Box>
  ) : (
    <Box my={my} {...props}>
      <MUIDivider />
    </Box>
  )

export default Divider

export const VerticalDivider = ({ mx = 3, height = 32, ml, mr, ...props }) => (
  <Box
    mx={mx}
    ml={typeof ml === 'number' ? ml : mx}
    mr={typeof mr === 'number' ? mr : mx}
    width="1px"
    height={height}
    bgcolor="#ddd"
    {...props}
  />
)
