import React from 'react'
import {
  Box,
  Button as MUIButton,
  ButtonBase,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { CheckIcon } from 'components'
import { palette } from 'theme'

const Button = ({ children, leftIcon = null, rightIcon = null, ...props }) => {
  const classes = useStyles()
  if (props.type === 'submit') {
    props.variant = props.variant || 'contained'
    props.color = props.color || 'primary'
    props.style = props.style || { margin: '16px 0', width: '100%' }
  }

  const base = { fontSize: 16 }
  return (
    <MUIButton disableRipple classes={classes} {...props}>
      {leftIcon &&
        React.cloneElement(leftIcon, {
          style: { ...base, marginRight: 6, marginLeft: -2 },
        })}
      {children}
      {rightIcon &&
        React.cloneElement(rightIcon, {
          style: { ...base, marginLeft: 6, marginRight: -2 },
        })}
    </MUIButton>
  )
}

export default Button

export const Pill = ({
  children,
  size = '',
  onClick,
  style = {},
  textStyle = {},
  count,
  ...props
}) => {
  return (
    <ButtonBase
      disabled={!onClick}
      onClick={onClick}
      disableRipple
      style={style}
    >
      <Box
        className={`pill-button pointer ${size}`}
        style={{
          padding: count && '5px 4px 5px 14px',
        }}
        {...props}
      >
        <Typography
          style={{ ...textStyle, textDecoration: onClick ? '' : 'none' }}
        >
          {children}
        </Typography>
        {count && (
          <Box className="pill-count">
            <Typography>{count}</Typography>
          </Box>
        )}
      </Box>
    </ButtonBase>
  )
}

export const SettingButton = ({ color, onClick, isActive, children }) => (
  <Button
    variant="text"
    align="center"
    color={color}
    endIcon={isActive && <CheckIcon />}
    onClick={onClick}
    style={{ fontSize: 22, margin: 0, textDecoration: 'none' }}
  >
    {children}
  </Button>
)

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    height: 40,
    padding: '10px 20px',
    color: '#696969',
  },
  text: {
    padding: '10px 20px',
    '& span': {
      fontWeight: '600',
    },
    '&:hover': {
      color: '#1D1D1D',
      backgroundColor: 'white',
    },
  },
  outlined: {
    borderColor: '#ddd',
    padding: '10px 20px',
    background: 'transparent',
    '& span': {
      color: '#1D1D1D',
      fontSize: 14,
    },
  },
  contained: {
    border: '1px solid transparent',
    backgroundColor: palette.default.main,
    padding: '10px 20px',
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: palette.default.light,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
    '&:active': {
      backgroundColor: palette.primary.dark,
    },
  },
  sizeSmall: {
    padding: '5px 10px',
    height: 22,
    minWidth: 0,
    '& span': {
      fontSize: 12,
    },
  },
  sizeLarge: {
    borderRadius: 32,
    padding: '16px 14px',
    '& span': {
      fontSize: 16,
      fontWeight: '600',
    },
  },

  label: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textTransform: 'none',
  },
})
