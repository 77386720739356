import React from 'react'
import { Button, MUIDialog, DialogContent, Typography, Box } from 'components'
import { useHistory } from 'react-router'
import { useContent } from 'utils'

const OnboardDialog = ({ open, onClose }) => {
  const history = useHistory()
  const { title } = useContent()
  return (
    <MUIDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ maxWidth: 500, padding: '16px 32px 32px' }}>
        <Typography
          variant="h5"
          color="primary"
          style={{ textAlign: 'center', margin: '12px 0' }}
        >
          Welcome to the Free Version of {title}
        </Typography>
        <Typography>
          Use the Search Bar to search by Role (like Art Director or Producer).
          You can also search by city.
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{ textAlign: 'center', margin: '12px 0' }}
        >
          Or start your 30-Day Free Trial of Full {title}
        </Typography>
        <Typography>
          Enjoy unlimited access to Freelancer portfolios & contact info, search
          by skills and post jobs to the Job Board.
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={4} px={2}>
          <Button
            onClick={onClose}
            style={{ flex: 1, marginRight: 12 }}
            variant="outlined"
          >
            Use Free {title}
          </Button>
          <Button
            onClick={() => {
              onClose()
              history.push('/packages')
            }}
            style={{ flex: 1 }}
            variant="contained"
            color="primary"
          >
            Start 30-Day Free Trial
          </Button>
        </Box>
      </DialogContent>
    </MUIDialog>
  )
}

export default OnboardDialog
