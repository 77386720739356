import * as React from 'react'
import {
  Datagrid,
  List,
  SimpleForm,
  ReferenceArrayInput,
  Create,
  TextInput,
  Edit,
  TextField,
  SelectArrayInput,
  ShowButton,
  Pagination,
  BooleanInput,
} from 'react-admin'

export const IndustryList = (props) => (
  <List
    {...props}
    title="Industries List"
    perPage={100}
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const IndustryEdit = (props) => (
  <Edit {...props} title="Industries Edit" undoable={false}>
    <IndustryForm />
  </Edit>
)

export const IndustryCreate = (props) => (
  <Create {...props} title="Industries Create">
    <IndustryForm />
  </Create>
)

export const IndustryForm = (props) => (
  <SimpleForm {...props}>
    <TextInput fullWidth source="name" />
    <BooleanInput source="disabled" />
    <ReferenceArrayInput
      fullWidth
      label="Roles"
      source="roleIds"
      perPage={500}
      sort={{ field: 'name', order: 'ASC' }}
      reference="admin/roles"
    >
      <SelectArrayInput fullWidth optionText="name" />
    </ReferenceArrayInput>
  </SimpleForm>
)
