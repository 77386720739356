import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  Typography,
  Box,
  QuoteIcon,
  PhoneIcon,
  LinkIcon,
  IconButton,
  StarIcon,
  MailIcon,
  ButtonBase,
  ProfileIcon,
  CloseIcon,
  CvIcon,
  Snackbar,
} from 'components'
import { Tooltip, Avatar, makeStyles } from '@material-ui/core'
import {
  getAvailabilityForDate,
  useTheme,
  onClickUserEmail,
  onClickUserWebsiteLink,
  DEFAULT_MESSAGES,
  toggleHidden,
  useHasActiveSubscription,
} from 'utils'
import { COLORS, CUSTOM_STATIC_LANDER, GROUP_COLORS, AVAILABLE_FROM_CALENDAR, PROFILE_PICTURES_ENABLED, USE_DEMO_LANDER } from 'constants.js'
import PhoneDialog from '../subscriber/PhoneDialog'
import { useIsMobile } from 'utils'
import { isBefore } from 'date-fns'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: '0 1rem 0 0',
  },
}))

export default function ProfileCard({
  date = new Date(),
  allowHide = true,
  profileUser,
  user,
  onGroup,
  hasNote,
  subscriberGroups,
  style = {},
}) {
  const isMobile = useIsMobile()
  const [showLinks, setShowLinks] = useState(isMobile ? true : false)
  const theme = useTheme()

  const getWLAvailability = (user) => {
    if (!user.availableFrom) {
      return {
        status: 0,
        message: DEFAULT_MESSAGES[0],
      }
    }
    if (isBefore(user.availableFrom.toDate(), date)) {
      return {
        status: 1,
        message: DEFAULT_MESSAGES[1],
      }
    } else {
      return {
        status: 2,
        message: DEFAULT_MESSAGES[2],
      }
    }
  }

  const availForDate = getAvailabilityForDate(date, profileUser, {
    defaultMessage: true,
  })
  const avail = !AVAILABLE_FROM_CALENDAR ?
    availForDate || profileUser.latestAvail :
    getWLAvailability(profileUser)

  let color = theme.palette['pastel_' + COLORS[avail.status]].main
  let mainColor = theme.palette[COLORS[avail.status]].main
  const recosCount = profileUser.totalRecommendations || 0
  let statusColor = theme.palette.grey[500]
  if (profileUser.employment) {
    switch (profileUser.employment.status) {
      case 'Full Time':
        statusColor = theme.palette.grey[500];
        break;
      case 'Contract':
        statusColor = theme.palette.grey[700];
        break;
      case 'Freelancer':
        statusColor = theme.palette.primary.main;
        break;
      default:
        statusColor = theme.palette.primary.main;
        break;
    }
  }

  const history = useHistory()

  return (
    <ButtonBase
      disableRipple
      key={profileUser.id}
      className="box"
      component="div"
      onClick={e => {
        // use case issue > Originally this click event handles displaying the profile card buttons on touch events, conflicts with desired CASO behavior
        // possible solution: display the links by default if a user is on a mobile device
        // if (!showLinks) setShowLinks(true)
        e.stopPropagation()
        if (CUSTOM_STATIC_LANDER) history.push(`/freelancers/${profileUser.email}`)
      }}
      onMouseEnter={() => {
        setShowLinks(true)
      }}
      onMouseLeave={() => {
        setShowLinks(false)
      }}
    >
      <Box className="box" style={style}>
        <Box
          className="inner"
          position="relative"
          textAlign="left"
          flexVariant="column space-between"
          style={{
            background: color,
            color: '#1d1d1d',
            padding: '20px 20px',
            boxSizing: 'border-box',
            borderRadius: 4,
          }}
        >
          <ContactLinks
            active={showLinks}
            profileUser={profileUser}
            user={user}
            avail={avail}
            color={color}
            onGroup={onGroup}
            hasNote={hasNote}
            subscriberGroups={subscriberGroups}
            onHide={
              allowHide
                ? (e) => {
                  e?.stopPropagation()
                  toggleHidden(user, profileUser)
                }
                : null
            }
            showLinks={showLinks}
          />

          <>
            <Box>
              <Box mb={1.5} flexVariant="row space-between">
                <ProfileTag
                  display="inline-flex"
                  bgcolor={USE_DEMO_LANDER ? statusColor : mainColor}
                  textColor="#fff"
                >
                  {USE_DEMO_LANDER ? profileUser.employment?.status || 'Unknown' : DEFAULT_MESSAGES[avail.status]}
                </ProfileTag>

                <ProfileIcons groups={subscriberGroups} hasNote={hasNote} />
              </Box>

              <Box mb={1.5}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                >
                  {profileUser.firstName} {profileUser.lastName}
                </Typography>
                {USE_DEMO_LANDER ? (
                  <Typography
                    style={{ marginTop: 8 }}
                    align="left"
                    variant="subtitle1"
                  >
                    {profileUser.city}
                  </Typography>
                ) : (
                  <>
                    {avail.message && user && (
                      <Typography
                        style={{ marginTop: 8 }}
                        align="left"
                        variant="subtitle1"
                      >
                        {avail.message}
                      </Typography>
                    )}
                  </>
                )}

              </Box>

              <Box>
                {Object.values(profileUser.titles).map((title) => (
                  <ProfileTag
                    key={title}
                    display="inline-flex"
                    border="1px solid #1d1d1d"
                    my={0.25}
                    mx={0.25}
                  >
                    {title} · {profileUser.experienceByRole[title]} yrs
                  </ProfileTag>
                ))}
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box>
                {recosCount > 0 && (
                  <Box flexVariant="row align-center">
                    <QuoteIcon style={{ fontSize: 20 }} />
                    <Typography variant="subtitle1">
                      {recosCount} recos
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </ButtonBase>
  )
}

const ContactButton = React.forwardRef(function ContactButton(props, ref) {
  const history = useHistory()
  const { onClick, link, Icon, disabled } = props
  if (link) {
    return (
      <a
        {...props}
        ref={ref}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault()
            history.push('/packages')
          }
        }}
        target="_blank"
        rel="noreferrer"
        href={link}
      >
        <IconButton style={{ padding: 8, backgroundColor: 'black', margin: 8 }}>
          <Icon style={{ fontSize: 24, fill: 'white' }} />
        </IconButton>
      </a>
    )
  }
  return (
    <div
      {...props}
      ref={ref}
    >
      <IconButton

        onClick={(e) => {
          e.stopPropagation()
          if (disabled) {
            history.push('/packages')
          } else {
            onClick()
          }
        }}
        style={{ padding: 8, backgroundColor: 'black', margin: 8 }}
      >
        <Icon style={{ fontSize: 24, fill: 'white' }} />
      </IconButton>
    </div>
  )
})

const ContactLinks = ({
  active,
  user,
  avail,
  color,
  profileUser,
  onHide,
  onGroup,
  subscriberGroups,
  hasNote,
  showLinks,
}) => {
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false)
  const [cvSnackOpen, setCvSnackOpen] = useState(false)

  const theme = useTheme()
  const classes = useStyles()
  const FaveIcon = StarIcon
  let mainColor = theme.palette[COLORS[avail.status]].main

  const { hasActiveSubscription } = useHasActiveSubscription(user?.id)

  const isDisabled = !hasActiveSubscription

  let statusColor = theme.palette.grey[500]
  if (profileUser.employment) {
    switch (profileUser.employment.status) {
      case 'Full Time':
        statusColor = theme.palette.grey[500];
        break;
      case 'Contract':
        statusColor = theme.palette.grey[700];
        break;
      case 'Freelancer':
        statusColor = theme.palette.primary.main;
        break;
      default:
        statusColor = theme.palette.primary.main;
        break;
    }
  }

  return (
    <Box
      position="absolute"
      flex={1}
      flexVariant="column"
      style={{
        inset: 0,
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'auto' : 'none',
        transition: 'opacity 200ms',
      }}
      bgcolor={color}
    >
      <Snackbar autoHideDuration={10} severity='warning' message='No CV Available' open={cvSnackOpen} onClose={() => setCvSnackOpen(false)} />
      <Box mx={2.5} mt={2.5}>
        <Box mb={1.5} flexVariant="row space-between">
          <ProfileTag
            display="inline-flex"
            bgcolor={USE_DEMO_LANDER ? statusColor : mainColor}
            textColor={profileUser.employment?.status === 'Full Time' ? '#000' : '#fff'}
          >
            {USE_DEMO_LANDER ? profileUser.employment?.status || 'Unknown' : DEFAULT_MESSAGES[avail.status]}
          </ProfileTag>

          <ProfileIcons groups={subscriberGroups} hasNote={hasNote} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
          >
            {profileUser.firstName} {profileUser.lastName}
          </Typography>
        </Box>
      </Box>

      <Box flex={1} flexVariant="center">
        <Box display='flex' flexDirection='row' width='90%' height='75%'>
          {PROFILE_PICTURES_ENABLED && showLinks && (
            <Avatar className={classes.avatar} variant='square' src={profileUser.avatarUrl || ''} />
          )}
          <Box flex="initial" flexVariant="center" flexWrap="wrap" width={180}>
            <PhoneDialog
              open={phoneDialogOpen}
              user={profileUser}
              onClose={() => setPhoneDialogOpen(false)}
            />
            {!PROFILE_PICTURES_ENABLED && (
              <>
                {CUSTOM_STATIC_LANDER ? (
                  <Tooltip title="View CV" arrow>
                    <ContactButton
                      disabled={isDisabled}
                      link={profileUser.cvUrl ? `${profileUser.cvUrl}` : null}
                      onClick={!profileUser.cvUrl ? e => {
                        setCvSnackOpen(true)
                      } : null}
                      Icon={CvIcon}
                    />
                  </Tooltip>
                ) :
                  <ContactButton
                    disabled={isDisabled}
                    link={`/freelancers/${profileUser.email}`}
                    Icon={ProfileIcon}
                  />
                }
              </>
            )}

            <Tooltip title="Call/Text" arrow>
              <ContactButton
                disabled={isDisabled}
                onClick={(e) => setPhoneDialogOpen(true)}
                stopPropagation
                Icon={PhoneIcon}
              />
            </Tooltip>
            <Tooltip title="LinkedIn" arrow>
              <ContactButton
                disabled={isDisabled}
                onClick={(e) => onClickUserWebsiteLink(profileUser.website)}
                Icon={LinkIcon}
              />
            </Tooltip>
            <Tooltip title="Email" arrow>
              <ContactButton
                disabled={isDisabled}
                onClick={(e) => onClickUserEmail(profileUser)}
                Icon={MailIcon}
              />
            </Tooltip>
            {onGroup && (
              <Tooltip title="Favorite" arrow>
                <ContactButton
                  disabled={isDisabled}
                  onClick={(e) => onGroup()}
                  Icon={FaveIcon}
                />
              </Tooltip>
            )}
            {(!PROFILE_PICTURES_ENABLED && onHide) && (
              <Tooltip title="Hide" arrow>
                <ContactButton
                  disabled={isDisabled}
                  onClick={onHide}
                  Icon={CloseIcon}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ProfileTag = ({ children, textColor = '#1d1d1d', ...props }) => (
  <Box
    height={22}
    flex={0}
    borderRadius={4}
    maxWidth="100%"
    flexVariant="justify-start align-center"
    {...props}
    px={1}
  >
    <Typography
      style={{
        fontSize: 12,
        lineHeight: '15px',
        color: textColor,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {children}
    </Typography>
  </Box>
)

export const ProfileIcons = ({ groups = [], hasNote = false }) => {
  return (
    <Box display="flex">
      {hasNote && <NoteIcon />}
      {groups?.slice(0, 3).map((group) => {
        const shape = group?.id !== 'favorites' ? 'circle' : 'star'
        const color = GROUP_COLORS[group?.color] || '#ECB22E'
        return (
          <Box
            key={group?.id}
            width={22}
            height={22}
            ml={0.5}
            bgcolor="black"
            borderRadius="50%"
            flexVariant="center"
          >
            {shape === 'star' ? (
              <StarIcon style={{ fontSize: 14, fill: color }} />
            ) : (
              <Box
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 100,
                  background: color,
                }}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

const NoteIcon = () => (
  <svg
    enableBackground="new 0 0 45.044 45.044"
    version="1.1"
    viewBox="0 0 45.044 45.044"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
  >
    <path d="m36.54 26.777c0 1.228-0.996 2.224-2.224 2.224h-7.6l-3.107 15.153c-0.104 0.519-0.561 0.89-1.087 0.89s-0.982-0.371-1.088-0.89l-3.107-15.153h-7.6c-1.227 0-2.223-0.996-2.223-2.224 0-3.379 1.955-6.405 5.021-8.425v-6.631h-2.066c-1.227 0-2.222-0.995-2.222-2.223v-7.274c0-1.228 0.995-2.224 2.222-2.224h22.125c1.229 0 2.223 0.996 2.223 2.224v7.274c0 1.228-0.994 2.223-2.223 2.223h-2.064v6.631c3.065 2.02 5.02 5.046 5.02 8.425z" />
  </svg>
)
