import React from 'react'
import { Typography, Divider } from 'components'

const Heading = ({ children, subheading }) => (
  <>
    <Typography align="center" variant="h3">
      {children}
    </Typography>

    {subheading && (
      <Typography align="center" style={{ marginTop: 6, marginBottom: 30 }}>
        {subheading}
      </Typography>
    )}

    <Divider my={0} mt={3} mb={2} />
  </>
)

export default Heading
