import React from 'react'
import {
  Snackbar as MUISnackbar,
  SnackbarContent,
  IconButton,
} from '@material-ui/core'
import { CloseIcon, Typography } from 'components'
import { makeStyles } from 'utils'

const Snackbar = ({ message, open, onClose, variant = 'success' }) => {
  const classes = useStyles()
  return (
    <MUISnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        message={
          <Typography style={{ color: 'white' }} variant="body1">
            {message}
          </Typography>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </MUISnackbar>
  )
}

export default Snackbar

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: { fontSize: 20 },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))
