import React from 'react'
import { Box, Typography, Button, TextField } from 'components'
import { useUserNotes } from 'utils'

export default function FreelancerProfileNotesSection({ freelancer }) {
  const { note, setNote, onSave, hasChanges } = useUserNotes(freelancer.id)

  return (
    <Box
      maxWidth={420}
      boxSizing="border-box"
      border="1px solid #DDD"
      borderRadius={10}
      py={2}
      mb={3}
      px={3}
    >
      <Box flexVariant="space-between align-center">
        <Typography variant="h5" style={{ fontWeight: '600' }}>
          Notes
        </Typography>

        <Button
          disabled={!hasChanges}
          variant="contained"
          color="primary"
          size="small"
          onClick={onSave}
        >
          Add/Edit
        </Button>
      </Box>

      <TextField
        required
        multiline
        rows={note ? 5 : 1}
        showCharacterCount={note}
        maxLength={400}
        value={note}
        onChange={(e) =>
          e.target.value.length <= 400 && setNote(e.target.value)
        }
        placeholder="Only seen by those sharing your account"
        name="notes"
        style={{ width: '100%' }}
      />
    </Box>
  )
}
