import React from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  FacebookIcon,
  LinkedInIcon,
} from 'components'
import { useContent } from 'utils'
import { CUSTOM_STATIC_LANDER } from 'constants'

export default function Footer({ user, history }) {
  let type = user ? 'auth' : 'unauth'
  const { domain, facebookURL, contactEmail, linkedInUrl } = useContent()
  const BUTTONS = {
    auth: [
      { label: 'Contact Us', url: `mailto:${contactEmail}` },
      { label: 'Terms of Service', url: '/terms' },
    ],
    unauth: [
      !CUSTOM_STATIC_LANDER && { label: 'About', url: '/about' },
      !CUSTOM_STATIC_LANDER && { label: 'FAQ', url: '/faq' },
      { label: 'Log In', url: '/login' },
    ].filter(Boolean),
  }

  const buttons = BUTTONS[type]
  return (
    <Box>
      <Box alignSelf="stretch" bgcolor="#fff" borderTop="1px solid #ddd">
        <Box
          display="flex"
          py={2}
          maxWidth={1250}
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box display="flex" alignItems="center">
            {buttons.map((b) => (
              <Button
                key={b.label}
                onClick={() => {
                  if (/^mailto/.test(b.url)) {
                    window.open(b.url.replace('crral.com', domain))
                  } else {
                    history.push(b.url)
                  }
                }}
              >
                {b.label}
              </Button>
            ))}
          </Box>
          <Box>
            <IconButton onClick={() => window.open(facebookURL, '_blank')} >
              <FacebookIcon />
            </IconButton>
            <IconButton onClick={() => window.open(linkedInUrl, '_blank')} >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box alignSelf="stretch" bgcolor="#fff" borderTop="1px solid #ddd">
        <Box
          display="flex"
          py={4}
          maxWidth={1250}
          mx="auto"
          justifyContent="center"
        >
          <Typography variant="subtitle1" style={{ color: '#333' }}>
            © {new Date().getFullYear()} {domain.toUpperCase()}
          </Typography>
          <Box></Box>
        </Box>
      </Box>
    </Box>
  )
}

