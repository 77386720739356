import React from 'react'
import {
  Frame,
  Button,
  TextField,
  Heading,
  Divider,
  Typography,
} from 'components'
import { createUser, getFormValues } from 'utils'
import firebase from 'utils/firebase'

const SubscriberCreateView = ({ user }) => {
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Sign up now.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          firebase.analytics().logEvent('user_subscriber_details_submit_click')
          await createUser({ ...getFormValues(event), role: 'subscriber' })
        }}
      >
        <TextField required name="firstName" label="First Name" />
        <TextField required name="lastName" label="Last Name" />
        <TextField required name="email" label="Email Address" />
        <TextField required name="password" type="password" label="Password" />

        <Button type="submit">Sign up</Button>
      </form>

      <Typography>
        By signing up for an account, you agree to the{' '}
        <Typography component="a" href="/terms">
          Terms of service
        </Typography>
        .
      </Typography>

      <Divider />

      <Typography>
        Already have an account?{' '}
        <Typography component="a" href="/login">
          Sign in here
        </Typography>
      </Typography>
    </Frame>
  )
}

export default SubscriberCreateView
