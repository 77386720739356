import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components'
import { updateFlags } from 'utils'

const FlagDialog = ({ user, flaggedUser, open, onClose, onSubmit }) => {
  const [value, setValue] = useState('not-in-region')
  const [other, setOther] = useState('')
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Thanks for bringing this to our attention. Why are you flagging this Profile for removal?
      </DialogTitle>

      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="reason"
            name="reason"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="not-in-region"
              control={<Radio color="primary" />}
              label="Doesn't work in this region"
            />
            <FormControlLabel
              value="bad-skills"
              control={<Radio color="primary" />}
              label="Misrepresenting their skills"
            />
            <FormControlLabel
              value="inappropriate"
              control={<Radio color="primary" />}
              label="Inappropriate account"
            />
            <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label="Other"
            />
          </RadioGroup>
          {value === 'other' && (
            <TextField
              label="Other"
              placeholder="Reason for flagging"
              value={other}
              onChange={(e) => setOther(e.target.value)}
            />
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            if (value === 'other' && !other) {
              return
            }

            updateFlags(user, flaggedUser, {
              value,
              otherReason: value === 'other' ? other : undefined,
            })

            onSubmit()
          }}
          color="primary"
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}

export default FlagDialog
