import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  BooleanInput,
  Edit,
  SimpleForm,
  TextField,
  SelectInput,
  TextInput,
  Filter,
  ShowButton,
  FunctionField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Button,
  downloadCSV,
  CreateButton,
  ExportButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanField,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { slugify, makeStyles, freelancersAgg, jobBoardEmail } from 'utils'
import { format } from 'date-fns'
import jsonExport from 'jsonexport/dist'
import { GENDER_TAGS_ENABLED, DIVERSITY_TAGS_ENABLED } from 'constants'

const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const { firstName, lastName, email, phoneNumber } = user
    return { firstName, lastName, email, phoneNumber }
  })
  jsonExport(usersForExport, (err, csv) => {
    downloadCSV(csv, 'users') // download as 'posts.csv` file
  })
}

const useStyles = makeStyles({
  flex: {
    width: '50%',
    display: 'inline-flex',
    paddingRight: '1rem',
    boxSizing: 'border-box',
  },
})

const UserFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="User Type"
      choices={[
        { id: 'subscriber', name: 'subscriber' },
        { id: 'freelancer', name: 'freelancer' },
      ]}
      source="role"
      alwaysOn
      allowEmpty={false}
      style={{ margin: 10 }}
    />
    <SelectArrayInput
      label="Industries"
      choices={[
        { id: 'sJL0zJ3pz13ypam8R88P', name: 'Advertising/Marketing' },
        { id: 'XPSsVvwHKvtZKcuF1YFo', name: 'Film/Video Production' },
        { id: 'HPW577zdvJp4zmWAWzoq', name: 'Web Development' },
      ]}
      source="industryIds"
      alwaysOn
      allowEmpty={false}
      style={{ margin: 10, minWidth: 150 }}
    />
  </Filter>
)

const generateRole = (name) => ({
  id: {
    [`advertising-marketing-${slugify(name)}`]: {
      label: name,
      experience: 15,
      industry: 'Advertising/Marketing',
      skills: ['Conceptual', 'Digital'],
    },
  },
  name: name,
})

export const UserList = (props) => (
  <List
    {...props}
    exporter={exporter}
    filters={<UserFilter />}
    title="Users List"
    perPage={100}
    // sort={{ field: 'email', order: 'ASC' }}
    actions={<Actions />}
  >
    <Datagrid>
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="city" />
      {props.location.search.match(/subscriber/) && <BooleanField source="paying" />}
      {props.location.search.match(/freelancer/) && <ReferenceArrayField label="Industries" reference="admin/industries" source="industryIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>}

      <FunctionField
        label="Created at"
        source="createdAt.seconds"
        sortBy="createdAt.seconds"
        render={(record) =>
          record.createdAt
            ? format(
              new Date(record.createdAt.seconds * 1000),
              'yyyy-MM-dd HH:mm',
            )
            : ''
        }
      />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const UserEdit = (props) => {
  const c = useStyles()

  return (
    <Edit {...props} title="Users Edit" undoable={false}>
      <SimpleForm>
        <TextInput fullWidth source="email" formClassName={c.flex} />
        <SelectInput
          formClassName={c.flex}
          fullWidth
          source="role"
          choices={[
            {
              id: 'freelancer',
              name: 'Freelancer',
            },
            {
              id: 'subscriber',
              name: 'Subscriber',
            },
          ]}
        />
        <TextInput fullWidth source="firstName" formClassName={c.flex} />
        <TextInput fullWidth source="lastName" formClassName={c.flex} />
        <TextInput fullWidth source="phoneNumber" formClassName={c.flex} />
        <TextInput fullWidth source="website" formClassName={c.flex} />
        <TextInput fullWidth source="secondaryWebsite" formClassName={c.flex} />
        <TextInput fullWidth source="tertiaryWebsite" formClassName={c.flex} />
        <TextInput fullWidth source="about" formClassName={c.flex} />
        <TextInput fullWidth source="cvUrl" formClassName={c.flex} />
        <TextInput fullWidth source="avatarUrl" formClassName={c.flex} />
        <BooleanInput fullWidth source="resident" formClassName={c.flex} />
        <BooleanInput fullWidth source="paying" formClassName={c.flex} />
        {GENDER_TAGS_ENABLED && (
          <ReferenceArrayInput
            fullWidth
            label="Gender Tags"
            source="genderIds"
            perPage={500}
            sort={{ field: 'name', order: 'ASC' }}
            reference="admin/gendertags"
          >
            <SelectArrayInput fullWidth optionText="name" />
          </ReferenceArrayInput>
        )}
        {DIVERSITY_TAGS_ENABLED && (
          <ReferenceArrayInput
            fullWidth
            label="Diversity Tags"
            source="diversityIds"
            perPage={500}
            sort={{ field: 'name', order: 'ASC' }}
            reference="admin/diversitytags"
          >
            <SelectArrayInput fullWidth optionText="name" />
          </ReferenceArrayInput>
        )}
      </SimpleForm>
    </Edit>
  )
}

export const UserCreate = (props) => {
  const c = useStyles()
  return (
    <Create {...props} title="Users Create">
      <SimpleForm
        initialValues={{
          email: 'email',
          role: 'freelancer',
          firstName: 'name',
          lastName: 'name',
          phoneNumber: '',
          website: '',
          fake: true,
        }}
      >
        <TextInput fullWidth source="email" formClassName={c.flex} />
        <SelectInput
          formClassName={c.flex}
          fullWidth
          source="role"
          choices={[
            { id: 'freelancer', name: 'Freelancer' },
            { id: 'subscriber', name: 'Subscriber' },
          ]}
        />
        <TextInput fullWidth source="firstName" formClassName={c.flex} />
        <TextInput fullWidth source="lastName" formClassName={c.flex} />
        <TextInput fullWidth source="phoneNumber" formClassName={c.flex} />
        <TextInput fullWidth source="website" formClassName={c.flex} />
        <BooleanInput fullWidth source="fake" formClassName={c.flex} />
        <ReferenceArrayInput
          formClassName={c.flex}
          fullWidth
          source="city"
          reference="admin/cities"
          perPage={500}
          sort={{ field: 'name', order: 'ASC' }}
          label="City"
        >
          <SelectInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          formClassName={c.flex}
          fullWidth
          source="roles"
          choices={[
            generateRole('Art Director'),
            generateRole('Copywriter'),
            generateRole('Graphic Designer'),
          ]}
        />
        <SelectInput
          formClassName={c.flex}
          fullWidth
          source="availability"
          choices={[
            {
              id: {
                '2020-01-16': {
                  indefinite: true,
                  message: 'Available for projects, large or small',
                  status: 1,
                },
              },
              name: 'Available',
            },
            {
              id: {
                '2020-01-16': {
                  indefinite: true,
                  message: 'On vacation',
                  status: 0,
                },
              },
              name: 'Unavailable',
            },
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

const Actions = (props) => {
  const { className, filters, maxResults, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        onClick={() => {
          const confirmed = window.confirm(
            'This will refresh the freelancer aggregates. This can be an expensive operation. Are you sure you want to continue?',
          )
          if (confirmed) freelancersAgg()
        }}
        label="Refresh Aggregates"
      ></Button>
      <Button
        label="Job Board Email"
        onClick={() => {
          const confirmed = window.confirm(
            'This will send the job board email. Are you sure you want to continue?',
          )
          if (confirmed) jobBoardEmail()
        }}
      ></Button>
    </TopToolbar>
  )
}
