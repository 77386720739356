import React from 'react'
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const Dialog = ({
  body,
  title,
  open,
  onClose,
  onClick,
  secondaryText,
  onSecondaryClick,
  confirmText = 'Okay',
  props
}) => (
  <MUIDialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {body}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        id="dialog-primary"
        variant="contained"
        onClick={onClick || onClose}
        color="primary"
        autoFocus
        {...props}
      >
        {confirmText}
      </Button>

      {secondaryText && (
        <Button
          id="dialog-secondary"
          variant="contained"
          onClick={onSecondaryClick}
          color="primary"
          autoFocus
        >
          {secondaryText}
        </Button>
      )}
    </DialogActions>
  </MUIDialog>
)

export default Dialog
