import React from 'react'
import { StaticLander } from './StaticLander'
import { GenericStaticLander } from './GenericStaticLander'
import { MarketsView } from '../components/subscriber/MarketsView'
import { useHasActiveSubscription } from 'utils'
import { Loader } from 'components'
import { CUSTOM_STATIC_LANDER } from 'constants'

const HomeView = ({ user, ...props }) => {
  const { isLoading } = useHasActiveSubscription(user?.id)

  if (isLoading) return <Loader />
  if (!user && !CUSTOM_STATIC_LANDER) return <StaticLander />
  if (!user && CUSTOM_STATIC_LANDER) return <GenericStaticLander />

  return <MarketsView user={user} {...props} />
}

export default HomeView
