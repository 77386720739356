import React, { useState } from 'react'
import {
  Button,
  TextField,
  SelectField,
  Slider,
  Box,
  TagsForm,
  Heading,
} from 'components'
import {
  createJobBoardPost,
  decorateJobBoardPost,
  editJobBoardPost,
  getCitiesFromMarkets,
  getIndustriesFromMarkets,
  getRolesFromMarkets,
  slugify,
  useTags,
} from 'utils'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { InputLabel } from '@material-ui/core'
import { addMonths, addWeeks, subDays } from 'date-fns'
import JobCard from 'components/freelancer/JobCard'
import { CUSTOM_STATIC_LANDER } from 'constants'

const JobBoardForm = ({
  defaultValues = JOB_BOARD_INITIAL_STATE,
  markets = {},
  user,
  history,
}) => {
  const [formState, _setFormState] = useState({
    email: user.email,
    ...defaultValues,
  })
  const setFormState = (key) => (v) =>
    _setFormState((formState) => ({ ...formState, [key]: v }))

  const [step, setStep] = useState(0)
  const [isPreview, setIsPreview] = useState(false)
  const cities = getCitiesFromMarkets(markets)
  const industries = getIndustriesFromMarkets(markets)
  const roles = getRolesFromMarkets(markets).filter((r) =>
    formState.industryId ? r.industryId === formState.industryId : true,
  )
  const tags = useTags()

  const onPrev = () => setStep((s) => s - 1)

  const onSubmit = async (event) => {
    event.preventDefault()
    if (step < 2) {
      setStep((s) => s + 1)
    } else if (formState.id) {
      if (!isPreview) return setIsPreview(true)
      await editJobBoardPost(formState, user)
      history.push('/job_board/list')
    } else {
      if (!isPreview) return setIsPreview(true)
      await createJobBoardPost(formState, user)
      history.push('/job_board/list')
    }
  }
  const maxDate = !CUSTOM_STATIC_LANDER ?addMonths(new Date(), 6) : addMonths(new Date(), 18)

  if (isPreview) {
    const cities = getCitiesFromMarkets(markets)
    const roles = getRolesFromMarkets(markets)
    const industries = getIndustriesFromMarkets(markets)
    const post = decorateJobBoardPost(formState, cities, roles, industries)

    return (
      <Box>
        <Heading>Check your post</Heading>
        <Box width={250} className="box-wrap" display="flex" mx="auto">
          <JobCard jobPost={post} />
        </Box>
        <Box width={280} display="flex" mt={4} mx="auto">
          <Button
            variant="outlined"
            color="gray"
            onClick={() => setIsPreview(false)}
            style={{ flex: 1, marginRight: 16 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            style={{ flex: 1 }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Heading>{formState.id ? 'Edit' : 'Create New'} Job Post</Heading>

      <form onSubmit={onSubmit}>
        {step === 0 && (
          <>
            <TextField
              required
              value={formState.title}
              onChange={(e) => setFormState('title')(e.target.value)}
              name="title"
              placeholder="Animator for TV Series"
              label="Post Title *"
            />
            <TextField
              required
              multiline
              rows={3}
              showCharacterCount
              maxLength={120}
              value={formState.description}
              onChange={(e) =>
                setFormState('description')(e.target.value.substring(0, 120))
              }
              name="description"
              label="Description *"
              placeholder="Include big picture info like hours per week, the team, the style"
              style={{ padding: 0 }}
            />
            <TextField
              required
              value={formState.company}
              onChange={(e) => setFormState('company')(e.target.value)}
              name="company"
              label="Company *"
              placeholder=""
            />
            <TextField
              required
              value={formState.email}
              onChange={(e) => setFormState('email')(e.target.value)}
              name="email"
              label="Contact Email *"
              placeholder=""
            />

            <SelectField
              required
              value={formState.industryId}
              name="industry"
              label="Industry"
              onChange={(e) => setFormState('industryId')(e.target.value)}
              choices={industries.map(({ id, label }) => ({
                label,
                value: id,
              }))}
            />

            <SelectField
              required
              disabled={!formState.industryId}
              name="role"
              label="Role"
              value={formState.roleId}
              onChange={(e) => setFormState('roleId')(e.target.value)}
              choices={roles.map(({ roleId, label }) => ({
                label,
                value: roleId,
              }))}
            />

            <InputLabel shrink>Years of Experience *</InputLabel>
            <Box display="flex" alignItems="center">
              <TextField
                value={`${formState.experience[0]} - ${formState.experience[1]}`}
                style={{ width: 80, marginRight: 15 }}
              />

              <Slider
                value={formState.experience}
                min={1}
                max={20}
                onChange={(e, value) => setFormState('experience')(value)}
              />
            </Box>
          </>
        )}

        {step === 1 && (
          <>
            <Box display="flex" flex={1}>
              <SelectField
                required
                flex={1}
                name="workingStyle"
                value={formState.workingStyle}
                onChange={(e) => setFormState('workingStyle')(e.target.value)}
                label="Remote?"
                choices={['In Office', 'Remote', 'In-Office/Remote'].map(
                  (string) => ({
                    label: string,
                    value: slugify(string),
                  }),
                )}
              />
              <Box mx={1} />
              <SelectField
                required={formState.workingStyle !== 'remote'}
                flex={1}
                name="city"
                label="City"
                value={formState.cityId}
                onChange={(e) => setFormState('cityId')(e.target.value)}
                choices={cities.map(({ id, label }) => ({
                  label,
                  value: id,
                }))}
              />
            </Box>

            <Box display="flex" flex={1}>
              <SelectField
                flex={1}
                required
                name="commitment"
                label="What's the time commitment?"
                value={formState.commitment}
                onChange={(e) => setFormState('commitment')(e.target.value)}
                choices={COMMITMENT_CHOICES.map((string) => ({
                  label: string,
                  value: slugify(string),
                }))}
              />
            </Box>

            <Box display="flex" flex={1}>
              <TextField
                value={formState.payAmount}
                onChange={(e) => setFormState('payAmount')(e.target.value)}
                placeholder="Optional"
                name="pay"
                label="Pay"
                wrapperProps={{ flex: 1 }}
              />
              <Box mx={1} />
              <SelectField
                flex={1}
                name="rate"
                label="Billing Style"
                value={formState.payRate}
                onChange={(e) => setFormState('payRate')(e.target.value)}
                choices={RATE_CHOICES.map((string) => ({
                  label: string,
                  value: slugify(string),
                }))}
              />
            </Box>

            <Box display="flex" flex={1}>
              <KeyboardDatePicker
                required
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start_date"
                label="Start Date"
                minDate={subDays(new Date(), 7)}
                maxDate={subDays(maxDate, 7)}
                value={formState.startDate}
                onChange={(v) => {
                  setFormState('startDate')(v)
                  if (v > formState.endDate) {
                    setFormState('endDate')(v)
                  }
                }}
              />
              <Box mx={1} />

              <KeyboardDatePicker
                required
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="end_date"
                label="End Date"
                minDate={new Date()}
                maxDate={maxDate}
                value={formState.endDate}
                onChange={(v) => {
                  setFormState('endDate')(v)
                  if (v < formState.startDate) {
                    setFormState('startDate')(v)
                  }
                }}
              />
            </Box>
          </>
        )}

        {step === 2 && (
          <TagsForm
            search
            max={3}
            role={roles.find((r) => r.roleId === formState.roleId).label}
            industry={
              industries.find((i) => i.id === formState.industryId).label
            }
            tags={tags}
            value={formState.skills}
            onChange={({ skills }) => setFormState('skills')(skills)}
          />
        )}

        <Box display="flex" mt={4}>
          {step > 0 && (
            <Button
              variant="contained"
              color="gray"
              onClick={onPrev}
              style={{ flex: 1, marginRight: 16 }}
            >
              Prev
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ flex: 1 }}
          >
            {step < 2 ? 'Next' : 'Submit'}
          </Button>
        </Box>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default JobBoardForm

const COMMITMENT_CHOICES = !CUSTOM_STATIC_LANDER ?[
  'Small project',
  'Large project',
  'Some of your time',
  'All of your time',
  'Full time job',
] : 
[
  'Contract: Full Time',
  'Contract: Part Time',
  'Freelance',
]

const RATE_CHOICES = ['', 'per Hour', 'per Day', 'per Week', 'Flat Rate']

const JOB_BOARD_INITIAL_STATE = {
  title: '',
  description: '',
  roleId: '',
  cityId: '',
  company: '',
  industryId: '',
  workingStyle: 'remote',
  payAmount: '',
  commitment: '',
  payRate: '',
  experience: [1, 20],
  startDate: new Date(),
  endDate: addWeeks(new Date(), 1),
  skills: [],
}
