import React, { useState, useEffect } from 'react'
import { Divider, Box, Typography, Pill, Button, TextField } from 'components'
import { Popover } from '@material-ui/core'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import firebase from 'utils/firebase'
import { DEMO_REEL_ENABLED } from 'constants'

export default function FreelancerExpertiseSection({ freelancer, onEdit }) {
  const [demoPopoverOpen, setDemoPopoverOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [demoUrl, setDemoUrl] = useState('')
  const ref = firebase.storage().ref(`/cvs/${freelancer.id}`)
  const setUser = (data) =>
    firebase
      .firestore()
      .collection('users')
      .doc(freelancer.id)
      .set(data, { merge: true })

  const onDelete = () => {
    const shouldDelete = window.confirm(
      'Are you sure you want to remove your CV?',
    )
    if (!shouldDelete) return
    ref.delete().then(() => setUser({ cvUrl: null }))
  }

  const onUpload = (e) =>
    ref
      .put(e.target.files[0])
      .then((a) => a.ref.getDownloadURL().then((cvUrl) => setUser({ cvUrl })))

  const handleDemoReel = e => {
    if (demoPopoverOpen) {
      setUser({ demoUrl: demoUrl })
      setDemoPopoverOpen(false)
      return
    }
    if (!!freelancer.demoUrl) {
      setDemoUrl('')
      setUser({ demoUrl: null })
      return
    } else {
      setDemoUrl('')
      setDemoPopoverOpen(true)
      setPopoverAnchor(e.target)
    }
  }

  return (
    <Box flex={1} pt={3.5} px={3.5}>
      <Box flex={1} flexVariant="row space-between" mb={2.5}>
        <Typography variant="h4" style={{ fontWeight: '600' }}>
          Expertise
        </Typography>
        <Box>
          {onEdit && (
            <>
              {DEMO_REEL_ENABLED && (
                <Pill size="small" onClick={handleDemoReel} style={{ margin: '0px 8px' }}>
                  {!!freelancer.demoUrl ? 'Remove Demo Reel URL' : 'Add Demo Reel URL'}
                </Pill>
              )}
              <Popover
                open={demoPopoverOpen}
                onClose={() => setDemoPopoverOpen(false)}
                anchorEl={popoverAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Box padding={1}>
                  <TextField onChange={e => setDemoUrl(e.target.value)} style={{ width: '100%' }} label="Enter Demo Reel URL" placeholder="https://www.youtube.com/watch?v=QH2-TGUlwu4" />
                  <Button variant="contained" color="primary" size="small" onClick={handleDemoReel}>Save</Button>
                  <Button color="secondary" size="small" onClick={() => setDemoPopoverOpen(false)}>Cancel</Button>
                </Box>
              </Popover>
              {!freelancer.cvUrl && (
                <input
                  type="file"
                  accept="application/pdf"
                  id="file"
                  style={{ display: 'none' }}
                  onChange={onUpload}
                />
              )}
              <Pill
                component="label"
                for="file"
                size="small"
                onClick={freelancer.cvUrl ? onDelete : () => { }}
              >
                {freelancer.cvUrl ? 'Remove CV' : 'Upload CV'}
              </Pill>
            </>
          )}

          {onEdit && (
            <Pill size="small" onClick={onEdit} style={{ margin: '0px 8px' }}>
              Manage Roles
            </Pill>
          )}

          {onEdit && (
            <Pill size="small" onClick={onEdit}>
              Add Links to Tags
            </Pill>
          )}
          {!onEdit && freelancer.demoUrl && (
            <Button
              variant='contained'
              color="primary"
              component="a" target="_blank"
              href={freelancer.demoUrl}
              size="small"
              padding={14}
            >
              View Demo Reel
            </Button>
          )}
          {!onEdit && freelancer.cvUrl && (
            <Button
              variant="contained"
              color="primary"
              component="a"
              target="_blank"
              size="small"
              style={{ padding: 14 }}
              href={freelancer.cvUrl}
            >
              Download CV
            </Button>
          )}
        </Box>
      </Box>

      {Object.values(freelancer.roles || {}).map((role, i) => (
        <Box key={role.label + i} mt={2}>
          <Box flexVariant="row align-center">
            <WorkOutlineIcon style={{ fill: '#696969', fontSize: 18 }} />

            <Typography
              style={{ marginLeft: 8, color: 'black', fontWeight: '600' }}
            >
              {role.label} - {role.experience} years
            </Typography>
          </Box>

          <Box my={2} />

          <Box flexVariant="row align-center" flexWrap="wrap">
            {role.skills
              .sort((a, b) => {
                if (role.links && role.links[a] && role.links[b])
                  return a.localeCompare(b)
                if (role.links && role.links[a]) return -1
                if (role.links && role.links[b]) return 1
                return 0
              })
              .map((skill) => (
                <SkillPill
                  role={role}
                  skill={skill}
                  onClick={
                    role.links && role.links[skill]
                      ? () => {
                        window.open(role.links[skill].startsWith('http') || role.links[skill].startsWith('https') ? (role.links[skill]) : `https://${role.links[skill]}`, '_blank')
                      }
                      : null
                  }
                />
              ))}
          </Box>

          <Divider />
        </Box>
      ))}
    </Box>
  )
}

export const SkillPill = ({ role, skill, onClick }) => (
  <Pill
    key={skill}
    bgcolor={role.links && role.links[skill] ? '#000000' : 'white'}
    style={{ marginRight: 8, marginBottom: 8 }}
    textStyle={{ color: role.links && role.links[skill] ? '#ffffff' : '#111' }}
    onClick={onClick}
  >
    {skill}
  </Pill>
)
