import { firebase } from 'utils'
import debounce from 'lodash/debounce'
import {
  format,
  parse,
  isAfter,
  eachDayOfInterval,
  isWeekend,
  addDays,
  subDays,
  addBusinessDays,
  subBusinessDays,
} from 'date-fns'
import { COLORS } from 'constants.js'

export const DEFAULT_MESSAGES = [
  'Available',
  'Available',
  'Unavailable',
  'It depends',
]

export const getAvailabilityUpdate = ({
  date,
  endDate,
  applyWeekends,
  availability,
}) => {
  let updateToMerge = {}
  if (!date) return updateToMerge
  if (endDate) {
    // Apply change to all dates in range, respecting weekend rules
    eachDayOfInterval({ start: date, end: endDate })
      .filter((d) => (applyWeekends ? true : !isWeekend(d)))
      .map((day) => format(day, 'yyyy-MM-dd'))
      .forEach((shortDate) => (updateToMerge[shortDate] = availability))
  } else {
    updateToMerge = { [format(date, 'yyyy-MM-dd')]: availability }
  }
  return updateToMerge
}

const _updateAvailability = ({ user, date, endDate, availability }) => {
  const applyWeekends = user.unsafeWeekends
  // Remove unset days from availability
  const deleteField = firebase.firestore.FieldValue.delete()
  if (availability.status === 0) {
    availability = deleteField
  } else {
    // Remove usused values in availability
    if (availability.message === '') {
      availability.message = deleteField
    }
    if (!availability.indefinite) {
      availability.indefinite = deleteField
    }
  }
  if (endDate) availability.indefinite = deleteField

  const newAvail = getAvailabilityUpdate({
    date,
    endDate,
    availability,
    applyWeekends,
  })

  return firebase
    .firestore()
    .collection('users')
    .doc(user.id)
    .set({ availability: newAvail, lastUpdated: new Date() }, { merge: true })
}

export const updateAvailability = debounce(_updateAvailability, 500, {
  leading: true,
})

const getLastIndefiniteDay = (date, availability) => {
  const availabilityAsDates = Object.entries(availability)
    .filter(([k, v]) => k !== 'id' && v && v.status > 0 && v.indefinite)
    .map(([shortDate]) => parse(shortDate, 'yyyy-MM-dd', new Date()))
    .filter((v) => isAfter(date, v))

  if (availabilityAsDates.length > 0) {
    const latestDate = Math.max.apply(null, availabilityAsDates)
    const avail = availability[format(latestDate, 'yyyy-MM-dd')]
    if (avail.indefinite) {
      return { ...avail, date: latestDate }
    }
  }

  return null
}

export const getAvailabilityForDate = (
  date,
  user,
  { defaultMessage = false } = {},
) => {
  user.availability = user.availability || {}
  const shortDate = format(date, 'yyyy-MM-dd')
  const avail = user.availability[shortDate] || {}
  const lastIndefinite = getLastIndefiniteDay(date, user.availability)

  const add = user.unsafeWeekends ? addDays : addBusinessDays
  const sub = user.unsafeWeekends ? subDays : subBusinessDays

  const tomorrowString = format(add(date, 1), 'yyyy-MM-dd')
  const yesterdayString = format(sub(date, 1), 'yyyy-MM-dd')
  const tomorrow = user.availability[tomorrowString]
  const yesterday = user.availability[yesterdayString]

  let { status = 0, message = '', indefinite } = avail
  let sourceDate

  if (lastIndefinite && !status) {
    status = lastIndefinite.status
    indefinite = true
    sourceDate = new Date(lastIndefinite.date)
  }

  if (lastIndefinite && !message && lastIndefinite.message) {
    if (!isWeekend(date) || lastIndefinite.status === status) {
      message = lastIndefinite.message
    }
  }

  if (lastIndefinite && !user.unsafeWeekends && isWeekend(date)) {
    status = 2
  }

  if (!message && status !== 0 && defaultMessage) {
    message = DEFAULT_MESSAGES[status]
  }

  if (typeof status !== 'number') {
    status = 0
  }

  const tomorrowMatch = tomorrow && avail.status === tomorrow.status
  const tomorrowMisMatch = !tomorrow || avail.status !== tomorrow.status
  const yesterdayMatch = yesterday && avail.status === yesterday.status
  const yesterdayMisMatch = !yesterday || avail.status !== yesterday.status

  const isRange = indefinite || tomorrowMatch || yesterdayMatch

  const isStartRange = avail.indefinite || (tomorrowMatch && yesterdayMisMatch)

  const isEndRange =
    (indefinite && tomorrow && tomorrow.status !== avail.status) ||
    (yesterdayMatch && tomorrowMisMatch)

  const isMiddleRange = !isStartRange && !isEndRange && isRange

  return {
    date,
    shortDate,
    status,
    message,
    indefinite,
    sourceDate,
    isStartRange,
    isMiddleRange,
    isEndRange,
    isRange,
    ...avail,
  }
}

export const toggleStatus = (status) =>
  status >= COLORS.length - 1 ? 1 : status + 1
