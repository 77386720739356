import React, { useEffect } from 'react'
import {
  Frame,
  List,
  ListItem,
  ListItemText,
  KeyboardArrowRightIcon,
  Heading,
} from 'components'

import {
  useHistory,
  makeStyles,
  getUserPortalLink,
  useSubscription,
} from 'utils'
import { useState } from 'react'

const SubscriberSettings = ({ user }) => {
  const { subscription } = useSubscription(user?.id)
  const [subscriptionUrl, setSubscriptionUrl] = useState()

  useEffect(() => {
    getUserPortalLink().then((url) => {
      setSubscriptionUrl(url)
    })
  }, [])

  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Settings & Account</Heading>

      <List>
        <Item
          label="Update My Name"
          secondary={user && `${user.firstName} ${user.lastName}`}
          route="/settings/name"
        />
        <Item label="Update My Details" route="/settings/details" />
        <Item label="Change My Password" route="/settings/password" />
        <Item
          label="Manage Subscription"
          disabled={!subscription || !subscriptionUrl}
          onClick={() => window.location.assign(subscriptionUrl)}
        />
        <Item label="Unhide Freelancers" route="/settings/hidden_freelancers" />
        <Item label="Delete My Account" route="/settings/delete" />
        <Item label="Log out" route="/logout" />
      </List>
    </Frame>
  )
}

export default SubscriberSettings

const useStyles = makeStyles((theme) => ({ primary: { fontSize: 21 } }))

const Item = ({ onClick, label, route, secondary, ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <ListItem
      button
      disabled={props.disabled}
      onClick={onClick || (() => history.push(route))}
      {...props}
    >
      <ListItemText classes={classes} primary={label} secondary={secondary} />
      <KeyboardArrowRightIcon color="primary" />
    </ListItem>
  )
}
