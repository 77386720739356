import { React, useState, useEffect, useRef } from 'react'
import { Box, Typography, SelectField, Switch, Button } from 'components'
import { getDaysInMonth, setDate, setMonth, setYear, getDate, getMonth, getYear } from 'date-fns'


const FreelancerAvailabilityStartDate = ({ onUpdate = state => { } }) => {
    const [state, setState] = useState(new Date())
    const [nowChecked, setNowChecked] = useState(true)
    const [dayChoices, setDayChoices] = useState([{}])
    const dateRefs = useRef([])

    const dateRef = useRef()
    const monthRef = useRef()
    const yearRef = useRef()

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const monthChoices = months.map((month, index) => ({ label: month, value: index }))
    const yearChoices = Array.from(new Array(10), (val, index) => index + new Date().getFullYear()).map((year) => ({ label: year, value: year }))

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case 'year':
                setState(setYear(state, value))
                break
            case 'month':
                setState(setMonth(state, value))
                break
            case 'day':
                setState(setDate(state, value))
                break
            default:
                break
        }
        onUpdate(state)
    }

    const handleNowChecked = () => {
        setNowChecked(!nowChecked)
        if (nowChecked) {
            setState(new Date())
        }
    }

    useEffect(() => {
        setDayChoices(Array.from(new Array(getDaysInMonth(state.getFullYear(), state.getMonth())), (val, index) => ({ label: index + 1, value: index + 1 })))
        onUpdate(state)
    }, [state])

    useEffect(() => {
        if (nowChecked) {
            setState(new Date())
            if (dateRef.current) {
                dateRef.current.value = state.getDate()
            }
            if (monthRef.current) {
                monthRef.current.value = state.getMonth()
            }
            if (yearRef.current) {
                yearRef.current.value = state.getFullYear()
            }
            onUpdate(state)
        }
    }, [nowChecked])

    return (
        <Box flexVariant='column space-between'>
            <Typography variant="caption">When would you like to start:</Typography>
            <Switch color='primary' label={'Now'} defaultChecked onChange={handleNowChecked} />
            <Box flexVariant='row space-between'>
                <SelectField hidden={nowChecked} name='year' label={'Year'} value={getYear(state)} choices={yearChoices} onChange={handleChange} ref={yearRef} />
                <SelectField hidden={nowChecked} name='month' label={'Month'} choices={monthChoices} value={getMonth(state)} onChange={handleChange} ref={monthRef} />
                <SelectField hidden={nowChecked} name='day' label={'Day'} choices={dayChoices} value={getDate(state)} onChange={handleChange} ref={dateRef} />
            </Box>
        </Box>
    )
}

export { FreelancerAvailabilityStartDate }