import React from 'react'
import { Box } from 'components'
import ReactCalendar from 'react-calendar'
import './calendar.css'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export function DateFilter({ filterState: state, setFilterState: setState }) {
  return (
    <Box flex={1} flexVariant="row" minWidth={{ xs: 0, sm: 600 }}>
      <BaseCalendar
        startDate={state.date1}
        endDate={state.date2}
        setStartDate={(v) => setState((f) => ({ ...f, date1: v }))}
        setEndDate={(v) => setState((f) => ({ ...f, date2: v }))}
      />
    </Box>
  )
}

const BaseCalendar = ({ startDate, endDate, setStartDate, setEndDate }) => {
  return (
    <ReactCalendar
      selectRange
      allowPartialRange
      showDoubleView
      maxDetail="month"
      minDetail="month"
      onChange={([fromDate, endDate]) => {
        setStartDate(fromDate)
        endDate && setEndDate(endDate)
      }}
      className="filter-calendar"
      formatShortWeekday={(_, date) => days[date.getDay()]}
      nextLabel={
        <ArrowForwardIcon
          style={{ marginTop: 15, fontSize: 16, fill: '#1d1d1d' }}
        />
      }
      prevLabel={
        <ArrowBackIcon
          style={{ marginTop: 15, fontSize: 16, fill: '#1d1d1d' }}
        />
      }
      value={[startDate, endDate]}
    />
  )
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
