import React, { useState } from 'react'
import { Heading, Checklist, Box, Frame, SelectField, Typography, Button, Switch } from 'components'
import { updateUser } from 'utils'
import { ProgressBarFooter } from '../FreelancerRoles/FreelancerAddRole'
import { NUM_ONBOARDING_STEPS, WORK_STATUSES } from 'constants'
import { FreelancerWorkStatusExpiry } from '../FreelancerOntarioWorkStatus/FreelancerWorkStatusExpiry'
import { useHistory } from 'react-router-dom'

const FreelancerSelectOntarioWorkingStatus = ({
  user,
  onboarding,
  onSubmit = () => { },
}) => {
  const [state, setState] = useState({ ontarioWorkingStatus: 1, permitExpiry: new Date() })

  const history = useHistory()
  return (
    <Frame maxWidth="sm" user={user}>
      <Box mx="auto" maxWidth={530}>
        <Heading subheading="Select one">What is your status for working in Ontario?</Heading>
        <Switch 
        color='primary'
        label={"I am eligible to work in Canada"} 
        onChange={e => setState({ ...state, ontarioWorkingStatus: +e.target.checked })} 
        defaultChecked />
        {/* <Checklist
          buttonLabel="Next"
          items={[
            { id: 4, label: WORK_STATUSES[4] },
            { id: 3, label: WORK_STATUSES[3] },
            { id: 2, label: WORK_STATUSES[2] },
            { id: 1, label: WORK_STATUSES[1] },
            { id: 0, label: WORK_STATUSES[0] },
          ]}
          onChange={([{ id }]) => setState({ ...state, ontarioWorkingStatus: id })}
        />
        {state.ontarioWorkingStatus === 1 || state.ontarioWorkingStatus === 2 ? (
          <FreelancerWorkStatusExpiry onUpdate={expiry => setState({ ...state, permitExpiry: expiry })} />
        ) : null} */}
      </Box>
      {onboarding ? (
        <ProgressBarFooter
          valid={typeof state.ontarioWorkingStatus === 'number'}
          stepIndex={4}
          firstStep={4}
          numSteps={NUM_ONBOARDING_STEPS}
          onNext={() => updateUser(user, state).then(onSubmit)}
          onPrev={() => { }}
        />
      ) : (
        <ProgressBarFooter
          valid={typeof state.ontarioWorkingStatus === 'number'}
          stepIndex={1}
          firstStep={1}
          numSteps={2}
          onNext={() => updateUser(user, state).then(history.push('/relocationstatus'))}
          onPrev={() => { }}
        />
        // <Button
        //   disabled={typeof state.ontarioWorkingStatus === 'number' ? false : true}
        //   type='submit'
        //   onClick={() => updateUser(user, state).then(history.push('/freelancer'))}
        // >Save</Button>
      )}

    </Frame>
  )
}

export default FreelancerSelectOntarioWorkingStatus
