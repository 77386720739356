import { Box, StarIcon, IconButton, Typography } from 'components'
import React, { useState, useEffect } from 'react'
import { getUserFavoriteCount } from 'utils'

export const FreelancerFavoriteBadge = ({ freelancer }) => {
  const [favoriteCount, setFavoriteCount] = useState(0)

  useEffect(() => {
    if (freelancer) {
      getUserFavoriteCount(freelancer).then(setFavoriteCount)
    }
  }, [freelancer])

  return (
    <Box display="flex" alignItems="center">
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 'bold',
          color: 'black',
          marginRight: 8,
        }}
      >
        {favoriteCount}
      </Typography>
      <IconButton
        style={{
          padding: 6,
          backgroundColor: 'black',
          margin: 0,

          pointerEvents: 'none',
        }}
      >
        <StarIcon style={{ fontSize: 18, fill: '#ECB230' }} />
      </IconButton>
    </Box>
  )
}
