import React, { useState, useEffect } from 'react'
import { Box, Button, Switch, Frame, Heading } from 'components'
import { useHistory, updateUser } from 'utils'

const FreelancerEmail = ({ user, setSnackMessage }) => {
  const [marketing, setMarketing] = useState(false)

  useEffect(() => {
    if (user) {
      setMarketing(user.marketing)
    }
  }, [user])
  const history = useHistory()

  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Change your email preferences.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()

          await updateUser(user, { marketing })

          history.push('/settings')
          setSnackMessage(
            'Your email preferences have been successfully updated',
          )
        }}
      >
        <Switch
          label="We may occasionally send you important industry and employment news. We promise it won’t get spammy!"
          checked={marketing}
          onChange={() => setMarketing(!marketing)}
          value="marketing"
        />

        <Box my={2} />

        <Button type="submit">Submit</Button>
      </form>
    </Frame>
  )
}

export default FreelancerEmail
