import React from 'react'
import { Redirect } from 'react-router-dom'
import SubscriberCreateForm from './SubscriberCreateForm'
import SubscriberCompanyForm from './SubscriberCompanyForm'

const SubscriberCreateView = ({ user }) => {
  if (!user) {
    return <SubscriberCreateForm renderAbout />
  }

  if (!user.company) {
    return <SubscriberCompanyForm user={user} />
  }

  return <Redirect to="/" />
}

export default SubscriberCreateView
