import React, { useState, useRef } from 'react'

import {
  Box,
  Divider,
  CloseIcon,
  CalendarIcon,
  ClockIcon,
  Typography,
  Button,
  ExperienceIcon,
  TagIcon,
} from 'components'

import { useOnClickOutside, sendGroupEmail } from 'utils'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  FilterList,
} from '@material-ui/icons'
import {
  ButtonBase, Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
} from '@material-ui/core'

export const MobileFilters = ({ open, onOpen, onClose, onClear, children }) => {
  return (
    <>
      <Button onClick={onOpen} variant="outlined">
        <FilterList style={{ fontSize: 16, marginRight: 5 }} />
        Filters
      </Button>

      {open && (
        <Box
          position="fixed"
          bgcolor="white"
          zIndex={2000}
          style={{ inset: 0, overflow: 'scroll' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={70}
          >
            <Button onClick={onClose}>Close</Button>
            <Typography variant="h4">Filters</Typography>
            <Button onClick={onClear}>Clear</Button>
          </Box>
          <Box px={2}>{children}</Box>
        </Box>
      )}
    </>
  )
}

export const DesktopFilters = ({ children, allowReset, resetState, allowMessage, renderedUsers = [] }) => {
  const [open, setOpen] = useState(false)
  const [formState, setFormState] = useState({})

  const handleSubmit = (e) => {
    e.preventDefault()
    renderedUsers.forEach(user => {
      sendGroupEmail({ ...formState, email: user.email, name: user.firstName })
        .then(res => console.log(res))
        .catch(err => console.log(err))
      // console.log({ ...formState, emailTo: user.email })
    })
    setOpen(false)
  }

  return (
    <Box
      position="relative"
      mt={{ xs: 2, md: 0 }}
      display="flex"
      flexVariant="row"
      flexWrap="wrap"
      flex={1}
    >
      {children}

      {allowReset && (
        <FilterButton onClick={() => resetState()}>Reset Filters</FilterButton>
      )}
      {allowMessage && (
        <>
          <FilterButton
            disabled={renderedUsers.length > 10}
            onClick={() => setOpen(true)}>
              {renderedUsers.length > 10 ? 'Cannot group message more than 10 results' : 'Message this search result'}
          </FilterButton>
          <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'} onClose={() => setOpen(false)}>
            <DialogTitle id="form-dialog-title">
              <Typography style={{ textAlign: 'left' }} variant="subtitle1">
                You are messaging the search result
              </Typography>
              <Typography variant="h5">
                Placeholder Search Name
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column">
                <TextField
                  placeholder="Subject"
                  variant='outlined'
                  style={{ marginBottom: '1rem' }}
                  onChange={(e) => setFormState({ ...formState, subject: e.target.value })}
                  value={formState.subject} />
                <TextField
                  placeholder="Your message goes here, maximum 500 characters"
                  multiline rows={5}
                  variant='outlined'
                  style={{ marginBottom: '1rem' }}
                  onChange={(e) => setFormState({ ...formState, message: e.target.value })}
                  value={formState.message} />
                <TextField
                  placeholder="Your name"
                  variant='outlined'
                  style={{ marginBottom: '1rem' }}
                  onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                  value={formState.name} />
                <TextField
                  placeholder="Email they should reply to"
                  variant='outlined'
                  type='email'
                  style={{ marginBottom: '1rem' }}
                  onChange={(e) => setFormState({ ...formState, replyTo: e.target.value })}
                  value={formState.replyTo} />
              </Box>
            </DialogContent>
            <DialogActions>
              <Typography variant='subtitle1'>The message will be sent to the {renderedUsers.length} people in this search result.</Typography>
              <Button onClick={handleSubmit} variant='contained'>Send message</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  )
}

export const MobileFilterMenu = ({ label, children, disabled }) => {
  const [open, setOpen] = useState(false)
  const Icon = ICONS[label]
  const ArrowIcon = open ? KeyboardArrowDown : KeyboardArrowUp
  return (
    <Box>
      <Divider my={0} />
      <ButtonBase
        disabled={disabled}
        disableRipple
        style={{ width: '100%' }}
        onClick={() => setOpen(!open)}
      >
        <Box flexVariant="row space-between align-center">
          <Typography
            style={{ fontWeight: 'bold', margin: '15px 8px', color: '#1d1d1d' }}
          >
            {Icon && (
              <Icon style={{ fill: '#1d1d1d', fontSize: 15, marginRight: 8 }} />
            )}
            {label}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#efefef"
            borderRadius={15}
            width={30}
            height={30}
          >
            <ArrowIcon style={{ fill: '#1d1d1d', fontSize: 22 }} />
          </Box>
        </Box>
      </ButtonBase>

      {open && (
        <>
          <Divider my={0} />
          <Box pl={3}>{children}</Box>
        </>
      )}
    </Box>
  )
}

export const FilterMenuButton = ({
  label,
  icon,
  children,
  changed,
  onSave,
  onCancel,
  flip,
  onReset,
  Icon,
  ...props
}) => {
  const [open, setOpen] = useState()
  const ref = useRef()

  useOnClickOutside(ref, () => {
    if (open) {
      onCancel && onCancel()
      children && setOpen(false)
    }
  })

  return (
    <Box
      ref={ref}
      style={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
      }}
      position={{ xs: 'none', sm: 'relative' }}
    >
      <FilterButton
        active={open}
        onClick={() => children && setOpen(!open)}
        onReset={changed ? onReset : null}
        Icon={Icon || ICONS[icon]}
        {...props}
      >
        {label}
      </FilterButton>

      {open && (
        <FilterMenu
          flip={flip}
          onSave={() => {
            onSave()
            setOpen(false)
          }}
        >
          {children}
        </FilterMenu>
      )}
    </Box>
  )
}

export const FilterMenu = ({ flip, children, onSave }) => (
  <Box
    position="absolute"
    top={{ xs: 140, sm: 45 }}
    left={flip ? null : 0}
    right={flip ? 0 : null}
    zIndex={10}
    borderRadius={8}
    px={2}
    pb={2}
    // maxHeight="80vh"
    display="flex"
    flexDirection="column"
    boxSizing="border-box"
    border="1px solid #ddd"
    boxShadow="0px 10px 20px rgba(29, 29, 29, 0.12)"
    style={{ backgroundColor: 'white', }}
  >
    <Box>{children}</Box>

    {onSave && (
      <>
        <Divider my={0} mb={2} />
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          style={{ width: '100%', maxWidth: 250, margin: '0 auto' }}
        >
          Apply
        </Button>
      </>
    )}
  </Box>
)

const ICONS = {
  Dates: CalendarIcon,
  Availability: ClockIcon,
  Years: ExperienceIcon,
  Role: TagIcon,
  Skills: TagIcon,
  Location: TagIcon,
}

export const FilterButton = ({ active, Icon, children, color = 'white', onReset, ...props }) => {
  return (
    <Button
      {...props}
      color={active ? undefined : color}
      style={{
        marginRight: 8,
        marginBottom: 4,
        padding: '6px 20px',
        opacity: props.disabled ? 0.6 : 1,
      }}
      variant={active ? 'contained' : 'outlined'}
    >
      {Icon && (
        <Icon
          style={{
            fill: active ? 'white' : '#1d1d1d',
            fontSize: 15,
            marginRight: 8,
          }}
        />
      )}
      {props.customIcon && props.customIcon}
      <Typography style={{ color: active ? 'white' : '#1d1d1d', fontSize: 14 }}>
        {children}
      </Typography>
      {onReset && (
        <Box
          onClick={(e) => {
            e.stopPropagation()
            onReset()
          }}
          borderRadius={10}
          width={20}
          height={20}
          style={{ backgroundColor: '#ddd', marginLeft: 10 }}
        >
          <CloseIcon style={{ fontSize: 12, fill: 'black' }} />
        </Box>
      )}
    </Button>
  )
}
