import React from 'react'
import { TextField as MUITextField, Typography, Box } from '@material-ui/core'
import { makeStyles } from 'utils'

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    background: '#fff',
    height: 40,
    'label + &': {
      marginTop: 24,
    },
    '&:hover': {
      background: 'white',
    },
    '&:hover .MuiInputBase-input': {
      border: '2px solid #DDDDDD',
    },
    '&.Mui-focused': {
      background: 'white',
    },
    '&.Mui-focused .MuiInputBase-input': {
      border: '2px solid #ADD0FF',
      boxShadow: 'inset -2.5px 2.5px 2.5px 2px rgba(29, 29, 29, 0.1)',
    },
  },
  multiline: {
    padding: 0,
    height: 'auto !important',
  },
  inputMultiline: {
    padding: 0,
    height: 'auto !important',
  },
  input: {
    borderRadius: 4,
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    height: 40,
    fontSize: 16,
    lineHeight: '20px',
    padding: '10px 16px',
    color: '#1d1d1d',
  },
}))

const useLabelStyles = makeStyles((theme) => ({
  root: {
    color: '#696969',
    transform: 'translate(0) !important',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
  },
}))

const TextField = ({
  label,
  showCharacterCount,
  maxLength,
  wrapperProps = {},
  ...props
}) => {
  const inputClasses = useInputStyles()
  const labelClasses = useLabelStyles()
  return (
    <Box my={1} position="relative" {...wrapperProps}>
      <MUITextField
        fullWidth
        variant="filled"
        margin="dense"
        label={label}
        autoComplete={getAutocompleteFromName(props.name)}
        InputProps={{ disableUnderline: true, classes: inputClasses }}
        InputLabelProps={{
          shrink: true,
          classes: labelClasses,
          required: false,
        }}
        id={props.name + '-input'}
        {...props}
      />
      {showCharacterCount && (
        <Box position="absolute" bottom={10} right={10}>
          <Typography color="primary">
            {maxLength
              ? maxLength - (props.value.length || 0)
              : props.value.length}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const getAutocompleteFromName = (name) => {
  if (name === 'email') {
    return 'email'
  }
  if (name === 'firstName') {
    return 'first name'
  }
  if (name === 'lastName') {
    return 'last name'
  }
}

export default TextField
