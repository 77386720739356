import React from 'react'
import { Box, Typography, Pill } from 'components'

export default function FreelancerEquipmentSection({ freelancer, onEdit }) {
  if (!freelancer.equipment && !onEdit) return null
  return (
    <Box flex={1} pt={3.5} px={3.5}>
      <Box flex={1} flexVariant="row space-between" mb={2.5}>
        <Typography variant="h4" style={{ fontWeight: '600' }}>
          Equipment
        </Typography>

        {onEdit && (
          <Pill size="small" onClick={onEdit}>
            Manage Equipment
          </Pill>
        )}
      </Box>

      {freelancer.equipment  && (
        freelancer.equipment.map((_equipment, i) => (
          <Pill
            bgcolor="white"
            key={_equipment + i}
            style={{ marginRight: 8, marginBottom: 8 }}
          >
            {_equipment}
          </Pill>
        )))}
    </Box>)
}
