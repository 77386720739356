import React, { useState } from 'react'
import {
  ListItem as MUIListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core'
import { Button, Box, NestedList, LockIcon } from 'components'
import { slugify } from 'utils'

const Checklist = ({
  items,
  state,
  initialState,
  initialOpen,
  onSubmit,
  onChange = () => { },
  isValid,
  border,
  label = 'Submit',
  multicheck,
}) => {
  const [active, setActive] = useState(initialState ? initialState : [])
  let activeItems = state || active

  const DecoratedItemComponent = (item) => {
    const isActive = activeItems.some(
      (a) => a.label === item.label || (item.id && a.id === item.id),
    )

    const clickItem = () => {
      const newActive = isActive ? [{}] : [item]
      setActive(newActive)
      onChange(newActive)
    }

    const clickItemMulticheck = () => {
      const newActive = isActive
        ? activeItems.filter((a) => a.label !== item.label && a.id !== item.id)
        : activeItems.concat([item])
      setActive(newActive)
      onChange(newActive)
    }

    return (
      <ChecklistItem
        {...item}
        checked={isActive}
        onClick={multicheck ? clickItemMulticheck : clickItem}
      />
    )
  }

  return (
    <>
      <Box
        border={border ? '1px solid #999' : null}
        py={border ? 2 : 0}
        my={border ? 4 : 2}
        className={border ? 'hard-shadow' : null}
      >
        <NestedList
          initialState={initialOpen}
          ItemComponent={DecoratedItemComponent}
          items={items}
        />
      </Box>

      {onSubmit && (
        <Button
          flex={1}
          variant="contained"
          id="checklist-submit"
          disabled={
            isValid
              ? !isValid(activeItems)
              : initialState && initialState.id && activeItems.length === 1
                ? activeItems[0].id === initialState.id
                : activeItems.length === 0
          }
          onClick={() => onSubmit(multicheck ? activeItems : activeItems[0])}
          style={{ margin: '16px auto 0', display: 'block', width: '100%' }}
        >
          {label}
        </Button>
      )}
    </>
  )
}

export default Checklist

const ChecklistItem = ({
  onClick,
  label,
  disabled,
  hidden,
  locked,
  secondary,
  checked,
  nested,
}) => {
  const margin = locked ? 8 : 16

  return (
    <MUIListItem
      disabled={disabled || hidden || locked}
      button
      id={`${slugify(label)}-checklist-item`}
      onClick={onClick}
    >
      {locked && <LockIcon />}
      <ListItemText
        primary={label}
        secondary={secondary}
        primaryTypographyProps={{
          style: {
            marginLeft: nested ? margin : 0,
            fontSize: 14,
            fontWeight: '600',
          },
        }}
        secondaryTypographyProps={{
          style: {
            marginLeft: nested ? margin : 0,
            fontSize: 12,
            fontWeight: '600',
          },
        }}
      />
      <ListItemSecondaryAction>
        <Checkbox
          disabled={disabled || hidden || locked}
          edge="end"
          color="primary"
          checked={checked}
          onChange={onClick}
          style={{ marginRight: 0 }}
        />
      </ListItemSecondaryAction>
    </MUIListItem>
  )
}
