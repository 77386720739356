import React, { useEffect, useState } from 'react'
import { DateFilter } from './filters/DateFilter'
import { Box, StarBorderIcon } from 'components'

import {
  getCitiesFromMarkets,
  getIndustriesFromMarkets,
  getRolesFromMarkets,
  useIsMobile,
} from 'utils'
import { format } from 'date-fns'
import {
  FilterMenuButton,
  DesktopFilters,
  MobileFilters,
  MobileFilterMenu,
} from '.'
import { WorkingStyleFilter } from './filters/WorkingStyleFilter'
import { ListFilter } from './filters/ListFilter'
import { IndustrySelect } from 'components/subscriber/MarketsView/SearchBar'

export const JobBoardFilterBar = ({ filterState, setFilterState, markets }) => {
  const isMobile = useIsMobile()
  const [tempFilterState, setTempFilterState] = useState({ ...filterState })
  const cities = getCitiesFromMarkets(markets)
  const roles = getRolesFromMarkets(markets).filter(
    (r) => r.industryId === filterState.industry,
  )
  const industries = getIndustriesFromMarkets(markets).reduce(
    (obj, n) => ({ ...obj, [n.id]: n.label }),
    {},
  )

  useEffect(() => {
    setTempFilterState(() => ({ ...filterState }))
  }, [filterState])

  const resetState = (...keys) => {
    if (keys.length === 0) {
      keys = Object.keys(filterInitialState)
    }
    keys.forEach((key) => {
      setTempFilterState((fs) => ({ ...fs, [key]: filterInitialState[key] }))
      setFilterState((fs) => ({ ...fs, [key]: filterInitialState[key] }))
    })
  }

  return (
    <Box display="flex" alignItems="center" mt={3.5} mb={2}>
      <IndustrySelect
        setIndustryId={(id) =>
          setFilterState((fs) => ({ ...fs, industry: id }))
        }
        industries={industries}
        industryId={filterState.industry}
      />
      {false && isMobile ? (
        <JobBoardMobileFilters
          tempFilterState={tempFilterState}
          setTempFilterState={setTempFilterState}
          resetState={resetState}
          cities={cities}
          setFilterState={setFilterState}
        />
      ) : (
        <JobBoardDesktopFilters
          filterState={filterState}
          setFilterState={setFilterState}
          tempFilterState={tempFilterState}
          setTempFilterState={setTempFilterState}
          resetState={resetState}
          cities={cities}
          roles={roles}
        />
      )}
    </Box>
  )
}

const JobBoardDesktopFilters = ({
  filterState,
  setFilterState,
  tempFilterState,
  setTempFilterState,
  resetState,
  cities,
  roles,
}) => {
  const dateChanged =
    +filterInitialState.date1 !== +filterState.date1 && filterState.date1

  const citiesChanged = filterState.cities.length > 0
  const rolesChanged = filterState.roles.length > 0
  const workLocationChanged = filterState.working_style

  const anyChanged =
    citiesChanged || rolesChanged || dateChanged || workLocationChanged

  const menuProps = {
    onSave: () => setFilterState({ ...tempFilterState }),
    onCancel: () => setTempFilterState({ ...filterState }),
  }
  const filterProps = {
    filterState: tempFilterState,
    setFilterState: setTempFilterState,
  }

  return (
    <DesktopFilters allowReset={anyChanged} resetState={resetState}>
      <FilterMenuButton
        icon="Dates"
        label={
          dateChanged
            ? filterState.date2
              ? `${format(filterState.date1, 'MMM d')} - ${format(
                  filterState.date2,
                  'MMM d',
                )}`
              : format(filterState.date1, 'MMM d')
            : 'Dates'
        }
        changed={dateChanged}
        onReset={() => resetState('date1', 'date2')}
        {...menuProps}
      >
        <DateFilter {...filterProps} />
      </FilterMenuButton>
      <FilterMenuButton
        label="Roles"
        Icon={StarBorderIcon}
        changed={rolesChanged}
        onReset={() => {
          resetState('roles')
        }}
        {...menuProps}
      >
        <ListFilter stateKey="roles" items={roles} {...filterProps} />
      </FilterMenuButton>
      <FilterMenuButton
        label="Work Location"
        Icon={StarBorderIcon}
        changed={workLocationChanged}
        onReset={() => {
          resetState('working_style')
        }}
        {...menuProps}
      >
        <WorkingStyleFilter {...filterProps} />
      </FilterMenuButton>
      {filterState.working_style !== 'remote_only' && (
        <FilterMenuButton
          label="Cities"
          Icon={StarBorderIcon}
          changed={citiesChanged}
          onReset={() => {
            resetState('cities')
          }}
          {...menuProps}
        >
          <ListFilter stateKey="cities" items={cities} {...filterProps} />
        </FilterMenuButton>
      )}
    </DesktopFilters>
  )
}

export const filterInitialState = {
  date1: undefined,
  date2: undefined,
  working_style: undefined,
  roles: [],
  cities: [],
}

const JobBoardMobileFilters = ({
  tempFilterState,
  setTempFilterState,
  resetState,
  setFilterState,
}) => {
  const [open, setOpen] = useState(false)
  const filterProps = {
    filterState: tempFilterState,
    setFilterState: setTempFilterState,
  }

  return (
    <MobileFilters
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
        setFilterState({ ...tempFilterState })
      }}
      onClear={() => {
        resetState()
        setOpen(false)
      }}
    >
      <MobileFilterMenu label="Dates">
        <DateFilter {...filterProps} />
      </MobileFilterMenu>
    </MobileFilters>
  )
}
