import firebase from 'utils/firebase'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

export const updateFlags = (user, flaggedUser, update) => {
  return firebase
    .firestore()
    .collection('flags')
    .doc(flaggedUser.id)
    .set(
      {
        [user.id]: pickBy(update, identity),
      },
      { merge: true },
    )
}
