import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  Pagination,
  ImageInput,
  ImageField, ArrayInput, SimpleFormIterator,
  BooleanInput,
} from 'react-admin'

export const ContentList = props => (
  <List
    {...props}
    title="Content List"
    perPage={100}
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="textValue" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const ContentEdit = props => (
  <Edit {...props} title="Content Edit" undoable={false}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput multiline source="textValue" fullWidth />
      <ImageInput multiple source="imageValue" label="Related pictures" accept="image/*" placeholder={<p>Drop your file here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ArrayInput source="textValues">
        <SimpleFormIterator>
          <TextInput multiline source="text" label="text" />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="trailingDivider" label="Trailing Divider" />
    </SimpleForm>
  </Edit>
)

export const ContentCreate = props => (
  <Create {...props} title="Content Create">
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput multiline source="textValue" fullWidth />
      <ImageInput multiple source="imageValue" label="Related pictures" accept="image/*" placeholder={<p>Drop your file here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ArrayInput source="textValues">
        <SimpleFormIterator>
          <TextInput multiline source="text" label="text" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
