import React from 'react'
import { Button, TextField, Frame, Heading, SelectField } from 'components'
import { useHistory, getFormValues, updateUser } from 'utils'
import { SUBSCRIBER_ROLES } from '../../../constants'

const SubscriberDetails = ({ user, setSnackMessage }) => {
  const history = useHistory()
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Change your details.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { company, role, city } = getFormValues(event)
          await updateUser(user, { company, occupation: role, city }).then(
            () => {
              history.push('/settings')
              setSnackMessage('Your details have been successfully updated')
            },
          )
        }}
      >
        <TextField
          defaultValue={user.company}
          required
          name="company"
          label="Company"
        />
        <TextField defaultValue={user.city} required name="city" label="City" />

        <SelectField
          required
          defaultValue={user.occupation}
          name="role"
          label="Role"
          choices={SUBSCRIBER_ROLES.map((label) => ({ label, value: label }))}
        />

        <Button type="submit">Update Details</Button>
      </form>
    </Frame>
  )
}

export default SubscriberDetails
