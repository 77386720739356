import React from 'react'
import { makeStyles } from 'utils'
import {
  InputLabel,
  MenuItem,
  Select as MUISelect,
  Box,
  FormControl,
} from '@material-ui/core'

const SelectField = ({
  name,
  label,
  choices = [],
  value,
  onChange,
  required,
  disabled,
  defaultValue,
  ...props
}) => {
  const inputClasses = useInputStyles()
  const labelClasses = useLabelStyles()
  return (
    <Box my={2} position="relative" {...props}>
      <InputLabel classes={labelClasses} shrink>
        {label} {required ? '*' : ''}
      </InputLabel>
      <FormControl
        classes={inputClasses}
        variant="outlined"
        style={{ width: '100%', marginTop: 8 }}
      >
        <MUISelect
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
          value={value}
          onChange={onChange}
          name={name}
          margin="dense"
        >
          {choices.map((c) =>
            props.renderChoice ? (
              props.renderChoice(c)
            ) : (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ),
          )}
        </MUISelect>
      </FormControl>
    </Box>
  )
}

export default SelectField

const useInputStyles = makeStyles((theme) => ({
  root: {
    borderColor: 'white',
  },
}))

const useLabelStyles = makeStyles((theme) => ({
  root: {
    color: '#696969',
    transform: 'translate(0) !important',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
  },
}))
