import AvatarEditor from "react-avatar-editor";
import placeholder from "assets/default_avatar.png";
import Dropzone from "react-dropzone";
import { Frame, Box, Heading, Slider, Button, DeleteIcon } from "components";
import { Popover, IconButton } from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import firebase from "utils/firebase";
import { ProgressBarFooter } from "../FreelancerRoles/FreelancerAddRole";
import { NUM_ONBOARDING_STEPS } from "constants";

const useStyles = makeStyles({
    root: {
        height: 250,
    },
    popover: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    popoverContainer: {
        backgroundColor: 'rgba(255,255,255,0.1)',
    },
    iconButton: {
        pointerEvents: 'auto',
        backgroundColor: 'rgba(0,0,0,0)',
        background: 'transparent',
    }
});

const FreelancerAvatar = ({ history, user, onboarding, onNext = () => { } }) => {
    const [zoomValue, setZoomValue] = useState(1.0);
    const [image, setImage] = useState(placeholder);
    const popoverAnchorEl = useRef(null);
    const editor = useRef(null)
    const dropzone = useRef(null)
    const classes = useStyles();
    const ref = firebase.storage().ref(`/profileimages/${user.id}`)

    const handleRescale = (e, value) => {
        setZoomValue(value);
    }

    const handleUpload = async e => {
        if (editor.current) {
            try {
                if (image === placeholder && onboarding) onNext() 
                if (image === placeholder) throw new Error('No image selected')
                const imageUrl = editor.current.getImageScaledToCanvas().toDataURL();
                const res = await fetch(imageUrl)
                const blob = await res.blob()
                const file = new File([blob], `${user.id}.png`, { type: 'image/png' })
                ref.put(file).then(e => e.ref.getDownloadURL().then((url) => {
                    firebase
                        .firestore()
                        .collection('users')
                        .doc(user.id)
                        .set({ avatarUrl: url }, { merge: true })
                    if (onboarding) {
                        onNext()
                        return
                    }
                    history.push('/freelancer')
                }))
            } catch (e) {
                console.error(e)
            }
        }
    }

    // useEffect(() => {
    //     if (!user.avatarUrl) return
    //     fetch(user.avatarUrl).then(res => res.blob()).then(blob => {
    //         const file = new File([blob], `${user.id}.png`, { type: 'image/png' })
    //         setImage(URL.createObjectURL(file))
    //     })
    //     // setImage(user.avatarUrl)
    // }, [user])

    return (
        <Frame maxWidth={"xs"}>
            <Box mx="auto" display="flex" flexDirection="column" style={{ justifyContent: "center" }} maxWidth={430}    >
                <Box mx="auto">
                    <Heading>Upload a Photo</Heading>
                </Box>
                <Box
                    className={classes.root}
                    mx="auto"
                    mb={4}
                    display="flex"
                    flexDirection="row"
                    ref={popoverAnchorEl}
                >
                    <Dropzone
                        onDrop={acceptedFiles => setImage(acceptedFiles[0])}
                        noClick={image !== placeholder}
                        noKeyboard
                        accept="image/*"
                        maxFiles={1}
                        multiple={false}
                        style={{ height: 250, width: 250 }}
                        ref={dropzone}
                        crossOrigin="anonymous"
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <AvatarEditor
                                    ref={editor}
                                    image={image}
                                    width={250}
                                    height={250}
                                    border={1}
                                    scale={zoomValue}
                                    rotate={0}
                                    crossOrigin="anonymous"

                                />
                                <input {...getInputProps()} />
                                <Popover
                                    open={image !== placeholder}
                                    anchorEl={popoverAnchorEl.current}
                                    className={classes.popover}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                >
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={() => { setImage(placeholder); setZoomValue(1.0) }}
                                        component='span'
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Popover>
                            </div>
                        )}
                    </Dropzone>
                    {image !== placeholder && (
                        <Slider
                            min={0.5} max={2.0} step={0.2} orientation="vertical" defaultValue={1.0} onChange={handleRescale} />
                    )}

                </Box>
                <Button onClick={handleUpload} variant="contained">Upload</Button>
            </Box>
            {onboarding &&
                <ProgressBarFooter
                    valid={true}
                    stepIndex={3}
                    firstStep={3}
                    numSteps={NUM_ONBOARDING_STEPS}
                    onNext={() => {
                        handleUpload()
                    }}
                    onPrev={() => { }}
                />}
        </Frame>
    );
};

export default FreelancerAvatar