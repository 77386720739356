import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'

import {
  Box,
  Divider,
  Input,
  Typography,
  Button,
  Pill,
  IndustryIcon,
  FreelancerList,
} from 'components'
import {
  searchUsers,
  getRoleCounts,
  getSkillCounts,
  useOnClickOutside,
  useHasActiveSubscription,
} from 'utils'
import SearchIcon from '@material-ui/icons/Search'

export const SearchBar = ({
  user,
  industryId,
  industries,
  setIndustryId,
  freelancers,
  results,
  cities,
  setFilterState,
}) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [searchNameResults, setSearchNameResults] = useState([])
  const [searchTitleResults, setSearchTitleResults] = useState([])
  const [searchSkillResults, setSearchSkillResults] = useState([])
  const [searchCityResults, setSearchCityResults] = useState([])

  const { hasActiveSubscription } = useHasActiveSubscription(user?.id)

  const ref = useRef()
  useOnClickOutside(ref, () => {
    if (open) {
      setOpen(false)
    }
  })

  const roleCounts = useMemo(() => getRoleCounts(freelancers), [freelancers])

  const skillCounts = useMemo(() => getSkillCounts(results), [results])

  const searchName = useMemo(
    () => debouncedSearchName(freelancers),
    [freelancers],
  )

  const searchTitle = useMemo(
    () => debouncedSearchTitle(roleCounts),
    [roleCounts],
  )

  const searchSkills = useMemo(
    () => debouncedSearchSkill(skillCounts),
    [skillCounts],
  )

  const searchCities = useCallback(
    () => debouncedSearchCities(cities),
    [cities],
  )

  const onChangeQuery = (e) => setQuery(e.target.value)

  useEffect(() => {
    searchName(query, setSearchNameResults)
  }, [query, searchName])

  useEffect(() => {
    searchTitle(query, setSearchTitleResults)
  }, [query, searchTitle])

  useEffect(() => {
    searchSkills(query, setSearchSkillResults)
  }, [query, searchSkills])

  useEffect(() => {
    searchCities(query, setSearchCityResults)
  }, [query, searchCities])

  const _setFilterState = (val) => {
    setFilterState(val)
    setOpen(false)
    setQuery('')
  }

  return (
    <Box
      borderColor={open ? '#146AFF' : 'white'}
      border="2px solid white"
      boxShadow="rgb(29 29 29 / 8%) 0px 10px 20px 0px"
      borderRadius="80px"
      padding="4px"
      marginTop="20px"
      style={{ transition: 'border-color 300ms' }}
    >
      <Box
        className={open ? null : 'clickthrough'}
        top={0}
        left={0}
        bottom={0}
        right={0}
        background="white"
        position="absolute"
        style={{ opacity: open ? 0.5 : 0, transition: 'opacity 300ms' }}
        zIndex={3}
      />
      <Box ref={ref}>
        <Box position="relative" zIndex={10} display="flex" alignItems="center" backgroundColor="white">
          <IndustrySelect
            setIndustryId={setIndustryId}
            industries={industries}
            industryId={industryId}
          />

          <Box ml={2} flex={1} display="flex" alignItems="center">
            <Box flex={1} mr={2}>
              <Input
                disableUnderline
                // placeholder="Start searching for freelancers, titles or specialties"
                placeholder="Search"
                style={{ width: '100%' }}
                value={query}
                onFocus={() => setOpen(true)}
                onChange={onChangeQuery}
              />
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Button variant="contained">
                <SearchIcon style={{ fontSize: 18, marginRight: 6 }} />
                Search
              </Button>
            </Box>
          </Box>
        </Box>

        <Box position="relative">
          <Box
            bgcolor="white"
            position="absolute"
            style={{
              opacity: open ? 1 : 0,
              transition: 'opacity 300ms',
              pointerEvents: open ? 'initial' : 'none',
              backgroundColor: 'white'
            }}
            top={100}
            left={0}
            right={0}
            zIndex={9}
            
          >
            {query === '' ? (
              <Box
                p={3}
                style={{
                  border: '1px solid #DDDDDD',
                  boxSizing: 'border-box',
                  boxShadow: '0px 10px 20px rgba(29, 29, 29, 0.08)',
                  borderRadius: 10,
                  maxHeight: 600,
                  overflow: 'scroll',
                }}
              >
                <SuggestionSection
                  label="Suggested Roles"
                  active={open}
                  onClick={(t) => _setFilterState((f) => ({ ...f, role: t }))}
                  suggestions={roleCounts}
                />
                <SuggestionSection
                  label="Suggested Cities"
                  active={open}
                  onClick={(t) => {
                    const city = Object.entries(cities).find(
                      ([k, v]) => v === t,
                    )[0]
                    _setFilterState((f) => ({ ...f, city }))
                  }}
                  suggestions={Object.values(cities)}
                />
                {hasActiveSubscription && (
                  <SuggestionSection
                    label="Suggested Skills"
                    initialAmount={30}
                    active={open}
                    showDivider={false}
                    onClick={(t) =>
                      _setFilterState((f) => ({
                        ...f,
                        skills: f.skills.includes(t)
                          ? f.skills
                          : f.skills.concat([t]),
                      }))
                    }
                    suggestions={skillCounts}
                  />
                )}
              </Box>
            ) : (
              <Box
                p={4}
                style={{
                  border: '1px solid #DDDDDD',
                  boxSizing: 'border-box',
                  boxShadow: '0px 10px 20px rgba(29, 29, 29, 0.08)',
                  borderRadius: 10,
                }}
              >
                <SuggestionSection
                  label="Suggested Roles"
                  suggestions={searchTitleResults}
                  onClick={(t) => _setFilterState((f) => ({ ...f, role: t }))}
                />
                {hasActiveSubscription && (
                  <SuggestionSection
                    onClick={(t) =>
                      _setFilterState((f) => ({
                        ...f,
                        skills: f.skills.includes(t)
                          ? f.skills
                          : f.skills.concat([t]),
                      }))
                    }
                    label="Suggested Skills"
                    suggestions={searchSkillResults}
                  />
                )}
                <SuggestionSection
                  onClick={(t) => {
                    const city = Object.entries(cities).find(
                      ([k, v]) => v === t,
                    )[0]
                    _setFilterState((f) => ({ ...f, city }))
                  }}
                  label="Suggested Cities"
                  suggestions={searchCityResults}
                />
                <SuggestionSection label="Freelancers" showDivider={false}>
                  <Box maxHeight={490} overflow="hidden scroll">
                    <FreelancerList user={user} users={searchNameResults} />
                  </Box>
                </SuggestionSection>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const SuggestionSection = ({
  label,
  children,
  suggestions = [],
  showDivider = suggestions.length !== 0,
  onClick,
  active,
  pageSize = 30,
  initialAmount = 15,
}) => {
  const [showAmount, setShowAmount] = useState(initialAmount)
  const allResults = showAmount >= suggestions.length

  useEffect(() => {
    setShowAmount(initialAmount)
  }, [label, active, initialAmount])

  return suggestions.length > 0 || children ? (
    <>
      {label && (
        <Typography style={{ marginBottom: 12 }} variant="h6">
          {label}:
        </Typography>
      )}
      {children}
      {suggestions.length > 0 && (
        <Box display="flex" flexWrap="wrap">
          {suggestions.slice(0, showAmount).map((thing) => {
            let title = thing
            let count = null
            if (Array.isArray(thing)) {
              title = thing[0]
              count = thing[1]
            }
            return (
              <Pill
                key={title}
                style={{ marginRight: 8, marginBottom: 6 }}
                count={count}
                onClick={() => onClick(title)}
              >
                {title}
              </Pill>
            )
          })}
          <Pill
            style={{ marginRight: 8, marginBottom: 6 }}
            onClick={() =>
              setShowAmount((s) => (allResults ? pageSize : s + pageSize))
            }
          >
            See {allResults ? 'Less' : 'More'}
          </Pill>
        </Box>
      )}
      {showDivider && <Divider mt={3} mb={3.5} />}
    </>
  ) : null
}

const debouncedSearchName = (users) =>
  debounce((query, callback) => {
    if (query === '') return
    searchUsers(query, users, ['firstName', 'lastName'], (name) =>
      callback(name),
    )
  }, 200)

const debouncedSearchCities = (cities) =>
  debounce((query, callback) => {
    if (query === '') return
    const fuse = new Fuse(
      Object.entries(cities).map(([k, v]) => ({ label: v, id: k })),
      {
        keys: ['label'],
        matchAllTokens: true,
        tokenize: true,
        threshold: 0.3,
        minMatchCharLength: 2,
        id: 'id',
      },
    )

    callback(fuse.search(query).map((r) => [r.item.label, r.item.count]))
  }, 200)

const debouncedSearchSkill = (skills) =>
  debounce((query, callback) => {
    if (query === '') return
    const fuse = new Fuse(
      skills.map((s) => ({ label: s[0], count: s[1] })),
      {
        keys: ['label'],
        matchAllTokens: true,
        tokenize: true,
        threshold: 0.3,
        minMatchCharLength: 2,
        id: 'id',
      },
    )
    const results = fuse.search(query).map((r) => [r.item.label, r.item.count])

    callback(results)
  }, 200)

const debouncedSearchTitle = (titles) =>
  debounce((query, callback) => {
    if (query === '') return
    const fuse = new Fuse(
      titles.map((s) => ({ label: s[0], count: s[1] })),
      {
        keys: ['label'],
        matchAllTokens: true,
        tokenize: true,
        threshold: 0.3,
        minMatchCharLength: 2,
        id: 'id',
      },
    )

    callback(fuse.search(query).map((r) => [r.item.label, r.item.count]))
  }, 200)

export function IndustrySelect({ industries, industryId, setIndustryId }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const ref = useRef()
  const industryName = industries[industryId]
  useOnClickOutside(ref, () => {
    if (menuOpen) {
      setMenuOpen(false)
    }
  })

  return (
    <Box ref={ref} position="relative">
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        variant={menuOpen ? 'contained' : undefined}
        style={{ color: menuOpen ? 'white' : '#1d1d1d' }}
      >
        <IndustryIcon style={{ fontSize: 16, marginRight: 4 }} />
        <Box display={{ xs: 'none', sm: 'block' }}>{industryName}</Box>
      </Button>
      <Box
        flexVariant="column"
        bgcolor="white"
        borderRadius={10}
        border="1px solid #ddd"
        position="absolute"
        width={230}
        top={45}
        zIndex={4}
        left={0}
        style={{
          transition: 'opacity 300ms',
          opacity: menuOpen ? 1 : 0,
          pointerEvents: menuOpen ? 'initial' : 'none',
          backgroundColor: 'white'
        }}
      >
        {Object.entries(industries).map(([key, industry]) => (
          <Box key={key}>
            <Box py={0.7}>
              <Button
                style={{ color: '#1d1d1d' }}
                key={industry}
                onClick={() => {
                  setMenuOpen(false)
                  setIndustryId(key)
                }}
              >
                {industry}
              </Button>
            </Box>
            <Divider my={0} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
