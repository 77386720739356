import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { Loader } from 'components'
import Admin from 'containers/Admin'
import Login from 'containers/Login'
import About from 'containers/About'
import Logout from 'containers/Logout'
import ForgotPassword from 'containers/ForgotPassword'
import Home from 'containers/Home'
import Terms from 'containers/Terms'
import * as SubscriberRoutes from 'containers/Subscriber'
import * as FreelancerRoutes from 'containers/Freelancer'
import { QueryParamProvider } from 'use-query-params'

const Routes = { ...SubscriberRoutes, ...FreelancerRoutes }

const RoutesComponent = (appState) => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        {ROUTES.map(({ exact = true, Component, ...route }, index) => (
          <Route
            key={index}
            exact={exact}
            path={route.path}
            render={(routeState) => {
              if (appState.loading) return <Loader />
              if (shouldRedirect(appState.user, route))
                return <Redirect to={route.redirect} />

              const render =
                route.render ||
                ((props) => {
                  if (
                    Component.Freelancer &&
                    getRole(props.user).isFreelancer
                  ) {
                    return <Component.Freelancer {...props} />
                  }

                  if (
                    Component.Subscriber &&
                    getRole(props.user).isSubscriber
                  ) {
                    return <Component.Subscriber {...props} />
                  }

                  return <Component {...props} />
                })

              return render({ ...routeState, ...appState })
            }}
          />
        ))}

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </QueryParamProvider>
  </Router>
)

const getRole = (user) => {
  const role = user ? user.role : ''
  const isAdmin = ['admin'].includes(role)
  const isFreelancer = ['freelancer', 'admin'].includes(role)
  const isSubscriber = ['subscriber'].includes(role)

  return { isAdmin, isFreelancer, isSubscriber }
}

const shouldRedirect = (user, route) => {
  const role = getRole(user)

  return (
    (route.requiresUnauth && user) ||
    (route.requiresAuth && !user) ||
    (route.requiresFreelancer && !role.isFreelancer) ||
    (route.requiresSubscriber && !role.isSubscriber) ||
    (route.requiresAdmin && !role.isAdmin)
  )
}

export default RoutesComponent

const PUBLIC_ROUTES = [
  {
    path: '/',
    Component: Home,
    requiresAuth: false,
    render: ({ user, ...props }) => {
      const redirect = getRedirectPath(user)
      return redirect ? (
        <Redirect to={redirect} />
      ) : (
        <Home user={user} {...props} />
      )
    },
  },
  { path: '/about', Component: About },
  { path: '/terms', Component: Terms },
  { requiresUnauth: true, path: '/login', Component: Login },
  { requiresUnauth: true, path: '/forgot-password', Component: ForgotPassword },

  { exact: false, path: '/signup', Component: Routes.SubscriberCreate },
  { path: '/freelancer-signup', Component: Routes.FreelancerCreate },

  { path: '/freelancers/:userEmail', Component: Routes.FreelancerShow },
  {
    path: '/recommendations/:userEmail/add/:name',
    Component: Routes.FreelancerRecommendationCreate,
  },
  { path: '/faq', Component: Routes.SubscriberFAQ },
]

const FREELANCER_ROUTES = [
  { path: '/month/', Component: Routes.FreelancerMonth },
  { path: '/profile', Component: Routes.FreelancerProfile },
  { path: '/equipment', Component: Routes.FreelancerEquipment },
  { path: '/roles/:subroute?/:editType?', Component: Routes.FreelancerRoles },
  {
    path: '/recommendations/request',
    Component: Routes.FreelancerRecommendationRequest,
  },
  { path: '/settings/city', Component: Routes.FreelancerCity },
  { path: '/settings/email', Component: Routes.FreelancerEmail },
  { path: '/settings/visibility', Component: Routes.FreelancerVisibility },
  { path: '/job_board', Component: Routes.FreelancerJobBoardList },
  { path: '/credits', Component: Routes.FreelancerCredits },
  { path: '/availability', Component: Routes.FreelancerCommitments },
  { path: '/workstatus', Component: Routes.FreelancerWorkStatus },
  { path: '/relocationstatus', Component: Routes.FreelancerRelocationStatus },
  { path: '/diversitytags', Component: Routes.FreelancerDiversityTags },
  { path: '/gendertags', Component: Routes.FreelancerGenderTags },
  { path: '/profilepicture', Component: Routes.FreelancerAvatar },
  { path: '/employmentstatus', Component: Routes.FreelancerSelectEmploymentStatus },
]

const SUBSCRIBER_ROUTES = [
  { path: '/packages', Component: Routes.SubscriberPayment },
  { path: '/job_board/list', Component: Routes.SubscriberJobBoardList },
  { path: '/job_board/edit/:id', Component: Routes.SubscriberJobBoardEdit },
  { path: '/job_board/create', Component: Routes.SubscriberJobBoardCreate },
  { path: '/groups', Component: Routes.SubscriberGroups },
  { path: '/groups/edit/:id', Component: Routes.SubscriberGroupEdit },
  // TODO: turn on message feature
  // { path: '/groups/message/:id', Component: Routes.SubscriberGroupMessage },
  { path: '/groups/create', Component: Routes.SubscriberGroupCreate },
  { path: '/settings/details', Component: Routes.SubscriberDetails },
  {
    path: '/settings/hidden_freelancers',
    Component: Routes.SubscriberHiddenFreelancers,
  },
]

const SHARED_AUTH_ROUTES = [
  { exact: false, path: '/logout', redirect: '/login', Component: Logout },
  {
    path: '/settings',
    Component: {
      Freelancer: Routes.FreelancerSettings,
      Subscriber: Routes.SubscriberSettings,
    },
  },
  {
    path: '/settings/name',
    Component: {
      Freelancer: Routes.FreelancerName,
      Subscriber: Routes.SubscriberName,
    },
  },
  {
    path: '/settings/password',
    Component: {
      Freelancer: Routes.FreelancerPassword,
      Subscriber: Routes.SubscriberPassword,
    },
  },
  {
    path: '/settings/delete',
    Component: {
      Freelancer: Routes.FreelancerDelete,
      Subscriber: Routes.SubscriberDelete,
    },
  },
]

export const ROUTES = [
  { exact: false, requiresAdmin: true, path: '/admin', Component: Admin },
  ...SHARED_AUTH_ROUTES,
  ...PUBLIC_ROUTES.map((r) => ({ ...r, requiresAuth: false })),
  ...FREELANCER_ROUTES.map((r) => ({ ...r, requiresFreelancer: true })),
  ...SUBSCRIBER_ROUTES.map((r) => ({ ...r, requiresSubscriber: true })),
]

const getRedirectPath = (user) => {
  let redirect
  if (user) {
    if (/freelancer/.test(user.role)) {
      redirect = !user.city ? '/freelancer-signup' : `/profile`
    }

    if (/subscriber/.test(user.role)) {
      if (!user.company) {
        redirect = '/signup'
      }
    }
    if (/admin/.test(user.role)) {
      redirect = '/profile'
    }
  }
  return redirect
}
