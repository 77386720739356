import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Routes from 'routes'
import { theme } from 'theme'
import { Snackbar } from 'components'
import { useFirebaseAuth, useFirebaseUser, useFirebaseAggregates } from 'utils'
import 'index.css'
import 'array-flat-polyfill'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '752ebf1d614330245be2247f2615da8c',
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ErrorView = () =>
  <div>
    <p>Whoops! Sorry, but we're currently having issues.</p>
  </div>


const App = () => {
  const [snackMessage, setSnackMessage] = useState('')
  const [authUser, setAuthUser] = useFirebaseAuth()
  const [user, setUser] = useFirebaseUser(authUser)
  const [aggregates, updateAggregates] = useFirebaseAggregates(authUser, user)

  const setUserNull = () => {
    setAuthUser(null)
    setUser(null)
  }

  const state = {
    loading: !!localStorage.getItem('has-session') && (!user || !aggregates),
    setSnackMessage,
    updateAggregates,
    setUserNull,
    authUser,
    user,
    ...aggregates,
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <MuiThemeProvider theme={theme}>
        <Routes {...state} />
        <Snackbar
          open={snackMessage !== ''}
          message={snackMessage}
          onClose={() => setSnackMessage('')}
        />
      </MuiThemeProvider>
    </ErrorBoundary>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
