import React from 'react'
import { Frame, Typography, Box } from 'components'
import about from 'assets/about.png'

const About = ({ user, users }) => {
  return (
    <Frame user={user}>
      <Box mx="auto" style={{ maxWidth: 1100 }}>
        <Typography align="left" variant="h1">
          Hey 👋 <br />
          We are CRRAL
        </Typography>

        <Box my={3} />

        <Typography>
          CRRAL was created by two longtime advertising creatives: Rich Cooper
          and Chris Harrison.
        </Typography>

        <br />

        <Typography>
          For years we worked in the trenches, at countless ad agencies. And
          there’s one problem that never seemed to go away. The chaos of
          staffing for unpredictable workflows and the resulting cries of “What
          Freelancers can we get in here right now!?” To our knowledge, no
          Freelancer website solves this problem better than CRRAL.
        </Typography>

        <br />

        <Typography>
          We also created CRRAL to support Freelancers, to strengthen their
          relationship with go-to employers, and help them find new ones. It’s
          built to help local economies and local Freelancers in a way that
          never exploits workers.
        </Typography>

        <img
          alt="about"
          style={{
            display: 'block',
            margin: '32px auto',
            width: '100%',
            maxWidth: 650,
          }}
          src={about}
        />

        <Typography>
          CRRAL is the best at helping employers manage their Freelancer
          workforce because of what we don’t do. We don’t get in the way of the
          relationship. We don’t have a flawed matchmaking algorithm or a
          bidding process. We don’t meddle with the money side of things.
        </Typography>

        <br />

        <Typography>
          We’re optimistic that simplicity and human decency will win. We
          welcome you to be part of the success story and our community. As a
          Freelancer it’s always free and for agencies it’s a small
          subscription. When you subscribe to CRRAL you’re supporting your
          industry, Freelancers and your local economy.
        </Typography>

        <br />

        <Typography>
          Tell everybody. Share your ideas that make us better. Partner with us,
          so together we can expand CRRAL into new industries. We’re open to all
          this good stuff. Reach us at hirich@crral.com
        </Typography>
        <br />
        <br />
      </Box>
    </Frame>
  )
}

export default About
