import { firebase } from 'utils'

export const updateRoles = (user, role) => {
  const label = role.roleId
  const update = { ...(user.roles[label] || {}), ...role }

  return firebase
    .firestore()
    .collection('users')
    .doc(user.id)
    .set({ roles: { [label]: update } }, { merge: true })
}

export const deleteRole = (user, role) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.id)
    .set(
      {
        roles: {
          [role.roleId]: firebase.firestore.FieldValue.delete(),
          [role.industryId]: firebase.firestore.FieldValue.delete(),
        },
      },
      { merge: true },
    )
}
