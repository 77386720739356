import React from 'react'
import { Box } from 'components'
import { List, ListItem, Typography } from '@material-ui/core'
import { useHistory, slugify, useOnClickOutside } from 'utils'

import { useRef } from 'react'

const Item = ({ onClick, label, route, color, ...props }) => {
  const history = useHistory()

  return (
    <ListItem
      id={`sidebar-button-${slugify(label)}`}
      button
      onClick={() => (onClick ? onClick() : history.push(route))}
      {...props}
    >
      <Typography>{label}</Typography>
    </ListItem>
  )
}

const FreelancerBar = ({ admin }) => (
  <>
    <Item key="Profile" label="My Profile" route="/profile" />
    <Item key="Settings" label="My Settings" route="/settings" />
    {admin && (
      <Item key="Admin" label="Admin" route="/admin" />
    )}
    <Item key="Log Out" label="Log Out" route="/logout" />
  </>
)

const SubscriberBar = () => (
  <>
    <Item key="Settings" label="Settings" route="/settings" />
    <Item key="Log Out" label="Log Out" route="/logout" />
  </>
)

export default function Sidebar({ open, setOpen, user }) {
  const ref = useRef()
  useOnClickOutside(ref, () => setOpen(false))
  let bar
  if (user) {
    if ((user.role === 'freelancer' && user.city) || user.role === 'admin') {
      bar = <FreelancerBar admin={user.role === 'admin'} />
    }
    if (user.role === 'subscriber') {
      bar = <SubscriberBar />
    }
  }
  if (!bar || !open) return null
  return (
    <Box
      ref={ref}
      maxWidth={1400}
      position="relative"
      width="100%"
      mx="auto"
      zIndex={9999}
    >
      <Box
        bgcolor="white"
        border="1px solid #d1d1d1"
        borderRadius={8}
        position="absolute"
        top={-15}
        minWidth={190}
        right={0}
        mr={3}
        zIndex={9999}
      >
        <List style={{ backgroundColor: "white" }}>{bar}</List>
      </Box>
    </Box>
  )
}
