import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { Frame } from 'components'
import { useParams, updateRoles, deleteRole, useTags } from 'utils'
import {
  FreelancerAddRole,
  FreelancerTagsForm,
  FreelancerExperienceForm,
} from './FreelancerAddRole'
import { FreelancerRoleList } from './FreelancerRoleList'

const FreelancerRoles = ({ user, markets, history }) => {
  const { subroute, editType } = useParams()
  const tags = useTags()

  if (subroute === 'add') {
    return (
      <FreelancerAddRole
        user={user}
        markets={markets}
        tags={tags}
        createRole={(role) => {
          updateRoles(user, role)
        }}
      />
    )
  }

  if (!editType) {
    return (
      <Frame maxWidth="xs" user={user}>
        <FreelancerRoleList user={user} deleteRole={deleteRole} />
      </Frame>
    )
  }

  const role = Object.values(user.roles || {}).find(
    (role) =>
      subroute.split(':')[0] === kebabCase(role.industry) &&
      subroute.split(':')[1] === kebabCase(role.label),
  )

  if (!role) {
    return false
  }

  const { experience, industry, skills, label } = role
  const onSubmit = (update) =>
    updateRoles(user, { ...role, ...update }).then(() => history.push('/roles'))

  if (editType === 'experience') {
    return (
      <Frame maxWidth="xs" user={user}>
        <FreelancerExperienceForm
          role={label}
          value={experience}
          onSubmit={onSubmit}
          buttonLabel="Submit"
        />
      </Frame>
    )
  }

  if (editType === 'skills') {
    return (
      <Frame maxWidth="xs" user={user}>
        <FreelancerTagsForm
          role={label}
          tags={tags}
          value={skills}
          industry={industry}
          onSubmit={onSubmit}
        />
      </Frame>
    )
  }
}

export default FreelancerRoles
