import React from 'react'
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'
import { makeStyles } from 'utils'
const useStylesCheckbox = makeStyles({
  root: {
    width: 32,
    height: 32,
    padding: 0,
    marginRight: props => props.marginRight || 10,
    marginTop: props => props.marginTop || 0,
    background: props => props.color || 'rgba(255,255,255, 0.5)',
  },
})
const useStyles = makeStyles({
  label: {
    color: props => props.color || '#fff',
    fontSize: props => props.fontSize || 18,
    textDecoration: 'underline',
    fontWeight: '400',
  },
  root: {
    marginRight: 0,
  },
  icon: {
    borderRadius: 16,
    width: 32,
    height: 32,
    backgroundColor: props =>
      props.color === '#fff' && 'rgba(255,255,255, 0.5)',
  },
  checkedIcon: {
    '&:before': {
      display: 'block',
      borderRadius: 16,
      width: 32,
      height: 32,
      backgroundColor: props =>
        props.color === '#fff' && 'rgba(255,255,255, 0.5)',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
})

const Checkbox = ({ label, color, fontSize, marginTop, ...props }) => {
  const classes = useStyles({ color, fontSize })
  const checkboxClasses = useStylesCheckbox({ color, marginTop, ...props })
  return (
    <FormControlLabel
      label={label}
      classes={{ root: classes.root, label: classes.label }}
      control={
        <MUICheckbox
          color="default"
          classes={checkboxClasses}
          icon={<span className={classes.icon} />}
          checkedIcon={<span className={classes.checkedIcon} />}
          {...props}
        />
      }
    />
  )
}

export default Checkbox
