import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Frame, Box, Button } from 'components'
import ReactCalendar from 'react-calendar'
import { addDays, isSameDay } from 'date-fns'
import { getTileClassName } from './utils'
import { TileStatusMessage } from './TileStatusMessage'
import { NavArrow } from './NavArrow'
import { Sidebar } from './Sidebar'
import {
  updateAvailability,
  updateUser,
  getAvailabilityUpdate,
  useIsMobile,
  getAvailabilityForDate,
} from 'utils'
import './calendar.css'

import isWeekend from 'date-fns/isWeekend'
import { pick } from 'lodash'
import IntroDialog from './IntroDialog'

export const getIsOnboarding = () => {
  const params = new URLSearchParams(window.location.search.split('?')[1])
  return params.get('source') === 'onboarding'
}

let defaultDate = new Date()
if (isWeekend(defaultDate)) defaultDate = addDays(defaultDate, 1)
if (isWeekend(defaultDate)) defaultDate = addDays(defaultDate, 1)

const FreelancerMonthView = ({ user: sourceUser, setSnackMessage }) => {
  const [date, setDate] = useState(getIsOnboarding() ? defaultDate : null)
  const [endDate, setEndDate] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [availability, _setAvailability] = useState({
    status: 1,
    message: '',
    indefinite: true,
  })
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [allowRange, setAllowRange] = useState(false)
  const useMobile = useIsMobile()
  const { status, message, indefinite } = availability

  let user = { ...sourceUser }
  if (hasChanges && date && endDate) {
    const applyWeekends = sourceUser.unsafeWeekends
    const changes = { date, endDate, applyWeekends, availability }
    user = {
      ...user,
      availability: { ...user.availability, ...getAvailabilityUpdate(changes) },
    }
  }

  useEffect(() => {
    if (getIsOnboarding()) {
      setDialogOpen(true)
      updateAvailability({
        user: sourceUser,
        date: new Date(),
        endDate: undefined,
        availability: { status: 3, message: '', indefinite: true },
      })
    }
  }, [date, endDate, sourceUser, status, message, indefinite])

  const setAvailability = useCallback(
    (update) => {
      setHasChanges(true)
      _setAvailability({ ...availability, ...update })
    },
    [availability, _setAvailability],
  )

  const clearAvailability = () => {
    setHasChanges(false)
    _setAvailability({ status: 0, message: '' })
  }

  useEffect(() => {
    if (date && !endDate) {
      const avail = pick(
        getAvailabilityForDate(date, sourceUser),
        availabilityKeys,
      )
      let { indefinite, status, message } = avail
      if (avail.status === 0) {
        status = 1
        message = ''
      }
      setHasChanges(false)

      _setAvailability({ indefinite, status, message })
    }
  }, [date, sourceUser, endDate])

  const confirmDeselect = (message) => {
    if (date && hasChanges) {
      return window.confirm(message)
    }
    return true
  }

  const ref = useRef()
  const handleClickOutside = (event) => {
    if (
      event.target?.id === 'calendar-wrap' ||
      (ref.current && !ref.current.contains(event.target))
    ) {
      if (!confirmDeselect('Are you sure you want to deselect?')) return
      setDate(null)
      setEndDate(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const onChangeDate = ([fromDate, endDate]) => {
    if (
      !allowRange &&
      !confirmDeselect('Are you sure you want to select a new day?')
    )
      return
    setDate(fromDate || endDate)
    setEndDate(isSameDay(fromDate, endDate) ? null : endDate)
    if (isSameDay(fromDate, endDate)) {
      setAllowRange(false)
    }
  }

  const onClearAvailability = () => {
    clearAvailability()
    setHasChanges(true)
  }

  const onToggleUnsafe = () =>
    updateUser(user, { unsafeWeekends: !user.unsafeWeekends })

  const onChangeMessage = (e) =>
    setAvailability({
      status: status === 0 ? 1 : status,
      message: e.target.value.length <= 40 ? e.target.value : message,
    })

  const onSaveChanges = () => {
    const availability = { status, message, indefinite }
    setHasChanges(false)
    updateAvailability({ user, date, endDate, availability })
    setSidebarOpen(false)
    setDate(null)
    setEndDate(null)
    setAllowRange(false)
  }

  return (
    <Frame
      containerStyle={{ padding: 0, flex: 1, display: 'flex' }}
      py={{ xs: 0, md: 2.5 }}
      user={user}
    >
      <Box display="flex" flex={1} px={{ xs: 0, md: 2 }}>
        <Box
          ref={ref}
          flexVariant="row"
          border={{ xs: null, md: '1px solid #DDD' }}
          borderRadius={10}
          flex={1}
        >
          {(!sidebarOpen || !useMobile) && (
            <Box flex={1} flexVariant="column space-between">
              <MonthCalendar
                user={user}
                date={date}
                endDate={endDate}
                availability={availability}
                allowRange={allowRange}
                clearAvailability={clearAvailability}
                onChangeDate={onChangeDate}
              />
              {useMobile && (
                <Button
                  disabled={!date}
                  variant="contained"
                  color="primary"
                  onClick={() => setSidebarOpen(true)}
                  style={{ margin: 16 }}
                >
                  Manage availability
                </Button>
              )}
            </Box>
          )}

          {(sidebarOpen || !useMobile) && (
            <Sidebar
              endDate={endDate}
              hasChanges
              availability={availability}
              date={date}
              unsafeWeekends={user.unsafeWeekends}
              setAvailability={setAvailability}
              allowRange={allowRange}
              setAllowRange={setAllowRange}
              onClearAvailability={onClearAvailability}
              onToggleUnsafe={onToggleUnsafe}
              onChangeMessage={onChangeMessage}
              onSaveChanges={onSaveChanges}
              onClose={() => setSidebarOpen(false)}
            />
          )}

          <IntroDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </Box>
      </Box>
    </Frame>
  )
}

export default FreelancerMonthView

const availabilityKeys = ['status', 'message', 'indefinite']
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const formatShortWeekday = (_, _date) => days[_date.getDay()]

const MonthCalendar = ({
  user,
  date,
  availability,
  allowRange,
  endDate,
  onChangeDate,
}) => {
  return (
    <Box id="calendar-wrap" flex={1}>
      <ReactCalendar
        selectRange={allowRange}
        allowPartialRange
        maxDetail="month"
        minDetail="month"
        value={date ? (endDate ? [date, endDate] : date) : null}
        returnValue="range"
        onChange={onChangeDate}
        formatShortWeekday={formatShortWeekday}
        className={`month-calendar ${endDate ? 'multi-selection' : 'single-selection'
          } ${user.unsafeWeekends ? '' : 'muted-weekends'}`}
        nextLabel={<NavArrow direction="right" />}
        prevLabel={<NavArrow direction="left" />}
        tileClassName={getTileClassName({ user, date, availability })}
        tileContent={({ date: tileDate }) => (
          <TileStatusMessage date={tileDate} user={user} />
        )}
      />
    </Box>
  )
}
