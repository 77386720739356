import React from 'react'
import {
  Button,
  MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'components'

const AddRoleDialog = ({ open, onClose }) => {
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are we missing a role?</DialogTitle>

      <DialogContent>
        <Typography>
          Currently we support roles in Advertising, Film and Web
          Development industries. More will be added in the future
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}

export default AddRoleDialog
