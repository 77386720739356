import uniq from 'lodash/uniq'
import { ChipList } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import Fuse from 'fuse.js'
import { TextField } from './mui'

const TagsForm = ({
  onSubmit,
  onChange = () => { },
  value = [],
  tags = [],
  max = 15,
  search = false,
  role,
  industry,
}) => {
  const generalTags = tags.find((t) => t.category === 'General') || { tags: [] }
  const industryTags = tags.find((t) => t.category === industry) || { tags: [] }
  const roleTags = tags.find((t) => t.category === role) || { tags: [] }
  const items = uniq(
    [...roleTags.tags, ...industryTags.tags, ...generalTags.tags].map(
      (t) => t.tag,
    ),
  )
  const [renderedItems, setRenderedItems] = useState(items)

  useEffect(() => {
    if (renderedItems.length === 0) setRenderedItems(items)
  }, [items, renderedItems])

  const debouncedSearchItems = (items) => {
    return debounce((query, callback) => {
      return callback(
        new Fuse(
          items.map((r) => ({ label: r })),
          { keys: ['label'], threshold: 0.3 },
        )
          .search(query)
          .map((r) => r.item.label),
      )
    }, 200)
  }
  const searchItems = useMemo(() => debouncedSearchItems(items), [items])

  return (
    <>
      {search && (
        <TextField
          name="query"
          label="Search"
          placeholder="Search for a skill tag"
          onChange={(e) => {
            if (e.target.value === '') {
              setRenderedItems(items)
            } else {
              searchItems(e.target.value, setRenderedItems)
            }
          }}
        />
      )}
      <ChipList
        max={max}
        items={renderedItems}
        value={value}
        onChange={(skills) => onChange({ skills })}
        onSubmit={
          onSubmit
            ? (skills) => {
              if (skills.length > 0) {
                onSubmit({ skills })
              } else {
                alert('Please select at least one Specialty')
              }
            }
            : null
        }
      />
    </>
  )
}

export default TagsForm
