import React from 'react'
import { ButtonBase, Box } from 'components'
import { Checkbox, Typography } from '@material-ui/core'
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons'

export const AvailButton = ({
  onClick,
  active = false,
  color,
  children,
  style = {},
}) => (
  <ButtonBase onClick={onClick} style={style}>
    <Checkbox
      color="primary"
      checked={active}
      icon={<RadioButtonUnchecked style={{ fill: '#ddd' }} />}
      checkedIcon={<CheckCircle />}
      style={{ marginRight: 0, padding: 0 }}
    />
    <Box ml={1} px={1} borderRadius={4} bgcolor={color}>
      <Typography style={{ color: 'white', fontSize: 12, lineHeight: 1.8 }}>
        {children}
      </Typography>
    </Box>
  </ButtonBase>
)
