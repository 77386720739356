import { Frame } from 'components'
import JobBoardListings from './JobBoardListings'

const FreelancerJobBoardList = (props) => {
  return (
    <Frame maxWidth="lg" user={props.user}>
      <JobBoardListings {...props} />
    </Frame>
  )
}

export default FreelancerJobBoardList
