import React from 'react'
import { Button, DialogActions, DialogTitle } from 'components'
import { onTextUser, onCallUser } from 'utils'
import { Dialog } from '@material-ui/core'

const PhoneDialog = ({ open, user, onClose }) => {
  const text = () => {
    onClose()
    onTextUser(user)
  }

  const call = () => {
    onClose()
    onCallUser(user)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Would you like to call or text {user.firstName}?
      </DialogTitle>

      <DialogActions>
        <Button autoFocus onClick={call}>
          Call
        </Button>
        <Button autoFocus onClick={text}>
          Text
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PhoneDialog
