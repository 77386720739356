import { firebase } from 'utils'
import React, { useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  CloseIcon,
  ExperienceSlider,
} from 'components'
import { updateRoles, useHistory } from 'utils'
import { SkillPill } from 'components/freelancer/FreelancerExpertiseSection'
import AddSkillLinkDialog from 'components/freelancer/AddSkillLinkDialog'

export function FreelancerRoleList({ user, deleteRole, onboarding }) {
  const history = useHistory()

  const [roleToDelete, setRoleToDelete] = useState(null)
  const [roleToLink, setRoleToLink] = useState(null)

  const onAddSkillTag = (link) => {
    const { role, skill } = roleToLink
    const existingLinks = { ...(role.links || {}) }
    updateRoles(user, {
      ...role,
      links: { ...existingLinks, [skill]: link },
    })

    setRoleToLink(null)
  }

  const onRemoveSkillTag = () => {
    const { role, skill } = roleToLink
    const existingLinks = { ...(role.links || {}) }
    setRoleToLink(null)
    updateRoles(user, {
      ...role,
      links: {
        ...existingLinks,
        [skill]: firebase.firestore.FieldValue.delete(),
      },
    })
  }

  return (
    <>
      <Dialog
        open={!!roleToDelete}
        onClose={() => setRoleToDelete(null)}
        title="Are you sure?"
        body="Are you sure you want to delete this role?"
        confirmText="Delete"
        onClick={() => {
          setRoleToDelete(null)
          deleteRole(user, roleToDelete)
        }}
      />

      <AddSkillLinkDialog
        open={!!roleToLink}
        onClose={() => setRoleToLink(null)}
        onRemove={onRemoveSkillTag}
        initialValue={
          roleToLink?.role.links
            ? roleToLink?.role.links[roleToLink?.skill]
            : null
        }
        onSubmit={onAddSkillTag}
      />

      <Typography align="center" variant="h3">
        {onboarding ? <>Make your profile<br />ultra-searchable. Hyperlink your skill tags.</> : 'Your Roles'}
      </Typography>
      <Box mt={1} mb={4}>
        <Typography align="center">
          {onboarding ? 'The skills and experiences you selected are now tags in your profile. This step turns those tags into links.' : 'Click any skill below to add a direct link to your work sample'}
        </Typography>
        <Box my={2} />

        {onboarding && <>
          <Typography align="center">
            For example, if you chose “Claymation” as a skill, you can add a link directly to a sample of your Claymation work.
          </Typography>
          <Box my={2} />
          <Typography align="center">
            Click any tag to add a link.
          </Typography>
        </>}
      </Box>

      <Divider variant="middle" />

      {Object.values(user.roles || {})
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((role) => (
          <RoleItem
            user={user}
            role={role}
            onboarding={onboarding}
            deleteRole={() => setRoleToDelete(role)}
            onAddLink={(role, skill) => setRoleToLink({ role, skill })}
            key={`role-${role.industry}-${role.label}]`}
          />
        ))}

      {!onboarding && Object.keys(user.roles).length < 3 && (
        <Box mt={6} flexVariant="column center">
          <Typography align="center" variant="body1">
            Some freelancers do more than one role. If you need to add another
            role click below
          </Typography>

          <Box mt={2} />

          <Button
            id="freelancer-add-role"
            variant="contained"
            color="primary"
            onClick={() => history.push('/roles/add')}
          >
            Add a Role
          </Button>
        </Box>
      )}
    </>
  )
}

const RoleItem = ({ user, role, deleteRole, onAddLink, onboarding }) => {
  const roleRoute = `${kebabCase(role.industry)}:${kebabCase(role.label)}`

  const [experience, setExperience] = useState(role.experience)

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">{role.label}</Typography>

        {!onboarding && <CloseIcon
          onClick={deleteRole}
          width={30}
          style={{
            margin: '0 5px',
            fill: 'red',
            opacity: 0.5,
            cursor: 'pointer',
          }}
        />}
      </Box>
      <Box my={1}>
        {!onboarding && <ExperienceSlider
          value={experience}
          setValue={(v) => {
            setExperience(v)
            updateRoles(user, { ...role, experience: v })
          }}
        />}
      </Box>

      <Box flexVariant="row align-center" flexWrap="wrap">
        {role.skills.map((skill) => (
          <SkillPill
            role={role}
            onClick={() => onAddLink(role, skill)}
            skill={skill}
          />
        ))}
        {!onboarding && <Button
          variant="contained"
          color="primary"
          size="small"
          component="a"
          href={`/roles/${roleRoute}/skills`}
          style={{ marginRight: 8, marginBottom: 8, padding: 14 }}
        >
          Edit
        </Button>}
      </Box>

      <Divider my={3} />
    </>
  )
}
