import React from 'react'
import { IconButton as MUIIconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    marginRight: 8,
    backgroundColor: '#F1F1F1',
    '&:hover': {
      backgroundColor: '#1D1D1D',
    },
    '&:hover .MuiIconButton-label svg': {
      fill: 'white',
    },
  },
  label: {},
})

const IconButton = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <MUIIconButton classes={classes} {...props}>
      {children}
    </MUIIconButton>
  )
}

export default IconButton
