import React, { useEffect, useState } from 'react'
import {
  Button,
  MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from 'components'
import { VALID_URI_REGEX } from 'containers/Freelancer/FreelancerProfile/FreelancerEditProfile'

const AddSkillLinkDialog = ({
  open,
  onSubmit,
  onClose,
  onRemove,
  initialValue = '',
}) => {
  const [link, setLink] = useState(initialValue)
  const [showRemove, setShowRemove] = useState(false)
  const isValid = VALID_URI_REGEX.test(link)

  useEffect(() => {
    setLink(initialValue)
    setShowRemove(!!initialValue)
  }, [initialValue])

  return (
    <MUIDialog
      open={open}
      fullWidth
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Skill Link</DialogTitle>

      <DialogContent>
        <TextField
          label="Skill Link"
          placeholder="Link to an example of this skill"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!isValid || !link || link === initialValue}
          variant="contained"
          onClick={() => onSubmit(link)}
          color="primary"
          autoFocus
        >
          Save
        </Button>
        {showRemove && (
          <Button
            variant="contained"
            onClick={onRemove}
            color="primary"
            autoFocus
          >
            Remove
          </Button>
        )}
      </DialogActions>
    </MUIDialog>
  )
}

export default AddSkillLinkDialog
