import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  NumberField,
  TextField,
  BooleanInput,
  NumberInput,
  TextInput,
  ShowButton,
  Pagination,
} from 'react-admin'

export const GenderTagList = props => (
  <List
    {...props}
    title="Gender Tag List"
    perPage={100}
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid>
      <TextField source="name" />
      <NumberField source="index" />
      <BooleanField source="disabled" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const GenderTagEdit = props => (
  <Edit {...props} title="Gender Tag Edit" undoable={false}>
    <SimpleForm>
      <TextInput source="name" options={{ fullWidth: true }} />
      <NumberInput source="index" />
      <BooleanInput source="disabled" />
    </SimpleForm>
  </Edit>
)

export const GenderTagCreate = props => (
  <Create {...props} title="Gender Tag Create">
    <SimpleForm>
      <TextInput source="name" options={{ fullWidth: true }} />
      <NumberInput source="index" />
      <BooleanInput source="disabled" />
    </SimpleForm>
  </Create>
)
