import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const { REACT_APP_FIRESTORE_EMULATOR_HOST } = process.env

firebase.initializeApp(config)

// firebase.functions().useEmulator('localhost', 5001)

if (REACT_APP_FIRESTORE_EMULATOR_HOST) {
  const firestoreSettings = {
    host: REACT_APP_FIRESTORE_EMULATOR_HOST,
    experimentalForceLongPolling: true,
    ssl: false,
  }

  firebase.firestore().settings(firestoreSettings)
}

export default firebase

export const getUserById = async (id) => {
  const r = await firebase.firestore().collection('users').doc(id).get()
  return { id: r.id, ...r.data() }
}

export const getUserByEmail = async (email) => {
  let collection = []
  const ref = await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()
  ref.forEach((r) => collection.push({ id: r.id, ...r.data() }))
  return collection[0]
}

export const getNotes = async (userId) => {
  const ref = await firebase
    .firestore()
    .collection('subscriber_notes')
    .doc(userId)
    .get()

  return { id: ref.id, ...ref.data() }
}

export const updateNotes = async (userId, freelancerId, note) => {
  await firebase
    .firestore()
    .collection('subscriber_notes')
    .doc(userId)
    .set({ [freelancerId]: note }, { merge: true })
  return true
}

export const getUserRecommendations = async (user) => {
  const ref = await firebase
    .firestore()
    .collection('recommendations')
    .doc(user.id)
    .get()
  return Object.values(ref.data() || {})
}

export const getUserFavoriteCount = async (user) => {
  const ref = await firebase
    .firestore()
    .collection('aggregates')
    .doc('favorites')
    .get()
  return ref.data()?.[user.id] || 0
}

export const getContent = () => {
  return firebase
    .firestore()
    .collection('contents')
    .get()
    .then((docs) => {
      let results = []
      docs.forEach((doc) => {
        results[doc.data().name] = doc.data()
      })
      return results
    })
}

export const getConstants = async () =>{
  return firebase
    .firestore()
    .collection('constants')
    .get()
    .then((docs) => {
      let results = {}
      docs.forEach((doc) => {
        results[doc.data().name] = doc.data()
      })
      return results
    })
}