import { firebase } from 'utils'

export const updateRecommendations = (user, { name, company, role, note }) => {
  return firebase
    .firestore()
    .collection('recommendations')
    .doc(user.id)
    .set({ [name + role]: { name, company, role, note } }, { merge: true })
}

export const requestRecommendation = firebase
  .functions()
  .httpsCallable('requestRecommendation')
