import { ONTARIO_WORKING_STATUS } from 'constants'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import FreelancerCreateForm from './FreelancerCreateForm'
import FreelancerWebsiteForm from './FreelancerWebsiteForm'
import FreelancerSelectCity from './FreelancerSelectCity'
import FreelancerSelectOntarioWorkingStatus from './FreelancerSelectOntarioWorkingStatus'
import FreelancerSelectRelocationStatus from './FreelancerSelectRelocationStatus'
import FreelancerSelectDesiredCommitment from './FreelancerSelectDesiredCommitment'
import FreelancerCredits from '../FreelancerCredits'
import FreelancerUploadCv from './FreelancerUploadCv'
import FreelancerDiversityTags from '../FreelancerDiversityTags'
import FreelancerGenderTags from '../FreelancerGenderTags'
import FreelancerAvatar from '../FreelancerCreate/FreelancerAvatar'
import FreelancerSelectEmploymentStatus from './FreelancerSelectEmploymentStatus'
import {
  ONTARIO_RESIDENCE_STATUS,
  COMMITMENT_STATUS,
  CREDITS_ENABLED,
  DIVERSITY_TAGS_ENABLED,
  CV_ONBOARD_ENABLED,
  GENDER_TAGS_ENABLED,
  PROFILE_PICTURES_ENABLED,
  USE_DEMO_LANDER
} from 'constants'

const FreelancerCreateView = ({ cities, user }) => {
  const [hasSkippedWebsite, setHasSkippedWebsite] = useState(false)
  const [hasSkippedCredits, setHasSkippedCredits] = useState(false)
  const [hasSkippedCv, setHasSkippedCv] = useState(false)
  const [hasSkippedDiversityTags, setHasSkippedDiversityTags] = useState(false)
  const [hasSkippedGenderTags, setHasSkippedGenderTags] = useState(false)
  const [hasSkippedPicture, setHasSkippedPicture] = useState(false)

  if (!user) {
    return <FreelancerCreateForm />
  }

  if (!hasSkippedWebsite && user.websites?.length === 0) {
    return <FreelancerWebsiteForm user={user} onNext={() => {
      setHasSkippedWebsite(true)
    }} />
  }

  if (!user.city) {
    return <FreelancerSelectCity onboarding cities={cities} user={user} />
  }

  if (ONTARIO_WORKING_STATUS && typeof user.ontarioWorkingStatus !== 'number') {
    return <FreelancerSelectOntarioWorkingStatus onboarding user={user} />
  }

  if (ONTARIO_RESIDENCE_STATUS && typeof user.relocationStatus !== 'number') {
    return <FreelancerSelectRelocationStatus onboarding user={user} />
  }

  if (COMMITMENT_STATUS && !user.desiredCommitments) {
    return <FreelancerSelectDesiredCommitment onboarding user={user} />
  }

  if (GENDER_TAGS_ENABLED && !hasSkippedGenderTags) {
    return <FreelancerGenderTags onboarding onNext={() => {
      setHasSkippedGenderTags(true)
    }}
      user={user} />
  }

  if (DIVERSITY_TAGS_ENABLED && !hasSkippedDiversityTags) {
    return <FreelancerDiversityTags onboarding onNext={() => {
      setHasSkippedDiversityTags(true)
    }}
      user={user} />
  }

  if (CREDITS_ENABLED && !hasSkippedCredits) {
    return <FreelancerCredits onboarding onNext={() => {
      setHasSkippedCredits(true)
    }}
      user={user} />
  }

  if (CV_ONBOARD_ENABLED && (!hasSkippedCv)) {
    return <FreelancerUploadCv onboarding user={user} onNext={() => {
      setHasSkippedCv(true)
    }}
    />
  }

  if (PROFILE_PICTURES_ENABLED && !hasSkippedPicture) {
    return <FreelancerAvatar onboarding user={user} onNext={() => { setHasSkippedPicture(true) }} />
  }

  if (USE_DEMO_LANDER && !user.employment) {
    return <FreelancerSelectEmploymentStatus onboarding user={user} />
  }

  return <Redirect to="/roles/add?source=onboarding" />
}

export default FreelancerCreateView
