import React from 'react'
import { Box, Button, Heading, TextField, Typography, Frame } from 'components'
import { forgotPassword } from 'utils'

const ForgotPasswordView = () => {
  return (
    <Frame maxWidth="xs">
      <Heading>Forgot your password?</Heading>

      <Typography>
        Enter your email address below and we will send you an email to reset
        your password.
      </Typography>

      <Box my={3} />

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { email } = event.target.elements
          await forgotPassword(email.value)
        }}
      >
        <TextField required type="email" name="email" label="Email Address" />

        <Button my={3} type="submit">
          Send
        </Button>
      </form>

      <Typography>
        Remember your password?{' '}
        <Typography component="a" href="/login">
          Sign in
        </Typography>
      </Typography>
    </Frame>
  )
}

export default ForgotPasswordView
