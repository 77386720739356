import React from 'react'
import { Box, Typography, Button } from 'components'
import BusinessIcon from '@material-ui/icons/Business'
// const mocks = [
//   {
//     name: 'Ken Grant',
//     company: 'Power Point Inc.',
//     note:
//       'We worked together on a complex and interesting product on an international scale. In our case, it was necessary to analyze the market of competitors, their systems and features. Combine all this with the requirements of business, users, local market features and make it a reality.',
//   },
//   {
//     name: 'Patsy Gonzales',
//     company: 'Power Point Inc.',
//     note:
//       'Ethan has been a cherished colleague of mine for a number of years. He has a great self-starter mentality and is always willing to take on more.',
//   },
// ]

const FreelancerProfileRecommendations = ({ freelancer, onEdit }) => {
  // const recos = mocks || freelancer.recommendations
  const recos = freelancer.recommendations

  if (recos.length === 0 && !onEdit) {
    return false
  }

  return (
    <Box flex={1} pt={3.5}>
      <Box px={3.5} flex={1} flexVariant="row space-between" mb={2.5}>
        <Typography variant="h4" style={{ fontWeight: '600' }}>
          Recommendations
        </Typography>
      </Box>

      {recos.map((reco) => (
        <Recommendation key={`${reco.name}-${reco.company}`} reco={reco} />
      ))}

      {onEdit && (
        <Button
          variant="contained"
          color="primary"
          onClick={onEdit}
          style={{ margin: '16px 28px' }}
        >
          Request a Recommendation
        </Button>
      )}
    </Box>
  )
}

export default FreelancerProfileRecommendations

const Recommendation = ({ reco }) => (
  <Box
    bgcolor="white"
    border="1px solid #DDD"
    borderRadius={10}
    flexVariant="row"
    py={3.5}
    px={3.5}
    mb={1}
  >
    <Box>
      <Typography style={{ color: 'black' }}>{reco.name}</Typography>

      <Box flexVariant="row align-center" mr={4}>
        <BusinessIcon
          style={{ fill: '#696969', marginRight: 6, fontSize: 18 }}
        />

        <Typography variant="body2">{reco.company}</Typography>
      </Box>
    </Box>

    <Box flex={1}>
      <Typography>{reco.note}</Typography>
    </Box>
  </Box>
)
