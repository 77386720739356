import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checklist,
} from 'components'
import { updateFlags } from 'utils'
import { CUSTOM_STATIC_LANDER } from 'constants'


const FlagDialog = ({ user, flaggedUser, open, onClose, onSubmit }) => {
  const [value, setValue] = useState('not-in-region')
  const [other, setOther] = useState('')
  const [listState, setListState] = useState([])
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth={false}
    >
      <DialogTitle id="alert-dialog-title">
        Thanks for helping us improve this Profile.
        We’ll notify them on your behalf, anonymously.
      </DialogTitle>
      <Typography variant="subtitle1">Tell us what's missing. Select any that apply.</Typography>

      <DialogContent>
        <FormControl style={CUSTOM_STATIC_LANDER ? {display: 'flex', justifyContent:"center"} : null} component="fieldset">
          {!CUSTOM_STATIC_LANDER ? (
            <>
              <RadioGroup
                aria-label="reason"
                name="reason"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="not-in-region"
                  control={<Radio color="primary" />}
                  label="Add 'About me'"
                />
                <FormControlLabel
                  value="bad-skills"
                  control={<Radio color="primary" />}
                  label="Missing Portfolio Link"
                />
                <FormControlLabel
                  value="inappropriate"
                  control={<Radio color="primary" />}
                  label="Missing LinkedIn Link"
                />
                {/* <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label="Other"
            /> */}
              </RadioGroup>
              {value === 'other' && (
                <TextField
                  label="Other"
                  placeholder="Reason"
                  value={other}
                  onChange={(e) => setOther(e.target.value)}
                />
              )}
            </>
          ) : (
            <Checklist
              style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
              maxWidth="xs"
              user={user}
              flaggedUser={flaggedUser}
              multicheck
              onChange={e => setListState(e.map(item => item.value))}
              items={[
                { label: 'About me', id: 'about-me', value: 'about-me' },
                { label: 'e-mail', id : 'email', value: 'email' },
                { label: 'LinkedIn', id: 'linkedin', value: 'linkedin' },
                { label: 'CV/Resume', id: 'resume', value: 'resume' },
                { label: 'Portfolio', id: 'portfolio', value: 'portfolio' },
                { label: 'Portfolio Password', id: 'portfolio-password', value: 'portfolio-password' },
                { label: 'IMDB Link', id: 'imdb', value: 'imdb' },
                { label: 'Canadian Work Status', id: 'canadian-work-status', value: 'canadian-work-status' },
                { label: 'Equipment', id: 'equipment', value: 'equipment' },
              ]} />
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={!CUSTOM_STATIC_LANDER ?() => {
            if (value === 'other' && !other) {
              return
            }

            updateFlags(user, flaggedUser, {
              value,
              otherReason: value === 'other' ? other : undefined,
            })

            onSubmit()
          }: () => {onSubmit(listState)} }
          color="primary"
          autoFocus
        >
          Notify
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}

export default FlagDialog
