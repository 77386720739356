import React from 'react'
import { Button, TextField, Frame, Heading } from 'components'
import { useHistory, getFormValues, updatePassword } from 'utils'

const SubscriberPassword = ({ user }) => {
  const history = useHistory()
  return (
    <Frame maxWidth="xs" user={user}>
      <Heading>Change your Password.</Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { oldPassword, newPassword } = getFormValues(event)
          updatePassword(oldPassword, newPassword).then(() => {
            history.push('/settings')
          })
        }}
      >
        <TextField
          required
          name="oldPassword"
          type="password"
          label="Old Password"
        />
        <TextField
          required
          name="newPassword"
          type="password"
          label="New Password"
        />

        <Button type="submit">Submit</Button>
      </form>
    </Frame>
  )
}

export default SubscriberPassword
