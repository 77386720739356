import React, { useEffect } from 'react'
import {
  ArrowForwardIcon,
  Box,
  Button,
  ButtonBase,
  VerticalDivider,
  ArrowDownIcon,
} from 'components'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import InviteDialog from 'components/subscriber/InviteDialog'
import OnboardDialog from 'components/subscriber/OnboardDialog'
import { useState } from 'react'
import Sidebar from './Sidebar'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import JobBoardIcon from '@material-ui/icons/MonetizationOnRounded'
import SettingsIcon from '@material-ui/icons/Settings'
import { useContent, useIsMobile, useSubscription } from 'utils'
import InfoOutlinedIcon from '@material-ui/icons/Info'
import HelpOutlineOutlinedIcon from '@material-ui/icons/Help'
import HomeIcon from '@material-ui/icons/Home'
// import StarsIcon from '@material-ui/icons/Stars'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import SetCalendarNagDialog from 'components/freelancer/SetCalendarNagDialog'
import { Link } from 'react-router-dom'
import { CUSTOM_STATIC_LANDER } from 'constants'
import { SUBSCRIPTIONS_ENABLED } from 'constants'

const NavBar = ({ user, history, setSnackMessage, openMissionDialog }) => {
  const [inviteOpen, setInviteOpen] = useState(false)
  const [onboardOpen, setOnboardOpen] = useState(false)
  const { subscription, isLoading } = useSubscription(user?.id)

  useEffect(() => {
    if (
      !isLoading &&
      !subscription &&
      !localStorage.getItem('onboard-dialog') &&
      SUBSCRIPTIONS_ENABLED &&
      user?.role === 'subscriber'
    ) {
      setOnboardOpen(true)
    }
  }, [subscription, isLoading, user])
  const [settingsOpen, setSettingsOpen] = useState(false)
  const useMobile = useIsMobile(600)

  let type = 'unauth'
  if (!!localStorage.getItem('has-session')) {
    type = 'loading'
    if (user) {
      type = user.role === 'subscriber' ? 'subscriber' : 'freelancer'
    }
  }
  const buttons = useMobile ? MOBILE_BUTTONS[type] : DESKTOP_BUTTONS[type]

  return (
    <>
      {useMobile ? (
        <MobileNavBar
          user={user}
          history={history}
          buttons={buttons}
          setSnackMessage={setSnackMessage}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          inviteOpen={inviteOpen}
          setInviteOpen={setInviteOpen}
        />
      ) : (
        <DesktopNavBar
          user={user}
          history={history}
          buttons={buttons}
          setSnackMessage={setSnackMessage}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          inviteOpen={inviteOpen}
          setInviteOpen={setInviteOpen}
          openMissionDialog={openMissionDialog}
          sidebar={
            <Sidebar
              history={history}
              open={settingsOpen}
              setOpen={setSettingsOpen}
              user={user}
            />
          }
        />
      )}

      {user && (
        <>
          <InviteDialog
            fromName={user.firstName}
            setSnackMessage={setSnackMessage}
            onClose={() => setInviteOpen(false)}
            open={inviteOpen}
          />
          <OnboardDialog
            onClose={() => {
              localStorage.setItem('onboard-dialog', '1')
              setOnboardOpen(false)
            }}
            open={onboardOpen}
          />
        </>
      )}
    </>
  )
}

const DesktopNavBar = ({
  user,
  history,
  buttons,
  openMissionDialog,
  settingsOpen,
  setSettingsOpen,
  inviteOpen,
  setInviteOpen,
  sidebar,
}) => {
  const { logo, tagline } = useContent()
  const [alertOpen, setAlertOpen] = useState(false)
  const closeAlert = () => {
    localStorage.setItem(`set-calendar-dialog`, Date.now() + 1209600000)
    setAlertOpen(false)
  }
  const timeToNag = JSON.parse(
    localStorage.getItem(`set-calendar-dialog`) || '-1',
  )
  const onCalendar = () => {
    closeAlert()
    history.push('/month')
  }

  return (
    <AppBar
      color="secondary"
      position="fixed"
      style={{
        background: 'secondary',
        boxShadow: 'none',
        borderBottom: '1px solid #DDDDDD',
      }}
    >
      <Box maxWidth={1300} mx="auto" width="100%">
        <Toolbar variant="dense">
          <Box display="flex" flex={1}>
            <Box flex={1} display="flex" alignItems="center">
              <ButtonBase
                style={{ minWidth: 120 }}
                disableRipple
                onClick={() => history.push('/')}
              >
                {logo && <img
                  src={logo}
                  alt="logo"
                  style={{
                    maxWidth: '100%',
                    height: 30,
                    margin: '25px 0',
                  }}
                />}
              </ButtonBase>
              <Box
                display={{ sm: 'none', md: 'block' }}
                minWidth={200}
                marginLeft={4}
              >
                <Link
                  style={{ textDecoration: 'none' }}
                  onClick={openMissionDialog}
                >
                  <Typography
                    fontSize={11}
                    style={{ color: '#999', lineHeight: 1.1 }}
                  >
                    {tagline}
                  </Typography>
                </Link>
              </Box>
            </Box>

            <Box
              flex={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {buttons.map((button, index) => (
                <NavButton
                  user={user}
                  {...button}
                  key={`button-${index}`}
                  onClick={() => {
                    if (button.label === 'Settings') {
                      return setSettingsOpen(!settingsOpen)
                    }
                    if (button.label === 'Invite') {
                      return setInviteOpen(true)
                    }
                    // if (
                    //   button.label === 'Job Posts' &&
                    //   Date.now() > timeToNag
                    // ) {
                    //   return setAlertOpen(true)
                    // }
                    history.push(button.url)
                  }}
                />
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Box>

      {sidebar}

      <SetCalendarNagDialog
        open={alertOpen}
        onClose={closeAlert}
        onCalendar={onCalendar}
      />
    </AppBar>
  )
}

const MobileNavBar = ({
  user,
  history,
  buttons,
  settingsOpen,
  setSettingsOpen,
  setInviteOpen,
}) => (
  <AppBar
    color="default"
    position="fixed"
    style={{
      background: 'white',
      boxShadow: 'none',
      top: 'auto',
      bottom: 0,
      borderTop: '1px solid #DDDDDD',
    }}
  >
    <Toolbar variant="dense">
      <Box mx="auto" maxWidth={400} flexVariant="row space-between">
        {buttons.map((button, index) => (
          <MobileNavButton
            user={user}
            {...button}
            key={`button-${index}`}
            onClick={() =>
              button.label === 'Invite'
                ? setInviteOpen(true)
                : history.push(button.url)
            }
          />
        ))}
      </Box>
    </Toolbar>
  </AppBar>
)

export default NavBar

const DESKTOP_BUTTONS = {
  loading: [],
  unauth: [
    !CUSTOM_STATIC_LANDER && { label: 'About', url: '/about' },
    !CUSTOM_STATIC_LANDER && { label: 'FAQ', url: '/faq' },
    !CUSTOM_STATIC_LANDER && { label: 'divider' },
    { label: 'Log In', url: '/login' },
  ].filter(Boolean),
  subscriber: [
    {
      label: 'Groups',
      url: '/groups',
      primary: true,
      style: { margin: '0 16px' },
    },
    {
      label: 'Post A Job',
      url: '/job_board/list',
      primary: true,
      style: { margin: '0 16px' },
    },
    { label: 'Invite', primary: true, style: { margin: '0 16px' } },
    { label: 'divider' },
    {
      label: 'Settings',
    },
  ],
  freelancer: [
    { label: 'Job Posts', url: `/job_board` },
    { label: 'My Calendar', url: `/month` },
    { label: 'Settings' },
  ],
}

const MOBILE_BUTTONS = {
  loading: [],
  unauth: [
    { Icon: HomeIcon, label: 'Home', url: '/' },
    !CUSTOM_STATIC_LANDER && { Icon: InfoOutlinedIcon, label: 'About', url: '/about' },
    !CUSTOM_STATIC_LANDER && { Icon: HelpOutlineOutlinedIcon, label: 'FAQ', url: '/faq' },
    { Icon: AccountCircleIcon, label: 'Sign Up', url: '/signup' },
  ].filter(Boolean),
  subscriber: [
    { Icon: HomeIcon, label: 'Market', url: '/' },
    { Icon: AccountCircleIcon, label: 'Groups', url: `/groups` },
    { Icon: AddCircleOutlineIcon, label: 'Invite' },
    { Icon: JobBoardIcon, label: 'Job Board', url: `/job_board/list` },
    { Icon: SettingsIcon, label: 'Settings', url: `/settings` },
  ],
  freelancer: [
    { Icon: CalendarIcon, label: 'My Calendar', url: `/month` },
    { Icon: AccountCircleIcon, label: 'Profile', url: `/` },
    { Icon: AddCircleOutlineIcon, label: 'Invite' },
    { Icon: JobBoardIcon, label: 'Job Board', url: `/job_board` },
    { Icon: SettingsIcon, label: 'Settings', url: `/settings` },
  ],
}

const NavButton = ({
  label,
  primary,
  rightIcon,
  style,
  user,
  url,
  onClick,
}) => {
  const activePath = window.location.hash.replace('#', '')

  if (label === 'divider') {
    return <VerticalDivider ml={1} mr={0.5} height={24} display={'flex'} />
  }
  return (
    <Box position="relative" alignItems="center" display={'flex'} key={label}>
      <Button
        variant={primary ? 'contained' : 'text'}
        color={primary ? 'primary' : 'default'}
        leftIcon={label === 'Invite' && <PersonAddIcon />}
        rightIcon={
          label === 'Sign Up' ? (
            <ArrowForwardIcon />
          ) : label === 'Settings' ? (
            <ArrowDownIcon />
          ) : null
        }
        onClick={onClick}
        style={{
          minWidth: primary ? 110 : 0,
          color:
            (label === 'Settings' || activePath === url) && !primary
              ? '#1d1d1d'
              : 'auto',
          padding: '10px 4px',
          ...(style || {}),
          margin: '0 4px',
        }}
      >
        {label === 'Settings' ? `${user.firstName} ${user.lastName}` : label}
      </Button>

      {activePath === url && (
        <Box
          position="absolute"
          bottom={-20}
          left="calc(50% - 10px)"
          height={2}
          width={20}
          bgcolor="#1d1d1d"
        />
      )}
    </Box>
  )
}

const MobileNavButton = ({ active, label, Icon, onClick }) => {
  const color = active ? '#1d1d1d' : '#696969'
  return (
    <ButtonBase
      onClick={onClick}
      position="relative"
      alignItems="center"
      display={'flex'}
      key={label}
    >
      <Box py={1} px={2}>
        <Icon style={{ fill: color }} />
        <Typography
          style={{
            fontSize: 10,
            lineHeight: 1.2,
            color,
            marginTop: 2,
          }}
        >
          {label}
        </Typography>
      </Box>
    </ButtonBase>
  )
}
