import React, { useEffect, useState, useMemo } from 'react'
import { Frame, Typography, FreelancerList } from 'components'
import {
  getAvailabilityForDate,
  filterHiddenUsers,
  filterUserWithStatus,
  filterUserWithExperience,
  filterUserWithRole,
  filterUserWithCity,
  filterUserWithSkills,
  filterUsersByAvailability,
  filterUserWithGenderTag,
  filterUserWithDiversityTag,
} from 'utils'
import { SearchBar } from './SearchBar'
import { FilterBar, filterInitialState } from '../../FilterBar/MarketFilterBar'
import { uniq, uniqBy } from 'lodash'
import { singular } from 'pluralize'
import { useContent } from 'utils'

import {
  useQueryParams,
  withDefault,
  ArrayParam,
  DateParam,
  DelimitedNumericArrayParam,
  StringParam,
  BooleanParam,
  ObjectParam,
} from 'use-query-params'
import { Redirect } from 'react-router'

import { USE_DEMO_LANDER } from 'constants'

export const MarketsView = ({
  user,
  markets = {},
  freelancers = {},
  setSnackMessage,
}) => {
  const [filterState, setFilterState] = useQueryParams({
    date1: withDefault(DateParam, filterInitialState.date1),
    date2: withDefault(DateParam, filterInitialState.date2),
    city: withDefault(StringParam, filterInitialState.city),
    role: withDefault(StringParam, filterInitialState.role),
    favorites: withDefault(BooleanParam, filterInitialState.favorites),
    has_equipment: withDefault(BooleanParam, filterInitialState.has_equipment),
    relocate: withDefault(BooleanParam, filterInitialState.relocate),
    ontario_resident: withDefault(BooleanParam, filterInitialState.ontario_resident),
    work_status: withDefault(BooleanParam, filterInitialState.work_status),
    experience: withDefault(
      DelimitedNumericArrayParam,
      filterInitialState.experience,
    ),
    skills: withDefault(ArrayParam, filterInitialState.skills),
    status: withDefault(DelimitedNumericArrayParam, filterInitialState.status),
    available_commitments: withDefault(ObjectParam, filterInitialState.available_commitments),
    gender_tags: withDefault(ArrayParam, filterInitialState.gender_tags),
    diversity_tags: withDefault(ArrayParam, filterInitialState.diversity_tags),
    employment_status: withDefault(ArrayParam, filterInitialState.employment_status),
  })

  const { search } = useContent()

  const [industryId, setIndustryId] = useState('')
  const [results, setResults] = useState([])
  const loading = Object.values(freelancers).length === 0
  const availabilityDate = filterState.date1 || new Date()

  const cities = useMemo(
    () =>
      Object.values(markets)
        .filter((m) => m.industryId === industryId)
        .sort((a, b) => a.cityName.localeCompare(b.cityName))
        .reduce((obj, v) => ({ ...obj, [v.cityId]: v.cityName }), {}),
    [industryId, markets],
  )

  const industries = uniqBy(Object.values(markets), (u) => u.industryId).reduce(
    (obj, v) => ({ ...obj, [v.industryId]: v.industry }),
    {},
  )

  useEffect(() => {
    const newResults = Object.values(markets)
      .filter(
        (market) =>
          industryId === market.industryId &&
          (filterState.city == null || filterState.city === market.cityId),
      )
      .map((market) =>
        (
          Object.values(
            freelancers[market.industryId + '-' + market.cityId] || {},
          ) || []
        ).map((f) => ({ ...f, market })),
      )
      .flat()
      .filter(filterHiddenUsers(user ? user.hiddenUsers : []))
    setResults(newResults)
  }, [freelancers, markets, user, filterState, industryId])

  const renderedUsers = useMemo(() => {
    if (!user) return []

    return results
      .map((u) => ({
        ...u,
        latestAvail: getAvailabilityForDate(availabilityDate, u),
      }))
      .filter(
        filterUserWithCity(
          filterState.city ? [filterState.city] : Object.keys(cities),
        ),
      )
      .filter(filterUserWithStatus(filterState.status))
      .filter(filterUserWithExperience(filterState.experience))
      .filter(
        filterUserWithRole(
          filterState.role ? singular(filterState.role) : null,
        ),
      )
      .filter(filterUserWithSkills(filterState.skills))
      .filter((u) => !u.hidden)
      .filter((u) =>
        filterState.favorites ? user.favorites.includes(u.id) : true,
      )
      .filter((u) =>
        filterState.has_equipment ? (u.equipment || []).length > 0 : true,
      )
      // resident of ontario
      .filter((u) =>
        filterState.ontario_resident ? (u.resident) : true,
      )
      // willing to relocate
      .filter((u) =>
        filterState.relocate ? (u.relocationStatus > 0) : true,
      )
      // legal permit to work
      .filter((u) =>
        filterState.work_status ? (u.ontarioWorkingStatus > 0) : true)
      .filter(filterUserWithGenderTag(filterState.gender_tags))
      .filter(filterUserWithDiversityTag(filterState.diversity_tags))
      .filter(u => filterUsersByAvailability(u, filterState.available_commitments))
      .filter(USE_DEMO_LANDER ? u => filterState.employment_status.includes(u.employment?.status) : u => true)
      .sort((a, b) =>
        a.latestAvail.status === 0 ? 1 : b.latestAvail.status === 0 ? -1 : 0,
      )
    // eslint-disable-next-line
  }, [user, filterState, results, cities])

  const titles = useMemo(
    () => uniq(results.map((f) => f.titles).flat()).slice(0, 10),
    [results],
  )
  const skills = useMemo(
    () => uniq(results.map((f) => f.skills).flat()).slice(0, 10),
    [results],
  )

  useEffect(() => {
    if (!search) return
    if (industryId === '') {
      setIndustryId(search['default-industry'])
    }
  }, [industryId, search])

  if (!user.company) return <Redirect to="/signup" />

  if (!search) return null

  return (
    <Frame setSnackMessage={setSnackMessage} maxWidth="lg" py={0} user={user}>
      <SearchBar
        user={user}
        industryId={industryId}
        setIndustryId={setIndustryId}
        industries={industries}
        setFilterState={setFilterState}
        freelancers={results}
        results={renderedUsers}
        setResults={setResults}
        titles={titles}
        cities={cities}
        skills={skills}
      />

      <FilterBar
        user={user}
        titles={titles}
        skills={skills}
        cities={cities}
        filterState={filterState}
        setFilterState={setFilterState}
        renderedUsers={renderedUsers}
      />

      {renderedUsers.length === 0 ? (
        <Typography align="center">
          {loading
            ? ''
            : 'Oops, no matches. Try adjusting/removing your active filters.'}
        </Typography>
      ) : (
        <FreelancerList
          user={user}
          date={availabilityDate}
          users={renderedUsers}
        />
      )}
    </Frame>
  )
}
