import React from 'react'
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  ButtonBase,
} from 'components'
import { AvailButton } from './AvailButton'
import { getColorFromStatus } from './utils'
import { format } from 'date-fns'
import { Checkbox } from '@material-ui/core'

const f = (d) => format(d, 'MMM dd, yyyy')
export const Sidebar = ({
  date,
  endDate,
  setAvailability,
  onClearAvailability,
  onClose,
  ...props
}) => {
  const { status, message, indefinite } = props.availability
  return (
    <Box
      maxWidth={{ xs: '100%', md: 300 }}
      boxSizing="border-box"
      pt={3}
      pb={1}
      px={3}
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      borderLeft="1px solid #DDD"
    >
      <Box flex={1}>
        <Box flexVariant="row space-between align-center">
          <Typography variant="h5">Manage Availability</Typography>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Button onClick={onClose}>Close</Button>
          </Box>
        </Box>

        {!date ? (
          <Typography style={{ marginTop: 16 }}>
            Select dates to start building your schedule
          </Typography>
        ) : (
          <>
            <Box
              mt={3}
              py={0.5}
              px={2}
              border={`1px solid ${getColorFromStatus(status)}`}
              borderRadius={15}
            >
              <Typography
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  lineHeight: 1.4,
                  color: getColorFromStatus(status),
                  whiteSpace: 'nowrap',
                }}
              >
                {date && endDate ? `${f(date)} - ${f(endDate)}` : f(date)}
              </Typography>
            </Box>

            <Divider my={2.5} />

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <AvailButton
                active={status === 1}
                color="#52B97F"
                style={{ marginBottom: 12 }}
                onClick={() =>
                  status === 1
                    ? onClearAvailability()
                    : setAvailability({ status: 1 })
                }
              >
                Available for work
              </AvailButton>

              <AvailButton
                active={status === 3}
                color="#ECB230"
                style={{ marginBottom: 12 }}
                onClick={() =>
                  status === 3
                    ? onClearAvailability()
                    : setAvailability({ status: 3 })
                }
              >
                It depends
              </AvailButton>

              <AvailButton
                active={status === 2}
                color="#F24741"
                onClick={() =>
                  status === 2
                    ? onClearAvailability()
                    : setAvailability({ status: 2 })
                }
              >
                Not available
              </AvailButton>
            </Box>

            <Divider my={2.5} />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <AvailButton
                active={!!indefinite}
                color="#999"
                style={{ marginBottom: 12 }}
                onClick={() => {
                  setAvailability({ indefinite: true })
                  props.setAllowRange(false)
                }}
              >
                Ongoing
              </AvailButton>
              <AvailButton
                active={!indefinite && !props.allowRange}
                color="#999"
                style={{ marginBottom: 12 }}
                onClick={() => {
                  setAvailability({ indefinite: false })
                  props.setAllowRange(false)
                }}
              >
                Single Day
              </AvailButton>

              <AvailButton
                active={!indefinite && props.allowRange}
                color="#999"
                onClick={() => {
                  setAvailability({ indefinite: false })
                  props.setAllowRange(true)
                }}
              >
                Range of days
              </AvailButton>
            </Box>

            <Divider mt={2.5} my={0} />

            <TextField
              multiline
              rows={2}
              margin="normal"
              label="Calendar Note"
              value={message}
              onChange={props.onChangeMessage}
            />

            {props.hasChanges && (
              <Button
                variant="contained"
                color="primary"
                onClick={props.onSaveChanges}
              >
                Save
              </Button>
            )}
          </>
        )}
      </Box>

      <Divider my={1} />

      <ButtonBase
        style={{ justifyContent: 'flex-start' }}
        onClick={props.onToggleUnsafe}
      >
        <Checkbox
          color="primary"
          checked={!props.unsafeWeekends}
          style={{ marginRight: 0 }}
        />
        <Typography style={{ color: '#1d1d1d' }}>
          I don't work weekends
        </Typography>
      </ButtonBase>
    </Box>
  )
}
